/** @format */
import type { DetailedFrame, IFrame } from 'javascripts/types/frame';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import * as React from 'react';
import { getThumbnailSize } from './frame-size-helper';
import { getFrameImage } from './getFrameImage';

export const getFrameImageSources = (
  frame: DetailedFrame | IFrame,
  aspectRatio: frameAspectRatio,
) => ({
  0: getFrameImage(frame.thumbnail_image_url, aspectRatio),
  [getThumbnailSize(aspectRatio).width]: getFrameImage(
    frame.large_image_url,
    aspectRatio,
  ),
});

export const useFrameImageSources: typeof getFrameImageSources = (
  frame,
  aspectRatio,
) => {
  return React.useMemo(
    () => getFrameImageSources(frame, aspectRatio),
    [aspectRatio, frame],
  );
};
