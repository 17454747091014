/** @prettier */
import * as shallowEqual from 'shallowequal';
import * as React from 'react';
import { SVGIcon, SVGIconType } from '../shared/SVGIcon';
import type { LiteralUnion } from 'type-fest';
import Icon from 'blackbird/components/icon/Icon';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import classNames from 'classnames';
import { ToursActions, tourEvents } from '../../flux/actions/tours';
import { TourHintable } from '../tours/TourHintable';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import { toMins } from 'javascripts/helpers/format-time';
import { FrameActions } from 'javascripts/flux/actions/frame';
import type { DetailedFrame } from 'javascripts/types/frame';
import type { RichTextInputChangeEventHandler } from 'blackbird/components/form/richTextInput/RichTextInput';
import { TextArea } from 'blackbird/components/form/text-input/TextInput';
import { stylesForIndexLength } from 'javascripts/helpers/styleForIndexLength';
import { type GridSize } from './FrameGrid';
import { clampNumber } from 'javascripts/helpers/clampNumber';

interface Props extends WithTranslation {
  frame: DetailedFrame;
  storyboardId: number;
  field_id: string;
  text?: string;
  indexLength: number;
  /** Can be either an Svg icon or the frame index */
  icon?: LiteralUnion<SVGIconType, string>;
  placeholder: string;
  isInteractive?: boolean;
  isMinimal?: boolean;
  gridSize: GridSize;
  showIcons?: boolean;
  showFrameNumber?: boolean;
  update_all_frames?: boolean;
  rows?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLTextAreaElement>;
}

interface State {
  text: string;
}

const fieldStyle: React.CSSProperties = { minHeight: '2rem' };
class FrameNotes extends React.Component<Props, State> {
  inputRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {
      text: this.getValue(props.frame, props.field_id),
    };
  }

  static defaultProps = {
    isInteractive: true,
    showIcons: true,
    showFrameNumber: true,
  };

  getValue(frame, field_id) {
    let value = getFrameField(frame, field_id);
    if (field_id === 'duration') {
      value = toMins(frame.duration);
    }

    return value;
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      (nextProps.frame.id == this.props.frame.id &&
        nextProps.frame.update_field_state) ||
      this.props.update_all_frames
    ) {
      this.setState({
        text: this.getValue(nextProps.frame, nextProps.field_id),
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      /**
       * If we have text, we should rerender so that the auto-resizing text
       * area can adjust in case the text area width has changed,
       * this is dumb, but it prevents text from being chopped off if the
       * zoom level resizes and the height of the textarea isn't sufficient
       */
      this.state.text ||
      this.state !== nextState ||
      !shallowEqual(nextProps, this.props);
    return Boolean(shouldUpdate);
  }

  _saveText = () => {
    if (
      this.state.text !== getFrameField(this.props.frame, this.props.field_id)
    ) {
      FrameActions.saveText.defer({
        field_id: this.props.field_id,
        frame_id: this.props.frame.id,
        storyboard_id: this.props.storyboardId,
        text: this.state.text,
      });

      this._advanceTour();
    }
  };

  _advanceTour = () => {
    if (
      this.props.field_id === 'voiceover' &&
      this.props.frame.number === '1'
    ) {
      ToursActions.triggerEvent.defer(tourEvents.addTextToFrame);
    }
  };

  _onKeyPress = (e) => {
    // Double check this?
    if (e.key == 'Escape') {
      this.setState({
        text: getFrameField(this.props.frame, this.props.field_id),
      });
    }
  };

  _onInputChange: RichTextInputChangeEventHandler = (newValue) => {
    this.setState({ text: newValue }, () => {
      this._saveText();
    });
  };

  render() {
    const noIconAndLabel = !this.props.showFrameNumber && !this.props.showIcons;
    var id = 'frame-' + this.props.frame.id + '-' + this.props.placeholder;
    var icon: React.ReactElement;

    const { isMinimal, gridSize, field_id } = this.props;
    const isLabel = field_id === 'reference';

    if (this.props.field_id === 'reference' && this.props.showFrameNumber) {
      icon = (
        <span className="index-icons text-type-subdued">{this.props.icon}</span>
      );
    } else if (this.props.showIcons) {
      icon = (
        <SVGIcon icon={this.props.icon as SVGIconType} className="w16px h16x" />
      );

      const label =
        this.props.placeholder === 'Sound'
          ? this.props.t('frameFields.tooltipVoiceover')
          : this.props.t('frameFields.tooltip', {
              fieldName: this.props.placeholder,
            });

      icon = (
        <Tooltip title={label} placement="left" as="div">
          <div className="inline-flex">
            <Icon icon={icon} aria-label={label} />
          </div>
        </Tooltip>
      );
    }

    const textareaProps = this.props.isInteractive
      ? {
          onKeyDown: this._onKeyPress,
          onChange: this._onInputChange,
          className: '',
        }
      : {
          disabled: true,
          className: this.props.onClick
            ? 'pointer-events-none'
            : 'cursor-not-allowed',
        };

    const textSize = {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-sm',
      lg: 'text-sm',
      xl: 'text-base',
    }[gridSize];

    textareaProps.className += ` ${textSize}`;

    const showCoreValueHint =
      this.props.field_id === 'voiceover' && this.props.frame.number === '1';

    return (
      <div
        className={classNames('relative frame__note', {
          'border-b border-b-border last:border-none': !this.props.isMinimal,
          'cursor-pointer': this.props.onClick,
        })}
        onClick={this.props.onClick}
      >
        <div className="flex items-start">
          {!noIconAndLabel && (
            <label
              className={classNames(
                'shrink-0 ',
                isLabel
                  ? 'truncate pt-0.5 pb-1 text-center text-type-subdued bg-surface'
                  : '',
                // Frame number styles
                isLabel && gridSize === 'xs' && 'rounded-sm text-xxs mt-2',
                isLabel && gridSize === 'sm' && 'rounded-md text-xs mt-2',
                isLabel && gridSize === 'md' && 'rounded-md text-xs mt-2.5',
                isLabel && gridSize === 'lg' && 'rounded-md text-sm mt-2.5',
                isLabel && gridSize === 'xl' && 'rounded-md text-sm mt-4',
                // Icon styles
                !isLabel && gridSize === 'xs' && 'mt-1.5 -ml-0.5 -mr-0.5 ',
                !isLabel &&
                  gridSize === 'sm' &&
                  'mt-1.5 pt-[0.1rem] -ml-0.5 -mr-0.5 ',
                !isLabel && gridSize === 'md' && 'mt-2.5 -ml-0.5 -mr-0.5 ',
                !isLabel && gridSize === 'lg' && 'mt-2.5 pt-[0.1rem]',
                !isLabel && gridSize === 'xl' && 'mt-2.5 pt-[0.45rem]',
              )}
              htmlFor={id}
              style={
                isLabel
                  ? stylesForIndexLength[
                      clampNumber(
                        this.props.indexLength,
                        0,
                        stylesForIndexLength.length - 1,
                      )
                    ].w
                  : {}
              }
            >
              {icon!}
            </label>
          )}

          <div className="flex-auto">
            <TourHintable
              canShow={showCoreValueHint}
              step={'addTextToFrame'}
              overlayPosition="right"
              showHighlight={false}
            >
              <TextArea
                inputSize="xs"
                noBorder
                id={id}
                ref={this.inputRef}
                autoFocus={showCoreValueHint}
                placeholder={this.props.placeholder}
                value={this.state.text}
                minRows={this.props.rows || 1}
                title={noIconAndLabel ? this.props.placeholder : undefined}
                dir="auto"
                style={fieldStyle}
                {...textareaProps}
                textAreaClassName={classNames(
                  gridSize === 'xs' && 'p-2',
                  gridSize === 'sm' && 'p-2',
                  gridSize === 'md' && 'p-2.5',
                  gridSize === 'lg' && 'p-3',
                  gridSize === 'xl' && 'p-4',
                  // Important because the disabled style would override
                  '!placeholder-text-type-empty prose',
                  textareaProps.className,
                  textareaProps.disabled && 'text-type-subdued/80',
                )}
                onClick={this.props.onClick}
                immediate={false}
              />
            </TourHintable>
          </div>
        </div>
      </div>
    );
  }
}

const translated = withTranslation(undefined, { withRef: true })(FrameNotes);
export default translated;
