/** @format */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { type ButtonProps } from 'blackbird/components/button/Button';
import { CopyFromStoryboard } from './CopyFieldsFromStoryboard';
import { type FrameField } from '../../types/storyboard';
import NewStoryboardAspectRatio from './NewStoryboardAspectRatio';
import NewStoryboardProjectsList from './NewStoryboardProjectsList';
import { advancedSettingsKey } from '../storyboard/Settings/StoryboardSettings';
import { StoryboardActions } from '../../flux/actions/storyboard';
import { LocalState } from '../../helpers/local-state';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Dialog from 'blackbird/components/dialog/Dialog';
import { type WithTranslation, withTranslation } from 'react-i18next';
import { RequestActions } from 'javascripts/flux/actions/request';
import ToggleButtonWithLabel from '../shared/ToggleButtonWithLabel';
import { AiPrompt } from 'blackbird/components/ai/AiPrompt';
import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';
import { useTemplates } from 'blackbird/components/templates/TemplatesContext';
import type { Project } from 'javascripts/flux/stores/dashboard';
import { isEmpty } from 'underscore';
import { type WizardContextProps } from 'blackbird/components/wizard/WizardContext';
import { TourHintable } from '../tours/TourHintable';
import { type availableTourNames } from 'javascripts/flux/stores/tours';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';
import { ActiveTemplateIndicator } from 'blackbird/components/templates/ActiveTemplateIndicator';
import { TeamUpgradePill } from 'blackbird/components/team/TeamUpgradePill';
import { hideWorkflowButtonLocalState } from 'javascripts/helpers/local-state';
import { type TeamAction } from 'blackbird/components/projects/ProjectsContext';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';

interface Props extends WithTranslation {
  show_project_dropdown: boolean;
  wizardContext: WizardContextProps;
  new_storyboard_project: any;
  frame_fields: FrameField[] | null;
  placeholder?: string;
  teamId?: number;
  defaultTemplateId?: string;
  actions?: TeamAction[];
  projects?: Project[];
  cta?: string;
  onClose?: () => void;
}

const NewStoryboardForm: React.FC<Props> = ({
  show_project_dropdown,
  wizardContext,
  new_storyboard_project,
  frame_fields,
  teamId,
  defaultTemplateId,
  projects,
  cta,
  onClose,
  actions,
  t,
}) => {
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const { showAi, useAssistant, setUseAssistant } = useContext(BoordsAiContext);
  const {
    setCurrentTemplateFromId,
    currentTemplate,
    isFetched,
    fetchTemplates,
    isFeatureAvailable,
    isTemplatesReleased,
  } = useTemplates();

  const [error, setError] = useState<string>();
  const [isValid, setIsValid] = useState(false);
  const [storyboardName, setStoryboardName] = useState('');
  const [starterId, setStarterId] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    LocalState.getValue(advancedSettingsKey) ?? false,
  );

  useEffect(() => {
    Track.event.defer('init_new_storyboard');
    Track.once.defer('activation_open_new_storyboard_form', {
      posthogCapture: true,
    });

    setTimeout(() => {
      nameFieldRef.current?.focus();
    }, 300);
  }, []);

  React.useEffect(() => {
    if (!isFetched) {
      fetchTemplates(teamId as any);
    }
  }, [teamId, isFetched]);

  useEffect(() => {
    if (defaultTemplateId && isFetched && isFeatureAvailable) {
      setCurrentTemplateFromId(defaultTemplateId);
    }
  }, [defaultTemplateId, isFetched, isFeatureAvailable]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const isNameEmpty = isEmpty(newName);

    setStoryboardName(newName);
    setIsValid(!isNameEmpty);

    if (!isNameEmpty) {
      Track.once.defer('name_new_storyboard');
    }
  };

  const handleToggleExpand = () => {
    const newExpandedState = !isExpanded;
    LocalState.setValue(advancedSettingsKey, newExpandedState);
    setIsExpanded(newExpandedState);
  };

  const createStoryboard = () => {
    setError(undefined);
    setIsSaving(true);

    return new Promise<void>((resolve, reject) => {
      if (storyboardName?.length) {
        StoryboardActions.create({
          documentName: storyboardName,
          projectId: new_storyboard_project.id,
          templateId: currentTemplate?.id ?? undefined,
          frame_fields: starterId ? undefined : frame_fields || undefined,
          generateThumbnails: starterId?.length > 0,
          starter_storyboard_id: starterId.length
            ? parseInt(starterId)
            : undefined,
          callback: (success) => {
            setIsSaving(false);
            if (success) {
              wizardContext.handleComplete('create-storyboard');

              Track.once.defer('activation_storyboard_created', {
                posthogCapture: true,
                isAi: false,
              });
              return resolve();
            }
            return reject();
          },
        });
      } else {
        setError('Please choose a name for your storyboard');
        setIsSaving(false);
        RequestActions.error.defer('Please give your storyboard a name!');
      }
    });
  };

  const tourIsActive = (tourName: availableTourNames) =>
    ToursStore.state.currentTours.length > 0 &&
    ToursStore.state.currentTours[0].name === tourName;

  const ToggleAssistant: React.FC = () => {
    if (!showAi) return null;

    return (
      <TourHintable
        hideNext
        overlayPosition="left"
        step={`wizardNewStoryboardToggle`}
      >
        <ToggleButtonWithLabel
          labelPosition="after"
          onChange={() => {
            if (setUseAssistant) {
              if (!useAssistant && tourIsActive(`wizardNewStoryboard`)) {
                ToursActions.advanceTour('wizardNewStoryboard');
              }
              setUseAssistant(!useAssistant);
            }
          }}
          value={useAssistant}
          label={t('modals.new_storyboard.useAssistant')}
        />
      </TourHintable>
    );
  };

  const btnProps: ButtonProps = {
    htmlType: 'submit',
    loading: isSaving,
    disabled: !isValid,
  };

  const ctaText = cta ?? t('modals.new_storyboard.button');

  const LegacyFields: React.FC<{ children?: React.ReactNode }> = ({
    children,
  }) => (
    <>
      <NewStoryboardAspectRatio size="lg" />

      {show_project_dropdown && (
        <NewStoryboardProjectsList
          size="lg"
          projectID={new_storyboard_project.id}
        />
      )}

      {isExpanded && !starterId.length && (
        <CopyFromStoryboard projectID={new_storyboard_project.id} />
      )}

      <div className="flex items-center">
        <div>{children}</div>
        {!starterId.length && projects && projects.length > 1 && (
          <div className="flex justify-end flex-auto mt-0">
            <SecondaryLink onClick={handleToggleExpand}>
              {isExpanded
                ? t('modals.new_storyboard.hide_options')
                : t('modals.new_storyboard.more_options')}
            </SecondaryLink>
          </div>
        )}
      </div>
    </>
  );

  return (
    <form className="relative w-full">
      <Dialog
        title={!isFetched ? null : t('modals.new_storyboard.title')}
        isDark
        hideClose={!isFetched}
        hideCancel
        hideActions={(useAssistant && showAi) || !isFetched}
        isOpen
        confirmBtnProps={btnProps}
        confirmText={ctaText}
        onConfirm={createStoryboard}
        onCancel={onClose}
        keepOpen={isSaving || !isFetched}
        actionChildren={<ToggleAssistant />}
      >
        {!isFetched ? (
          <div className="flex items-center justify-center pb-10 aspect-square">
            <LoadingIndicator text={null} />
          </div>
        ) : !showAi || !useAssistant ? (
          <div className="flex flex-col w-full gap-3">
            <div className="space-y-6">
              <TextInput
                inputSize="lg"
                label={t('modals.new_storyboard.labels.storyboard_name')}
                placeholder={currentTemplate?.placeholder || `Storyboard name`}
                labelClasses="font-semibold"
                name="storyboardName"
                id="storyboardName"
                autoFocus
                value={storyboardName}
                onChange={handleChange}
                autoComplete="off"
                required
                error={error}
              />

              {isTemplatesReleased && isFeatureAvailable ? (
                <>
                  {teamId ? (
                    // Templates available, has feature
                    <ActiveTemplateIndicator
                      teamId={teamId}
                      defaultTemplateId={defaultTemplateId}
                    >
                      <NewStoryboardAspectRatio size="lg" />
                    </ActiveTemplateIndicator>
                  ) : (
                    // Fall back to old UI
                    <LegacyFields />
                  )}
                </>
              ) : (
                // ) : isTemplatesReleased &&
                //   !isFeatureAvailable &&
                //   !hideWorkflowButtonLocalState.getValue() ? (
                //   // Templates available, does not have feature (show old UI with upgrade prompt)
                //   <LegacyFields>
                //     {actions?.includes('projects.create') ? (
                //       <TeamUpgradePill
                //         isAdmin={actions?.includes('team.billing')}
                //         label={`New`}
                //         title={`Create templates with Workflow`}
                //       />
                //     ) : null}
                //   </LegacyFields>
                // Templates unavailable (show old UI)
                <LegacyFields />
              )}
            </div>
          </div>
        ) : (
          <AiPrompt
            project_id={new_storyboard_project.id}
            actionChildren={<ToggleAssistant />}
          />
        )}
      </Dialog>
    </form>
  );
};

export default withTranslation()(NewStoryboardForm);
