/** @prettier */
import type { allTheActions } from '../flux/allTheActions';
import { StoreContext } from '../flux/StoreContextProvider';
import React from 'react';
import { isObject, isUndefined } from 'underscore';

type allOptions = typeof allTheActions;

/** A way of accessing store actions in the current React Context (as an
 * alternative to using globals). Use this function to prepare a store action
 * with certain props, then call it to execute (good for in onComponentMount).
 * It always uses `defer` */
export const useStoreAction = <
  T extends keyof allOptions,
  Y extends keyof allOptions[T],
>(
  storeName: T,
  action: Y,
  withProps?: allOptions[T][Y] extends (...args: any) => any
    ? Parameters<allOptions[T][Y]>[0]
    : never,
) => {
  const context = React.useContext(StoreContext);

  if (!context)
    throw new Error(
      `context is not defined, did you pass any stores to the StoreContextProvider?`,
    );

  const store = context?.actions as allOptions;
  const storeActions = store[storeName];

  if (!storeActions) {
    throw new Error(
      `Actions for the store ${storeName} were not found. Is it added to \`allTheActions.ts?\``,
    );
  }

  const output: any = storeActions[action];

  if (!output) {
    throw new Error(
      `The action called "${
        action as string
      }" was not found in ${storeName}. Does this action exist?`,
    );
  }

  return React.useCallback(
    (args?) => {
      let argumentsToUse: typeof withProps;
      const canBeExtended = (object) => isObject(object) || isUndefined(object);

      if (canBeExtended(args) && canBeExtended(withProps)) {
        argumentsToUse = { ...args, ...withProps };
      } else {
        argumentsToUse = withProps ?? args;
      }

      output.defer(argumentsToUse);
    },
    [output, withProps],
  );
};
