/** @format */

import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { posthog } from 'posthog-js';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { FrameActions } from 'javascripts/flux/actions/frame';
import logger from 'javascripts/helpers/logger';
import { rollbar } from 'javascripts/helpers/rollbar';
import type {
  GeneratorStyle,
  DetailedFrame,
  GeneratorPreferences,
} from 'javascripts/types/frame';
import type { IStoryboard } from 'javascripts/types/storyboard';
import React, {
  createContext,
  useState,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
} from 'react';

import i18n from 'i18next';
import {
  generatorCharacterLocalState,
  generatorStyleLocalState,
  generatorLastRunLocalState,
  generatorColoursLocalState,
} from 'javascripts/helpers/local-state';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { WizardContext } from '../wizard/WizardContext';
import { getUnixTime } from 'date-fns';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { getAccountStyle } from 'javascripts/helpers/account-type-config-helper';
const errorHandler = RequestErrorHandler('generator');

interface GeneratorProviderProps {
  children: React.ReactNode;
}

interface FrameApiResponse {
  data: {
    attributes: DetailedFrame;
  };
}

interface GeneratorPreview {
  id: number;
  large_url: string;
  thumbnail_url: string;
  seed?: number;
  created_at: Date;
}

interface TokenCount {
  total_token_count: number;
  recurring_token_count: number;
  topup_token_count: number;
}

interface GeneratorPreviewApiResponse {
  data: {
    attributes: {
      error: string | undefined;
      previews?: GeneratorPreview[];
    };
  }[];
}

export interface GeneratorPositive {
  age?: string;
  gender?: string;
  race?: string;
  hair?: string;
  clothing?: string;
  facial_features?: string;
}

interface GeneratorNegative {
  general?: string;
}

type GeneratorGuidelineType = 'character';

export interface GeneratorGuideline {
  id: string;
  name: string;
  guideline_type: GeneratorGuidelineType;
  positive: GeneratorPositive;
  negative: GeneratorNegative;
  is_global: boolean;
  is_active: boolean;
  preview_large_url?: string;
  preview_thumbnail_url?: string;
  preview_processing?: boolean;
  preview_processed_at?: Date;
  preview_seed?: number;
  updated_at: Date;
  preview_error?: string;
  preview_style?: string;
  team_id?: number;
}

interface GeneratorGuidelinesApiResponse {
  data: {
    attributes: {
      guidelines: GeneratorGuideline[];
    };
  };
}

interface TokenCountApiResponse {
  data: {
    attributes: TokenCount;
  };
}

type GeneratorError = undefined | string;

type Layout = 'singleColumn' | 'twoColumn';

export interface EventProps {
  event_name: string;
  args?: object;
}

interface PollGeneratorParams {
  firstRun?: boolean;
}

export interface GeneratorContextProps {
  colors: string[];
  setColors: Dispatch<SetStateAction<string[]>>;
  addColor: () => void;
  removeColor: (index: number) => void;
  GenerateImage: (parameters?: any) => Promise<void>;
  PollGenerator: (parameters: any) => Promise<void>;
  FetchGuidelines: () => Promise<void>;
  CancelGeneration: () => void;
  DiscardImage: () => void;
  UseImage: () => void;
  UseToken: () => void;
  GetTokenCount: () => void;
  TrackGeneratorEvent: (args: EventProps) => void;
  handlePurchase: () => void;
  selectedCharacter: GeneratorGuideline | undefined;
  setSelectedCharacter: Dispatch<
    SetStateAction<GeneratorGuideline | undefined>
  >;
  seed: number | undefined;
  setSeed: Dispatch<SetStateAction<number | undefined>>;
  canInsertSeedImage: boolean;
  setCanInsertSeedImage: Dispatch<SetStateAction<boolean>>;
  seedImageUrl: string | undefined;
  setSeedImageUrl: Dispatch<SetStateAction<string | undefined>>;
  editPromptWithSeed: boolean;
  setEditPromptWithSeed: Dispatch<SetStateAction<boolean>>;
  useSeed: boolean;
  setUseSeed: Dispatch<SetStateAction<boolean>>;
  hasCredits: boolean;
  setHasCredits: Dispatch<SetStateAction<boolean>>;
  teamAdminCreditPurchaseRequired: boolean;
  setTeamAdminCreditPurchaseRequired: Dispatch<SetStateAction<boolean>>;
  offerOpen: boolean | string;
  setOfferOpen: Dispatch<SetStateAction<boolean | string>>;
  canViewOffer: boolean;
  setCanViewOffer: Dispatch<SetStateAction<boolean>>;
  canBuyTopupCredits: boolean;
  setCanBuyTopupCredits: Dispatch<SetStateAction<boolean>>;
  setStyle: Dispatch<SetStateAction<GeneratorStyle>>;
  style: GeneratorStyle;
  styles: GeneratorStyle[];
  setLayout: Dispatch<SetStateAction<Layout>>;
  layout: Layout;
  tokenCount: number;
  setTokenCount: Dispatch<SetStateAction<number>>;
  definedTokenCount: boolean;
  setDefinedTokenCount: Dispatch<SetStateAction<boolean>>;
  definedStyle: boolean;
  setDefinedStyle: Dispatch<SetStateAction<boolean>>;
  isFlyoverGrid: boolean;
  setIsFlyoverGrid: Dispatch<SetStateAction<boolean>>;
  isPanelbar: boolean;
  setIsPanelbar: Dispatch<SetStateAction<boolean>>;
  isUnpaidUser: boolean;
  setIsUnpaidUser: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isGenerating: boolean;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  frame: DetailedFrame | undefined;
  setFrame: Dispatch<SetStateAction<DetailedFrame | undefined>>;
  storyboard: IStoryboard | undefined;
  setStoryboard: Dispatch<SetStateAction<IStoryboard>>;
  negativePrompt: string;
  setNegativePrompt: Dispatch<SetStateAction<string>>;
  errorMessage: GeneratorError;
  setErrorMessage: Dispatch<SetStateAction<GeneratorError>>;
  prompt: string;
  setPrompt: Dispatch<SetStateAction<string>>;
  generatorGuidelines: GeneratorGuideline[] | undefined;
  setGeneratorGuidelines: Dispatch<
    SetStateAction<GeneratorGuideline[] | undefined>
  >;
  generatorPreview: GeneratorPreview | undefined;
  setGeneratorPreview: Dispatch<SetStateAction<GeneratorPreview | undefined>>;
  filteredCharacters: GeneratorGuideline[] | undefined;
  setFilteredCharacters: React.Dispatch<
    React.SetStateAction<GeneratorGuideline[] | undefined>
  >;
}

export const initStyles = i18n.t('generator:styles', {
  returnObjects: true,
})['items'] as GeneratorStyle[];

// Also referenced in BoordsAiContext to define
// the default style for full storyboard generation
export const defaultStyle = initStyles.find(
  (s) => s.value === getAccountStyle(),
) as GeneratorStyle;

// Helper to validate hex colors
const isValidHexColor = (color: string): boolean => {
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return hexColorRegex.test(color);
};

// Helper to get valid colors from local state
const getValidColorsFromLocalState = (): string[] => {
  const savedColors = generatorColoursLocalState.getValue();
  if (!savedColors) return [];

  return savedColors.filter(isValidHexColor);
};

const defaultColors = ['#333333', '#fafaf5', '#86E9D3'];

const defaultValues: GeneratorContextProps = {
  colors: defaultColors,
  setColors: () => {},
  addColor: () => {},
  removeColor: () => {},
  GenerateImage: async () => {},
  DiscardImage: async () => {},
  GetTokenCount: async () => {},
  TrackGeneratorEvent: () => {},
  UseImage: async () => {},
  UseToken: async () => {},
  CancelGeneration: async () => {},
  PollGenerator: async () => {},
  FetchGuidelines: async () => {},
  handlePurchase: () => {},
  frame: undefined,
  setTokenCount: () => {},
  tokenCount: 1,
  setStyle: () => {},
  style: defaultStyle,
  styles: initStyles,
  setFrame: () => {},
  storyboard: undefined,
  selectedCharacter: undefined,
  setSelectedCharacter: () => {},
  errorMessage: undefined,
  setErrorMessage: () => {},
  editPromptWithSeed: false,
  setEditPromptWithSeed: () => {},
  seedImageUrl: undefined,
  setSeedImageUrl: () => {},
  isUnpaidUser: false,
  setIsUnpaidUser: () => {},
  seed: undefined,
  setSeed: () => {},
  layout: 'singleColumn',
  canInsertSeedImage: false,
  setCanInsertSeedImage: () => {},
  useSeed: false,
  setUseSeed: () => {},
  setLayout: () => {},
  setStoryboard: () => {},
  prompt: '',
  negativePrompt: '',
  setNegativePrompt: () => {},
  offerOpen: false,
  setOfferOpen: () => {},
  definedStyle: false,
  setDefinedStyle: () => {},
  definedTokenCount: false,
  setDefinedTokenCount: () => {},
  generatorGuidelines: undefined,
  setGeneratorGuidelines: () => {},
  hasCredits: true,
  setHasCredits: () => {},
  teamAdminCreditPurchaseRequired: false,
  setTeamAdminCreditPurchaseRequired: () => {},
  canViewOffer: false,
  setCanViewOffer: () => {},
  canBuyTopupCredits: false,
  setCanBuyTopupCredits: () => {},
  generatorPreview: undefined,
  setGeneratorPreview: () => {},
  isGenerating: false,
  setIsFlyoverGrid: () => {},
  isFlyoverGrid: false,
  setIsPanelbar: () => {},
  isPanelbar: false,
  setIsOpen: () => {},
  isOpen: false,
  setPrompt: () => {},
  setIsGenerating: () => {},
  filteredCharacters: undefined,
  setFilteredCharacters: () => {},
};

export const GeneratorContext =
  createContext<GeneratorContextProps>(defaultValues);

export const GeneratorProvider: React.FC<GeneratorProviderProps> = ({
  children,
}) => {
  const [colors, setColors] = useState<string[]>(() => {
    const localStateColors = getValidColorsFromLocalState();
    return localStateColors.length > 0 ? localStateColors : defaultColors;
  });

  const addColor = useCallback(() => {
    if (colors.length < 4) {
      const newColor = '#000000';
      setColors((prev) => [...prev, newColor]);
      generatorColoursLocalState.addToArray(newColor);
    }
  }, [colors]);

  const removeColor = useCallback(
    (index: number) => {
      const colorToRemove = colors[index];
      const newColors = colors.filter((_, i) => i !== index);
      setColors(newColors);

      // Update local state
      const currentLocalState = generatorColoursLocalState.getValue() || [];
      generatorColoursLocalState.setValue(
        currentLocalState.filter((color) => color !== colorToRemove),
      );
    },
    [colors],
  );
  const [isUnpaidUser, setIsUnpaidUser] = useState<boolean>(
    defaultValues.isUnpaidUser,
  );
  const [tokenCount, setTokenCount] = useState<number>(
    defaultValues.tokenCount,
  );
  const [seed, setSeed] = useState<number | undefined>(defaultValues.seed);
  const [seedImageUrl, setSeedImageUrl] = useState<string | undefined>(
    defaultValues.seedImageUrl,
  );

  const [filteredCharacters, setFilteredCharacters] = useState<
    GeneratorGuideline[] | undefined
  >(defaultValues.filteredCharacters);

  const [selectedCharacter, setSelectedCharacter] = useState<
    GeneratorGuideline | undefined
  >(defaultValues.selectedCharacter);

  const [canInsertSeedImage, setCanInsertSeedImage] = useState<boolean>(
    defaultValues.canInsertSeedImage,
  );
  const [useSeed, setUseSeed] = useState<boolean>(defaultValues.useSeed);
  const [editPromptWithSeed, setEditPromptWithSeed] = useState<boolean>(
    defaultValues.editPromptWithSeed,
  );
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(defaultValues.isOpen);
  const [isPanelbar, setIsPanelbar] = useState<boolean>(
    defaultValues.isPanelbar,
  );
  const [isFlyoverGrid, setIsFlyoverGrid] = useState<boolean>(
    defaultValues.isFlyoverGrid,
  );
  const [isGenerating, setIsGenerating] = useState<boolean>(
    defaultValues.isGenerating,
  );
  const [generatorGuidelines, setGeneratorGuidelines] = useState<
    GeneratorGuideline[] | undefined
  >(defaultValues.generatorGuidelines);

  const [definedStyle, setDefinedStyle] = useState<boolean>(
    defaultValues.definedStyle,
  );
  const [definedTokenCount, setDefinedTokenCount] = useState<boolean>(
    defaultValues.definedTokenCount,
  );

  const [errorMessage, setErrorMessage] = useState<GeneratorError>(
    defaultValues.errorMessage,
  );

  const [hasCredits, setHasCredits] = useState<boolean>(
    defaultValues.hasCredits,
  );
  const [canBuyTopupCredits, setCanBuyTopupCredits] = useState<boolean>(
    defaultValues.canBuyTopupCredits,
  );
  const [canViewOffer, setCanViewOffer] = useState<boolean>(
    defaultValues.canViewOffer,
  );
  const [offerOpen, setOfferOpen] = useState<boolean | string>(
    defaultValues.offerOpen,
  );
  const [teamAdminCreditPurchaseRequired, setTeamAdminCreditPurchaseRequired] =
    useState<boolean>(defaultValues.teamAdminCreditPurchaseRequired);

  const styles = initStyles;
  const [style, setStyle] = useState<GeneratorStyle>(defaultValues.style);

  const [layout, setLayout] = useState<Layout>(defaultValues.layout);
  const [frame, setFrame] = useState<DetailedFrame | undefined>(
    defaultValues.frame,
  );
  const [storyboard, setStoryboard] = useState<IStoryboard | undefined>(
    defaultValues.storyboard,
  );

  const [generatorPreview, setGeneratorPreview] = useState<
    GeneratorPreview | undefined
  >(defaultValues.generatorPreview);
  const [prompt, setPrompt] = useState<string>(defaultValues.prompt);
  const [negativePrompt, setNegativePrompt] = useState<string>(
    defaultValues.negativePrompt,
  );

  const teamId = BoordsConfig.StoryboardTeamId || BoordsConfig.TeamId;

  const { handleComplete } = React.useContext(WizardContext);

  const handleError = (error: GeneratorError | Error) => {
    if (typeof error === 'object') {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(error);
    }

    setIsGenerating(false);
    logger.error(error);
    rollbar.error(error);
  };

  const lastUsedOrDefaultStyle = () => {
    try {
      if (generatorStyleLocalState.getValue()) {
        setStyle(
          styles.find(
            (s) => s.value === generatorStyleLocalState.getValue(),
          ) as GeneratorStyle,
        );
      } else {
        setStyle(defaultStyle);
      }
    } catch {
      setStyle(defaultStyle);
    }
  };

  useEffect(() => {
    if (
      [`trialing`, `professional_free`].includes(
        BoordsConfig.StoryboardTeamPlanSlug,
      ) ||
      [`trialing`].includes(BoordsConfig.StoryboardTeamStatus)
    ) {
      setIsUnpaidUser(true);
    }
  }, []);

  // Set the seed/seed preview image
  useEffect(() => {
    if (generatorPreview && generatorPreview.seed) {
      setSeed(generatorPreview.seed);
      setSeedImageUrl(generatorPreview.thumbnail_url);
      setEditPromptWithSeed(true);
      setCanInsertSeedImage(true);
    } else if (frame && frame.seed) {
      setSeed(frame.seed);
      setSeedImageUrl(frame.thumbnail_image_url);
      setEditPromptWithSeed(true);
      setCanInsertSeedImage(false);
    } else {
      setSeed(undefined);
      setSeedImageUrl(undefined);
      setEditPromptWithSeed(true);
      setCanInsertSeedImage(false);
    }
  }, [frame, generatorPreview]);

  // Set the prompt/preset value based on the DB record
  useEffect(() => {
    if (isOpen || isPanelbar) {
      if (frame && frame.generator_preferences && generatorGuidelines) {
        if (typeof frame.generator_preferences === 'string') {
          const prefs: GeneratorPreferences = JSON.parse(
            frame.generator_preferences,
          );
          // Priority: generator preferences > local state > defaults
          if (prefs.colors && Array.isArray(prefs.colors)) {
            // Filter out invalid hex colors from preferences
            const validPrefColors = prefs.colors.filter(isValidHexColor);
            if (validPrefColors.length > 0) {
              setColors(validPrefColors);
            } else {
              // Fall back to local state if pref colors are invalid
              const localStateColors = getValidColorsFromLocalState();
              setColors(
                localStateColors.length > 0 ? localStateColors : defaultColors,
              );
            }
          } else {
            // No colors in preferences, try local state
            const localStateColors = getValidColorsFromLocalState();
            setColors(
              localStateColors.length > 0 ? localStateColors : defaultColors,
            );
          }

          if (prefs.prompt) {
            setPromptAndParseGuidelines(prefs.prompt);
          } else {
            setPrompt('');
            setCharacterFromLocalState();
          }
          if (prefs.negativePrompt) {
            setNegativePrompt(prefs.negativePrompt);
          } else {
            setNegativePrompt('');
          }
          if (prefs.preset) {
            try {
              const savedStyle = styles.find(
                (s) => s.value === prefs.preset,
              ) as GeneratorStyle;
              if (savedStyle) {
                setStyle(savedStyle);
              }
            } catch {
              setStyle(defaultStyle);
            }
          } else {
            lastUsedOrDefaultStyle();
          }
        } else {
          setPrompt('');
          setNegativePrompt('');
          setCharacterFromLocalState();
          // Try local state before falling back to defaults
          const localStateColors = getValidColorsFromLocalState();
          setColors(
            localStateColors.length > 0 ? localStateColors : defaultColors,
          );
          lastUsedOrDefaultStyle();
        }
      } else {
        lastUsedOrDefaultStyle();
        setCharacterFromLocalState();
        setNegativePrompt('');
        // Try local state before falling back to defaults
        const localStateColors = getValidColorsFromLocalState();
        setColors(
          localStateColors.length > 0 ? localStateColors : defaultColors,
        );
        setPrompt('');
      }
    }
  }, [frame, isOpen, isPanelbar, generatorGuidelines]);

  // Update local state when colors change
  useEffect(() => {
    const validColors = colors.filter(isValidHexColor);
    if (validColors.length > 0) {
      generatorColoursLocalState.setValue(validColors);
    }
  }, [colors]);

  useEffect(() => {
    if (isPanelbar) {
      Track.event.defer('generator_panelbar_open', {
        posthogCapture: true,
      });
    }
  }, [isPanelbar]);

  // Is this frame already generating?
  useEffect(() => {
    if (frame && frame.generator_processing) {
      setIsGenerating(frame.generator_processing);
    }
  }, [frame]);

  // do they have credits?
  useEffect(() => {
    setHasCredits(tokenCount > 0);
  }, [tokenCount]);

  // can they buy top up credits?
  useEffect(() => {
    if (
      [
        'individual',
        'group',
        'agency',
        'powerhouse',
        'professional_starter',
        'professional_medium',
      ].includes(BoordsConfig.PlanSlug) &&
      BoordsConfig.TeamRole === 'admin'
    ) {
      setCanBuyTopupCredits(true);
    }
  }, []);

  useEffect(() => {
    setDefinedStyle(true);
  }, [setStyle]);

  // does a team admin need to buy credits?
  useEffect(() => {
    if (!hasCredits && BoordsConfig.TeamRole !== 'admin') {
      setTeamAdminCreditPurchaseRequired(true);
    } else {
      setTeamAdminCreditPurchaseRequired(false);
    }
  }, [tokenCount, hasCredits]);

  const generatorTourStepActive = (stepName: string) =>
    ToursStore.state.currentSteps.length > 0 &&
    ToursStore.state.currentSteps[0].name === stepName;

  const generatorTourNameActive = (tourName: string) =>
    ToursStore.state.currentTours.length > 0 &&
    ToursStore.state.currentTours[0].name === tourName;

  const advanceTour = () => {
    if (generatorTourNameActive('wizardAddImage')) {
      ToursActions.advanceTour.defer('wizardAddImage');
    } else if (generatorTourNameActive('editing')) {
      ToursActions.advanceTour.defer('editing');
    }
  };

  // set layout
  useEffect(() => {
    if (
      !isPanelbar &&
      storyboard &&
      ['9x16', '9:16', '4:5', '4x5'].includes(storyboard.frame_aspect_ratio) &&
      (isGenerating || generatorPreview)
    ) {
      setLayout('twoColumn');
    } else {
      setLayout('singleColumn');
    }
  }, [storyboard, isGenerating, generatorPreview, isPanelbar]);

  const TrackGeneratorEvent = useCallback(
    ({ event_name, args = {} }: EventProps) => {
      if (storyboard && definedStyle && definedTokenCount) {
        const characterParams = selectedCharacter
          ? {
              hasCharacter: true,
              characterAge: selectedCharacter.positive.age,
              characterRace: selectedCharacter.positive.race,
              characterGender: selectedCharacter.positive.gender,
              characterIsGlobal: selectedCharacter.is_global ? true : false,
            }
          : {
              hasCharacter: false,
            };
        Track.event.defer(
          event_name,
          Object.assign(
            Object.assign(
              {
                posthogCapture: true,
                tokenCount: tokenCount,
                style: style.value,
                aspectRatio: storyboard.frame_aspect_ratio,
                isTeamAdmin: BoordsConfig.TeamRole === 'admin',
              },
              characterParams,
            ),
            args,
          ),
        );
      }
    },
    [
      storyboard,
      definedStyle,
      definedTokenCount,
      style,
      tokenCount,
      selectedCharacter,
    ],
  );

  const FetchGuidelines = async () => {
    const request = await apiRequest({
      path: `generator_guidelines?team_id=${teamId}`,
      method: 'get',
    });

    if (!request.ok) return errorHandler({ method: 'get' })(request);
    const response: GeneratorGuidelinesApiResponse = await request.json();

    setGeneratorGuidelines(response.data.attributes.guidelines);
  };

  // Poll when generating
  const PollGenerator = useCallback(
    async ({ firstRun = false }: PollGeneratorParams = {}) => {
      try {
        if (frame) {
          const request = await apiRequest({
            path: `frames/${frame.id}/generator_preview`,
            method: 'get',
          });

          const response: GeneratorPreviewApiResponse = await request.json();

          const { previews, error } = response.data[0].attributes;

          if (error && !firstRun) {
            handleError(error);
            setGeneratorPreview(undefined);
          } else if (previews && previews.length > 0) {
            setGeneratorPreview(previews[previews.length - 1]);
            setIsGenerating(false);

            // Hide the prompt editing view
            // when a new image is created
            if (!firstRun) {
              setEditPromptWithSeed(false);
            }

            if (startTime) {
              TrackGeneratorEvent({
                event_name: 'generator_complete',
                args: {
                  durationInSeconds: differenceInSeconds(new Date(), startTime),
                },
              });
            } else {
              TrackGeneratorEvent({ event_name: 'generator_complete' });
            }

            if (generatorTourStepActive('generatorTimer')) {
              advanceTour();
            }
          } else {
            setGeneratorPreview(undefined);
          }
        }
      } catch (e) {
        handleError(e);
      }
    },
    [frame, startTime],
  );

  useEffect(() => {
    if (isGenerating) {
      const interval = setInterval(() => {
        PollGenerator();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isGenerating]);

  const handlePurchase = () => {
    FlyoverActions.open.defer({
      type: BoordsConfig.CreditsAvailable ? 'credits' : 'inlinePricing',
    });
  };

  // Filter character guidelines based on selected style
  React.useEffect(() => {
    if (style && generatorGuidelines) {
      setFilteredCharacters(
        generatorGuidelines.filter((g) => g.preview_style === style.value),
      );
    }
  }, [style, generatorGuidelines, setStyle]);

  // Remove selected character if it doesnt match style change
  React.useEffect(() => {
    if (style && style.value && selectedCharacter) {
      if (style.value !== selectedCharacter.preview_style) {
        setSelectedCharacter(undefined);
        generatorCharacterLocalState.setValue(undefined);
      }
    }
  }, [style, selectedCharacter]);

  // Use a token
  const UseToken = useCallback(async () => {
    try {
      const request = await apiRequest({
        path: `tokens/${teamId}`,
        method: 'put',
      });

      if (!request.ok) return errorHandler({ method: 'put' })(request);
      const response: TokenCountApiResponse = await request.json();
      const newTokenCount = response.data.attributes.total_token_count;

      setTokenCount(newTokenCount);

      if (newTokenCount === 0) {
        Track.event.defer('generator_empty', {
          posthogCapture: true,
        });
      }
    } catch (e) {
      handleError(e);
    }
  }, []);

  const addGuidelinesToPrompt = useCallback(() => {
    let output = prompt;

    if (selectedCharacter) {
      output = `<character:${selectedCharacter.id}> ` + output;
    }

    return output;
  }, [prompt, selectedCharacter]);

  const setCharacterFromLocalState = useCallback(() => {
    const localStateCharacterId = generatorCharacterLocalState.getValue();

    if (localStateCharacterId && generatorGuidelines && style) {
      const savedCharacter = generatorGuidelines.find(
        (g) =>
          g.id === localStateCharacterId && g.preview_style === style.value,
      ) as GeneratorGuideline;

      if (savedCharacter) {
        setSelectedCharacter(savedCharacter);
      }
    }
  }, [generatorGuidelines, style]);

  const setPromptAndParseGuidelines = useCallback(
    (guidelinePrompt: string) => {
      const pattern = /<(\w+):([^>]+)>\s*/g;
      let cleanedPrompt = guidelinePrompt;
      let matchedCharacter: GeneratorGuideline | undefined = undefined;

      cleanedPrompt = guidelinePrompt.replace(pattern, (_match, type, id) => {
        // Check if the token type is 'character' and attempt to find the corresponding character
        if (type === 'character' && generatorGuidelines) {
          const character = generatorGuidelines.find(
            (g) =>
              g.guideline_type === type &&
              g.id === id &&
              g.preview_style === style.value,
          ) as GeneratorGuideline;
          if (character) {
            matchedCharacter = character; // Found a matching character
          }
        }
        // Remove all tokens, not just those of type 'character'
        return '';
      });

      // If a character was matched, update the selectedCharacter state
      if (matchedCharacter) {
        setSelectedCharacter(matchedCharacter);
      } else if (generatorCharacterLocalState.getValue() && !matchedCharacter) {
        setCharacterFromLocalState();
      } else {
        // logger.log('skipping character');
      }

      // Update the prompt state without the tokens
      setPrompt(cleanedPrompt.trim());
    },
    [generatorGuidelines, style],
  );

  const GenerateImage = useCallback(async () => {
    setIsGenerating(true);
    setErrorMessage(undefined);
    setStartTime(new Date());
    generatorLastRunLocalState.setValue(getUnixTime(new Date()).toString());
    // don't show the prompt editing UI
    setEditPromptWithSeed(false);

    if (frame && storyboard && style) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/generate`,
          method: 'post',
          payload: {
            prompt: addGuidelinesToPrompt(),
            negativePrompt: negativePrompt,
            preset: style.value,
            seed: seed && useSeed ? seed : 0,
            aspect_ratio: storyboard.frame_aspect_ratio,
            team_id: teamId,
            colors,
          },
        });

        if (!request.ok) return errorHandler({ method: 'post' })(request);
        const response: FrameApiResponse = await request.json();

        if (response.data[0].attributes.generator_preferences) {
          FrameActions.updateFrameGeneratorPreferences.defer({
            frameId: frame.id,
            generatorPreferences:
              response.data[0].attributes.generator_preferences,
          });
        }
        setGeneratorPreview(undefined);

        TrackGeneratorEvent({ event_name: 'generator_run' });

        if (isUnpaidUser) {
          UseToken();
        }
      } catch (e) {
        handleError(e);
      }
    }
  }, [
    isUnpaidUser,
    colors,
    teamId,
    frame,
    prompt,
    storyboard,
    style,
    selectedCharacter,
    negativePrompt,
    generatorPreview,
    seed,
    useSeed,
  ]);

  // Fetch token count
  const GetTokenCount = async () => {
    try {
      const request = await apiRequest({
        path: `tokens/${teamId}`,
        method: 'get',
      });

      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: TokenCountApiResponse = await request.json();

      setTokenCount(response.data.attributes.total_token_count);
      setDefinedTokenCount(true);
    } catch (e) {
      handleError(e);
    }
  };

  const CancelGeneration = useCallback(async () => {
    if (frame) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/generator_preview/cancel`,
          method: 'delete',
        });
        if (!request.ok) return errorHandler({ method: 'get' })(request);

        setIsGenerating(false);
      } catch (e) {
        handleError(e);
      }
    } else {
      handleError('Missing frame');
    }
  }, [frame, generatorPreview]);

  const DiscardImage = useCallback(async () => {
    if (frame) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/generator_preview/clear`,
          method: 'delete',
        });

        if (!request.ok) return errorHandler({ method: 'delete' })(request);

        setIsGenerating(false);
        setGeneratorPreview(undefined);
      } catch (e) {
        handleError(e);
      }
    } else {
      handleError('Missing frame');
    }
  }, [frame]);

  const UseImage = useCallback(async () => {
    if (frame && generatorPreview) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/generator_preview/${generatorPreview.id}`,
          method: 'put',
        });

        if (!request.ok) return errorHandler({ method: 'put' })(request);

        FrameActions.updateFrameImageUrls({
          frame: frame,
          seed: generatorPreview.seed,
          background_image_url: generatorPreview.large_url,
          large_image_url: generatorPreview.large_url,
          thumbnail_image_url: generatorPreview.thumbnail_url,
        });

        if (!isUnpaidUser) {
          await UseToken();
        }

        if (!isPanelbar) {
          setIsOpen(false);
          FlyoverActions.close.defer();
          setFrame(undefined);
        }

        setGeneratorPreview(undefined);

        // Update the frame preview seeds
        setSeed(seed);
        setSeedImageUrl(generatorPreview.thumbnail_url);

        TrackGeneratorEvent({ event_name: 'generator_insert' });
        handleComplete('add-image', GetTokenCount);

        if (generatorTourStepActive('generatorInsert')) {
          advanceTour();
        }
      } catch (e) {
        handleError(e);
      }
    } else {
      handleError('Missing frame/preview');
    }
  }, [frame, generatorPreview, isPanelbar, seed]);

  const value = {
    GenerateImage,
    CancelGeneration,
    UseImage,
    PollGenerator,
    isGenerating,
    setIsGenerating,
    frame,
    setFrame,
    layout,
    setLayout,
    negativePrompt,
    setNegativePrompt,
    prompt,
    setPrompt,
    storyboard,
    setStoryboard,
    canBuyTopupCredits,
    setCanBuyTopupCredits,
    isOpen,
    styles,
    setIsOpen,
    generatorPreview,
    DiscardImage,
    GetTokenCount,
    setGeneratorPreview,
    style,
    setStyle,
    tokenCount,
    setTokenCount,
    handlePurchase,
    teamAdminCreditPurchaseRequired,
    setTeamAdminCreditPurchaseRequired,
    hasCredits,
    setHasCredits,
    TrackGeneratorEvent,
    errorMessage,
    setErrorMessage,
    definedStyle,
    definedTokenCount,
    setDefinedStyle,
    setDefinedTokenCount,
    isPanelbar,
    setIsPanelbar,
    canViewOffer,
    setCanViewOffer,
    offerOpen,
    setOfferOpen,
    seed,
    setSeed,
    useSeed,
    setUseSeed,
    editPromptWithSeed,
    setEditPromptWithSeed,
    seedImageUrl,
    setSeedImageUrl,
    canInsertSeedImage,
    setCanInsertSeedImage,
    FetchGuidelines,
    generatorGuidelines,
    setGeneratorGuidelines,
    selectedCharacter,
    setSelectedCharacter,
    filteredCharacters,
    setFilteredCharacters,
    isFlyoverGrid,
    setIsFlyoverGrid,
    isUnpaidUser,
    setIsUnpaidUser,
    colors,
    setColors,
    addColor,
    removeColor,
    UseToken,
  };

  return (
    <GeneratorContext.Provider value={value}>
      {children}
    </GeneratorContext.Provider>
  );
};
