/** @prettier */
import React, { useContext, useEffect } from 'react';
import { GeneratorContext } from './GeneratorContext';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import { TextArea } from '../form/text-input/TextInput';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import Img from 'javascripts/components/shared/Img';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import format from 'date-fns/format';
import { FrameImageStatusOverlay } from 'javascripts/components/frames/editor/FrameImageStatus';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { GeneratorTimer } from './GeneratorTimer';
import { ModalDivider } from '../dialog/Dialog';
import classNames from 'classnames';
import { GeneratorStyles } from './GeneratorStyles';
import Toast from '../feedback/toast/Toast';
import { TourHintable } from 'javascripts/components/tours/TourHintable';
import { RequestActions } from 'javascripts/flux/actions/request';
import Toggle from '../toggle/Toggle';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { GuidelineCharacterDropdown } from './guidelines/GuidelineCharacterDropdown';
import { GeneratorCharatcerContext } from './guidelines/GeneratorCharacterContext';
import { GuidelineCharacterPreview } from './guidelines/GuidelineCharacterPreview';
import { GuidelineBetaBadge } from './guidelines/GuidelineBetaBadge';
import { hasRolloutFlagEnabled } from 'javascripts/helpers/rollout';
import { GeneratorStartButton } from './GeneratorStartButton';
import { SecondaryLink } from '../common/SecondaryLink';
import { GeneratorColors } from './GeneratorColors';
import { MagnifiedPreview } from '../inpaint/MagnifiedPreview';
import logger from 'javascripts/helpers/logger';
import { OfferInline } from '../offer/OfferInline';

interface IGeneratorFormProps {
  panelbar?: boolean;
}

export const ButtonWithCreditIndicator: React.FC<{ isPaidUser?: boolean }> = ({
  children,
  isPaidUser,
}) => {
  return (
    <div className="relative w-full group">
      {children}

      {isPaidUser && (
        <Tooltip title={`Uses 1 image credit`} placement="top">
          <div className="absolute mt-0.5 top-0 -right-2 text-xs font-normal bg-black/10 rounded-md px-2.5 py-0.5">{`1`}</div>
        </Tooltip>
      )}
    </div>
  );
};

const EmptyCta: React.FC = () => {
  return (
    <div className="flex justify-center mb-6 ">
      <div className="w-full p-3 text-center border rounded-xl bg-brand-yellow-25/50 border-brand-yellow">
        <div className="">{`Need More Images?`}</div>
        {BoordsConfig.CreditsAvailable ? (
          <>
            <div className="mt-1 mb-4 text-sm opacity-50">{`Get more image generator credits, no subscription required.`}</div>
            <Button
              rounded
              type="yellow"
              size="sm"
              className="w-full"
              onClick={() => {
                FlyoverActions.open.defer({ type: 'credits' });
              }}
            >
              {`See Credit Options`}
            </Button>
          </>
        ) : (
          <>
            <div className="mt-1 mb-4 text-sm opacity-50">{`Get unlimited AI credits with Workflow`}</div>
            <Button
              rounded
              type="yellow"
              size="sm"
              className="w-full"
              onClick={() => {
                FlyoverActions.open.defer({ type: 'inlinePricing' });
              }}
            >
              {`Compare Plans`}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export const GeneratorForm: React.FC<IGeneratorFormProps> = ({ panelbar }) => {
  const {
    canInsertSeedImage,
    frame,
    isFlyoverGrid,
    isUnpaidUser,
    selectedCharacter,
    isGenerating,
    prompt,
    errorMessage,
    setPrompt,
    negativePrompt,
    setNegativePrompt,
    UseImage,
    DiscardImage,
    GenerateImage,
    PollGenerator,
    hasCredits,
    teamAdminCreditPurchaseRequired,
    CancelGeneration,
    GetTokenCount,
    generatorPreview,
    storyboard,
    style,
    isPanelbar,
    setIsPanelbar,
    setIsOpen,
    layout,
    editPromptWithSeed,
    setEditPromptWithSeed,
    useSeed,
    setUseSeed,
    seed,
    seedImageUrl,
    FetchGuidelines,
  } = useContext(GeneratorContext);

  const { isCharacterEditorOpen } = useContext(GeneratorCharatcerContext);

  useEffect(() => {
    PollGenerator({ firstRun: true });
    GetTokenCount();
    FetchGuidelines();
  }, [frame]);

  useEffect(() => {
    if (panelbar) {
      setIsPanelbar(true);
      setIsOpen(true);
    }
  }, [panelbar]);

  const PreviewPrompt: React.FC = () => (
    <div
      className={classNames(
        'mb-8',
        layout === 'singleColumn' && 'mt-6',
        isPanelbar && 'text-sm',
      )}
    >
      <div className="mb-0.5 font-semibold">
        {selectedCharacter ? (
          <span className="flex items-center space-x-2 mb-0.5">
            <span>{`Character Prompt`}</span>
            <div className="-mt-0.5">
              <GuidelineBetaBadge size="sm" />
            </div>
          </span>
        ) : (
          `Prompt`
        )}
      </div>
      <div className="flex items-start">
        {selectedCharacter && (
          <div className="flex-shrink-0 mr-[0.25rem] mt-[0.35rem]">
            <GuidelineCharacterPreview character={selectedCharacter} hideName />
          </div>
        )}
        <div className="flex-auto text-type-subdued">{prompt}</div>
      </div>
      {negativePrompt.length > 1 && (
        <div className="mt-6">
          <div className="mb-0.5 font-semibold">{`Negative Prompt`}</div>
          <div className="text-type-subdued">{negativePrompt}</div>
        </div>
      )}
    </div>
  );

  interface PreviewMetadataProps {
    title: string;
    children: React.ReactElement;
  }

  const PreviewMetadata: React.FC<PreviewMetadataProps> = ({
    title,
    children,
  }) => (
    <div
      className={classNames(
        'flex items-center space-x-2',
        isPanelbar ? 'text-xs' : 'text-sm',
      )}
    >
      <div className="text-type-disabled">{title}</div>
      <div className="text-type-subdued">{children}</div>
    </div>
  );

  const PreviewMetaGrid: React.FC = ({ children }) => (
    <div
      className={
        layout === 'singleColumn' && !panelbar
          ? 'grid grid-cols-2 space-x-2'
          : 'space-y-2'
      }
    >
      {children}
    </div>
  );

  const buttonSize = layout === 'singleColumn' && !panelbar ? 'lg' : 'md';

  return !frame || !storyboard ? null : (
    <div>
      {errorMessage && (
        <div className="mb-8">
          <Toast kind="error" size="full" message={errorMessage} />
        </div>
      )}

      {isGenerating ? (
        <div className={layout === 'twoColumn' ? 'grid grid-cols-2 gap-6' : ''}>
          <TourHintable
            step="generatorTimer"
            overlayPosition="left"
            canShow
            hideNext
          >
            <div className="relative">
              <FrameImageStatusOverlay title="Generating...">
                <LoadingIndicator className="w-3 h-3 text-white" fast />
              </FrameImageStatusOverlay>

              <Img
                width={
                  BoordsFrameSizeHelper(storyboard.frame_aspect_ratio).width
                }
                height={
                  BoordsFrameSizeHelper(storyboard.frame_aspect_ratio).height
                }
                src={getFrameImage(undefined, storyboard.frame_aspect_ratio)}
              />
            </div>
          </TourHintable>

          <div>
            <PreviewPrompt />

            <PreviewMetaGrid>
              <PreviewMetadata title={`Timer`}>
                <GeneratorTimer />
              </PreviewMetadata>
              <PreviewMetadata title={`Style`}>
                <>{style.label}</>
              </PreviewMetadata>
            </PreviewMetaGrid>

            <div className="my-8">
              {layout === 'singleColumn' && <ModalDivider />}
            </div>

            <Button
              className="w-full"
              type="secondary"
              size={buttonSize}
              onClick={CancelGeneration}
            >{`Cancel`}</Button>
          </div>
        </div>
      ) : generatorPreview && !editPromptWithSeed ? (
        <TourHintable
          step={'generatorInsert'}
          overlayPosition="left"
          onNext={() => {
            UseImage();
          }}
          canShow
          deferred
        >
          <div
            className={layout === 'twoColumn' ? 'grid grid-cols-2 gap-6' : ''}
          >
            <MagnifiedPreview
              thumbnail_url={generatorPreview.thumbnail_url}
              aspect_ratio={storyboard.frame_aspect_ratio}
            />

            <div>
              <PreviewPrompt />

              <PreviewMetaGrid>
                <PreviewMetadata title={`Created`}>
                  <>
                    {`${format(
                      new Date(generatorPreview.created_at),
                      `d MMM yyyy, HH:mm`,
                    )}`}
                  </>
                </PreviewMetadata>
                <PreviewMetadata title={`Style`}>
                  <>{style.label}</>
                </PreviewMetadata>
              </PreviewMetaGrid>

              <div className="my-8">
                {layout === 'singleColumn' && <ModalDivider />}
              </div>

              <div className="space-y-4">
                <Button
                  className="w-full"
                  type="fancy"
                  size={buttonSize}
                  onClick={UseImage}
                >
                  <ButtonWithCreditIndicator isPaidUser={!isUnpaidUser}>
                    {`Insert Image`}
                  </ButtonWithCreditIndicator>
                </Button>
                {generatorPreview.seed ? (
                  <Button
                    className="w-full"
                    type="secondary"
                    size={buttonSize}
                    onClick={() => setEditPromptWithSeed(true)}
                  >{`Back`}</Button>
                ) : (
                  <div
                    className={classNames(
                      'grid gap-4',
                      isPanelbar
                        ? 'grid-cols-1 xl:grid-cols-2'
                        : 'grid-cols-2 ',
                    )}
                  >
                    <Tooltip
                      title={`No image credits remaining`}
                      disabled={hasCredits}
                    >
                      <Button
                        className="w-full"
                        type="secondary"
                        size={buttonSize}
                        disabled={!hasCredits}
                        onClick={GenerateImage}
                      >
                        {`Retry`}
                      </Button>
                    </Tooltip>
                    <Button
                      className="w-full"
                      type="secondary"
                      size={buttonSize}
                      onClick={DiscardImage}
                    >{`Back`}</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </TourHintable>
      ) : (
        <>
          <div
            className={classNames(
              isFlyoverGrid && 'grid grid-cols-2 gap-8',
              'relative',
            )}
          >
            <div className="relative z-20">
              {!isPanelbar && (
                <div className="mb-8">
                  <ModalDivider />
                </div>
              )}

              {!hasCredits && <EmptyCta />}

              {/* Generator form */}
              <div
                className={classNames(
                  'relative space-y-6',
                  !hasCredits && 'opacity-50 cursor-not-allowed',
                )}
              >
                <GeneratorStyles />

                {style.hasColors && <GeneratorColors />}

                <>
                  {isCharacterEditorOpen && <FlyoverCharacterEditor />}
                  <GuidelineCharacterDropdown />
                </>

                <TourHintable
                  step={'generatorPrompt'}
                  overlayPosition="left"
                  onNext={() => {
                    GenerateImage(null);
                  }}
                  canShow
                  disableNext={prompt.length < 1}
                >
                  <TextArea
                    className={classNames(!hasCredits && 'cursor-not-allowed')}
                    plainText
                    disabled={!hasCredits}
                    // autoFocus - this prevents the prompt value from updating correctly
                    immediate
                    labelClasses={classNames(
                      'font-semibold w-full',
                      isPanelbar && 'text-sm',
                    )}
                    inputSize={isPanelbar ? 'md' : 'lg'}
                    label={
                      <div className="flex items-center w-full">
                        <div className="flex-auto">
                          {selectedCharacter ? `Character Prompt` : `Prompt`}
                        </div>
                      </div>
                    }
                    placeholder={
                      selectedCharacter
                        ? `What is your character doing? E.g. walking in a park`
                        : `What do you want to see?`
                    }
                    onChange={setPrompt}
                    value={prompt}
                    minRows={3}
                    maxCharacters={1600}
                  />
                </TourHintable>

                {!style.positivePromptOnly && (
                  <TextArea
                    plainText
                    immediate
                    disabled={!hasCredits}
                    placeholder={`What do you want to avoid?`}
                    labelClasses={classNames(
                      'font-semibold',
                      isPanelbar && 'text-sm',
                    )}
                    inputSize={isPanelbar ? 'md' : 'lg'}
                    label={`Negative Prompt`}
                    onChange={setNegativePrompt}
                    value={negativePrompt}
                    maxCharacters={1600}
                  />
                )}
              </div>
            </div>
            <div className="relative z-10">
              {/* Generator Preview Image */}
              {editPromptWithSeed && seed && seedImageUrl && (
                <div
                  className={classNames(
                    'my-6',
                    !hasCredits && 'opacity-50 cursor-not-allowed',
                    isFlyoverGrid && 'flex flex-col min-h-full',
                  )}
                >
                  <div className="flex items-baseline mb-2">
                    <div
                      className={classNames(
                        'flex-auto font-semibold',
                        isPanelbar ? 'text-sm' : 'text-base',
                      )}
                    >
                      {generatorPreview ? (
                        <>{`Generated Image`}</>
                      ) : (
                        <>{`Current Frame Image`}</>
                      )}
                    </div>

                    <div className="text-xs text-type-disabled">
                      {generatorPreview ? (
                        <>{`Created ${format(
                          new Date(generatorPreview.created_at),
                          `d MMM, HH:mm`,
                        )}`}</>
                      ) : (
                        <>{`Frame ${frame.number}`}</>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'relative flex justify-center items-center border border-form rounded-md px-4 py-3.5',
                      !useSeed ? 'bg-surface-light' : 'bg-surface',
                      isFlyoverGrid && 'flex-auto',
                    )}
                  >
                    <div
                      className={classNames(
                        'rounded-sm overflow-hidden flex-shrink-0 relative group',
                        storyboard.frame_aspect_ratio === '1x1'
                          ? 'w-1/2'
                          : storyboard.frame_aspect_ratio === '16x9'
                            ? 'w-2/3'
                            : ['1.85:1', '2.4:1'].includes(
                                  storyboard.frame_aspect_ratio,
                                )
                              ? 'w-full'
                              : ['9x16', '4x5'].includes(
                                    storyboard.frame_aspect_ratio,
                                  )
                                ? isPanelbar
                                  ? 'w-1/2'
                                  : 'w-1/4'
                                : 'w-1/2',
                      )}
                    >
                      {canInsertSeedImage && (
                        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-0 group-hover:opacity-100">
                          <div className="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black/10" />
                          <div className="flex flex-col items-center space-y-2">
                            <Button
                              type="solid"
                              size={'sm'}
                              onClick={UseImage}
                              className="relative z-10"
                            >
                              <span className="text-xs">{`Insert Image`}</span>
                            </Button>

                            <Button
                              type="secondary"
                              size={'sm'}
                              onClick={DiscardImage}
                              className="relative z-10"
                            >
                              <span className="text-xs">{`Discard`}</span>
                            </Button>
                          </div>
                        </div>
                      )}
                      <div>
                        <Img
                          src={getFrameImage(
                            seedImageUrl,
                            storyboard.frame_aspect_ratio,
                          )}
                          width={
                            BoordsFrameSizeHelper(storyboard.frame_aspect_ratio)
                              .width
                          }
                          height={
                            BoordsFrameSizeHelper(storyboard.frame_aspect_ratio)
                              .height
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      'flex items-center mt-3',
                      isFlyoverGrid && 'mb-6',
                    )}
                  >
                    <Toggle
                      value={useSeed}
                      onChange={() => setUseSeed(!useSeed)}
                    />

                    <div
                      className={classNames(
                        'ml-1.5 -mt-0.5 whitespace-nowrap flex items-center',
                        isPanelbar ? 'text-xs ' : 'text-sm ',
                        useSeed ? 'text-type-primary' : 'text-type-disabled',
                      )}
                    >
                      {generatorPreview ? (
                        <>{`Use Generated Image as Seed`}</>
                      ) : (
                        <>{`Use Frame Image as Seed`}</>
                      )}

                      <span className="-mt-0.5">
                        <Tooltip
                          as="span"
                          title={`Use a 'seed' image to create a similar version of an existing image by adjusting text prompts. Using the same prompt and style will generate an identical image.`}
                        >
                          <span className="ml-1 -mt-1 underline text-type-subdued decoration-dotted cursor-help underline-offset-2 decoration-border-mid">
                            <InformationCircleIcon className="inline w-3.5 h-3.5 text-type-primary" />
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {hasCredits ? (
            <div className="my-8">
              <ModalDivider />
            </div>
          ) : (
            <div className="mb-4" />
          )}
          {/* Buttons */}
          <div>
            {hasCredits ? (
              <Tooltip
                title={`Please enter a prompt`}
                disabled={prompt !== ''}
                placement="top"
              >
                <GeneratorStartButton buttonSize={buttonSize} />
              </Tooltip>
            ) : teamAdminCreditPurchaseRequired ? (
              <Tooltip title={`Please contact team admin to request credits`}>
                <Button
                  className="w-full"
                  type="fancy"
                  size="lg"
                  disabled
                  onClick={GenerateImage}
                >
                  {`Generate`}
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};
