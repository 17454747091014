/** @prettier */
import * as mapValues from 'async/mapValues';
import { compact, pick } from 'underscore';
import type { SVGIconID } from '../../../types/icons';
import { loadPDFImage } from './loadFrameImages';
import type { Base64FrameImagesResult } from '../types';
import type { FrameField } from '../../../types/storyboard';

let savedResults: Record<SVGIconID | 'footerLogo', Base64FrameImagesResult>;

/**
 * Load and cache all the pdf icons and the footer logo so that they can be
 * loaded easily.
 * TODO: Potentially optimisable by only fetching the used icons
 */
export const loadPDFIcons = (
  footerLogo?: string,
  frameFields?: FrameField[],
) => {
  const iconNamesToUse: string[] = frameFields
    ? compact(frameFields.map((i) => i.icon))
    : Object.keys(BoordsConfig.pdfIconUrls);

  const iconsToFetch = pick(BoordsConfig.pdfIconUrls, iconNamesToUse);
  iconsToFetch['footerLogo'] = footerLogo;

  return new Promise((resolve, reject) => {
    mapValues(
      iconsToFetch,
      (url, key, done) => {
        if (!url) return done();
        loadPDFImage(url, done, { failSilent: true });
      },
      (error, result) => {
        if (error) return reject(error);
        savedResults = result;
        resolve(result);
      },
    );
  });
};

/** get the image data of a pre-fetched icon  */
export const getPDFIcon = (
  key: keyof typeof savedResults,
): Base64FrameImagesResult | undefined | null => savedResults[key];
