/** @format */
import * as memoize from 'memoizee';
interface Options {
  type: 'text/javascript';
  charset: 'utf8';
  async: true;
}

export const loadScriptPromise = memoize(
  (src: string, opts?: Partial<Options>) =>
    new Promise<void>((resolve, reject) => {
      loadScript(src, opts, (err) => {
        if (err) return reject(err);
        resolve();
      });
    }),
);
