/** @format */

import React, { useEffect, useState } from 'react';
import BoordsLogo from 'blackbird/images/logo/logotype.svg';

interface WatermarkProps {
  children: React.ReactElement;
  isMissing?: boolean;
}

export const Watermark: React.FC<WatermarkProps> = ({
  children,
  ...props
}): React.ReactElement => {
  const [show, setShow] = useState(false);

  const childSrc = children.props?.src;
  useEffect(() => {
    // Yes, it would be better to do this with the permissions controller,
    // however controlling data loading across many frames would make this very
    // complex, and require an additional context, then wrapping all the relevant
    // components in that context. This allows us to get this information on a
    // per-storyboard basis.
    const isMissing =
      props.isMissing || childSrc?.indexOf('assets/missing') > -1;
    if (
      !isMissing &&
      (BoordsConfig.IsProfessionalFree || BoordsConfig.Freeloader)
    ) {
      setShow(true);
    }
  }, [childSrc, props.isMissing]);

  return !show ? (
    <>{children}</>
  ) : (
    <div className="relative">
      <div className="absolute top-0 bottom-0 left-0 right-0 z-[1] flex items-center justify-center">
        <div className="w-1/2">
          <BoordsLogo className="opacity-20" />
        </div>
      </div>
      {/* <div */}
      {/*   className="bg-repeat absolute top-0 bottom-0 left-0 right-0 z-[1] flex items-center justify-center opacity-100" */}
      {/*   style={{ */}
      {/*     backgroundSize: '50% auto', */}
      {/*     backgroundImage: `url('${cdnUrl('/assets/watermark-pattern.png')}`, */}
      {/*   }} */}
      {/* /> */}
      {children}
    </div>
  );
};
