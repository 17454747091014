/** @prettier */
/* eslint  react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import { OverlayListItemNew as OverlayListItem } from 'javascripts/components/shared/OverlayListItemNew';
import type { fileStackSource } from '../../../flux/stores/filestack';
import type { IPanelProps } from '../../shared/MultiPanel';
import type { IStoryboardInStore } from '../../../types/storyboard';
import { FilestackActions } from '../../../flux/actions/filestack';
import type { DetailedFrame, IFrame } from '../../../types/frame';
import FrameUserActions from '../../shared/FrameUserActions';
import { filter, size } from 'underscore';
import DropboxIcon from 'blackbird/images/icons/dropbox.svg';
import GoogleDriveIcon from 'blackbird/images/icons/google-drive.svg';
import { isImagePlaceholder } from 'javascripts/helpers/isImagePlaceholder';
import { FilmIcon } from '@heroicons/react/24/outline';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';
import {
  Copy,
  Fullscreen,
  Images,
  ImageUp,
  MessageCirclePlus,
  MessageSquareDot,
  Sparkles,
  SquareMinus,
  Trash2,
  WandSparkles,
} from 'lucide-react';
import { FrameActions } from 'javascripts/flux/actions/frame';
import {
  imageEditorSidebarModeLocalState,
  panelbarFocusLocalState,
} from 'javascripts/helpers/local-state';
import { type imageEditorSidebarMode } from 'javascripts/components/panelbars/PanelbarImageLibrary';
import classNames from 'classnames';
import { TeamUpgradePill } from 'blackbird/components/team/TeamUpgradePill';

const listItemProps = {
  showBorder: false,
  center: false,
  showArrow: false,
  padding: 'px-1',
  height: 'h-[42px]',
  // size: 'small',
};

/** Used in the Checkout overlay when free users try to use web sources */
const nameMap = {
  dropbox: 'Dropbox',
  googledrive: 'Google Drive',
};

interface Props {
  storyboard: IStoryboardInStore;
}

export interface AddProps extends Props {
  replace: false;
}

export interface ReplaceProps extends Props {
  replace: true;
  currentFrame: IFrame;
}

export class ImageSourcesPanel extends React.PureComponent<
  (AddProps | ReplaceProps) & IPanelProps,
  { actions: ReturnType<typeof FrameUserActions>; hasImages: boolean }
> {
  static contextType = DialogContext;

  constructor(props) {
    super(props);
    this.state = {
      actions: {},
      hasImages: false,
    };
  }

  componentDidMount(): void {
    this.setActions(this.props);
  }
  /**
   * If a service is limited to paid users, this handler will open up a checkout
   * overlay instead of the Filestack overlay
   */
  handleLimitedSourceClick = (e: React.MouseEvent<HTMLElement>) => {
    const source = (e.currentTarget as HTMLButtonElement)
      .value as fileStackSource;
    const name = nameMap[source];
    if (!name)
      throw new Error(
        `could not find name for service ${source} in ${Object.keys(nameMap)}`,
      );

    if (BoordsConfig.Freeloader && BoordsConfig.IsStoryboardAdmin) {
      FlyoverActions.open.defer({
        type: 'checkout',
        plan_name: 'standard',
        frequency: 'monthly',
        props: {
          checkoutHeader: 'Upgrade to upload images from ' + name,
        },
      });
    } else {
      this.handleFilestackSourceClick(e);
    }
  };

  handleVideoImportClick = () => {
    FlyoverActions.open.defer({
      type: 'importVideo',
      frame: this.props.replace ? this.props.currentFrame : undefined,
      props: {
        replace: this.props.replace,
      },
    });
  };

  handleFilestackSourceClick = (e: React.MouseEvent<HTMLElement>) => {
    const source = (e.currentTarget as HTMLButtonElement)
      .value as fileStackSource;

    const props = this.props.replace
      ? {
          replace: true,
          frame: this.props.currentFrame,
          multi: false,
        }
      : {
          multi: true,
        };

    FilestackActions.openDialog({
      team_id: this.props.storyboard.project.owner.id,
      storyboard_id: this.props.storyboard.id,
      frame_aspect_ratio: this.props.storyboard.frame_aspect_ratio,
      initial_source: source,
      ...props,
    });

    Track.event.defer('open_image_search');
    Track.once.defer('open_' + source);
  };

  setActions(props) {
    // I know, I know…
    const { frames, groupInfo } = FrameStore.getState();
    const oldActionCount = size(this.state.actions);

    const hasImages =
      filter(
        frames,
        (i: DetailedFrame) =>
          i.large_image_url && !isImagePlaceholder(i.large_image_url),
      ).length > 1;

    const actions = FrameUserActions({
      isGuest: BoordsConfig.TeamRole === 'guest',
      commentsEnabled: props.storyboard.has_comments_enabled,
      framesToOperateOn: props.replace ? [props.currentFrame] : [],
      storyboard: props.storyboard,
      frameCount: frames.length,
      groupInfo: groupInfo,
      dialogContext: this.context,
    });

    this.setState({ actions, hasImages }, () => {
      if (oldActionCount !== size(actions)) {
        this.props.updateHeight();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setActions(this.props);
    }
  }

  private openCommentsPanelbar(e: React.MouseEvent) {
    const { actions } = this.state;

    panelbarFocusLocalState.setValue('frameInfo');
    if (actions.edit && actions.edit.onClick) {
      actions.edit.onClick(e);
    }
  }

  private openEditWithTab(e: React.MouseEvent, tab: imageEditorSidebarMode) {
    const { actions } = this.state;

    panelbarFocusLocalState.setValue('images');
    imageEditorSidebarModeLocalState.setValue(tab);
    if (actions.edit && actions.edit.onClick) {
      actions.edit.onClick(e);
    }
  }

  // This was necessary to use currentFrame which only exists when replace
  // is present.
  private renderReplaceContent() {
    if (!this.props.replace) return null;
    const { currentFrame, storyboard } = this.props;

    const noCommenting = [`lite`].includes(BoordsConfig.StoryboardTeamPlanSlug);
    const isEmpty = currentFrame.large_image_url.includes('assets/missing');

    return (
      <>
        {BoordsConfig.ShowGeneratorUI && (
          <>
            <OverlayListItem
              {...listItemProps}
              text="Generate"
              lucideIcon={Sparkles}
              onClick={(e) => {
                this.openEditWithTab(e, 'ai');
              }}
            />

            {!isEmpty && (
              <OverlayListItem
                {...listItemProps}
                text="Magic Fill"
                lucideIcon={WandSparkles}
                onClick={(e) => {
                  this.openEditWithTab(e, 'inpaint');
                }}
              />
            )}
          </>
        )}

        <div className="my-2 border-b border-border" />

        <OverlayListItem
          {...listItemProps}
          onClick={(e) => {
            e.preventDefault();
            FrameActions.insertFrame({
              dropped_sort_order: parseInt(currentFrame.sort_order) + 1,
              frame_to_duplicate: currentFrame,
              move_frames_after_here: true,
              storyboard_id: storyboard.id,
            });
          }}
          text="Duplicate"
          lucideIcon={Copy}
        />
        <OverlayListItem
          {...listItemProps}
          onClick={(e) => {
            e.preventDefault();
            if (confirm(`Delete frame?`)) {
              FrameActions.deleteFrames(currentFrame.id);
            }
          }}
          text="Delete"
          lucideIcon={Trash2}
        />

        <div className="my-2 border-b border-border" />

        {storyboard.has_comments_enabled && (
          <div className={classNames('flex items-center pr-3')}>
            <div
              className={classNames('flex-auto', noCommenting && 'opacity-50')}
            >
              <OverlayListItem
                {...listItemProps}
                onClick={(e) => {
                  e.preventDefault();
                  if (noCommenting) {
                    FlyoverActions.open.defer({
                      type: 'inlinePricing',
                    });
                  } else {
                    this.openCommentsPanelbar(e);
                  }
                }}
                text="Comment"
                lucideIcon={MessageCirclePlus}
              />
            </div>
            {noCommenting && <TeamUpgradePill showPlanName={false} />}
          </div>
        )}

        <OverlayListItem
          {...listItemProps}
          onClick={this.props.goToPanelF('setStatus')}
          text="Status"
          showArrow={true}
          lucideIcon={MessageSquareDot}
        />

        <div className="my-2 border-b border-border" />
      </>
    );
  }

  render() {
    const actions = this.state.actions;
    if (!actions) return null;

    return (
      <div className="p-2">
        {actions.edit && (
          <>
            <OverlayListItem
              {...listItemProps}
              onClick={(e) => this.openEditWithTab(e, 'ai')}
              text="Edit"
              lucideIcon={Fullscreen}
            />

            {this.renderReplaceContent()}
          </>
        )}

        {actions.upload ? (
          <OverlayListItem
            {...listItemProps}
            onClick={this.handleFilestackSourceClick}
            text={this.props.replace ? 'Upload image' : 'Upload images'}
            value="local_file_system"
            lucideIcon={ImageUp}
          />
        ) : null}

        {actions.clear && (
          <OverlayListItem
            {...listItemProps}
            onClick={(e) => {
              if (actions.clear?.onClick) {
                actions.clear.onClick(e);
              }
            }}
            text="Remove image"
            lucideIcon={SquareMinus}
          />
        )}

        {actions.upload && this.state.hasImages ? (
          <OverlayListItem
            {...listItemProps}
            onClick={this.props.goToPanelF('frameInStoryboard')}
            text="Copy from..."
            showArrow={true}
            lucideIcon={Images}
            disabled={!this.state.hasImages}
          />
        ) : null}

        {actions.upload ? (
          <OverlayListItem
            {...listItemProps}
            onClick={this.handleVideoImportClick}
            text={'Import from video'}
            icon={<FilmIcon />}
          />
        ) : null}

        <div className="my-2 border-b border-border" />

        {actions.upload && !BoordsConfig.CustomStorage ? (
          <>
            <OverlayListItem
              {...listItemProps}
              onClick={this.handleLimitedSourceClick}
              text="Dropbox"
              value="dropbox"
              icon={<DropboxIcon />}
            />
            <OverlayListItem
              {...listItemProps}
              onClick={this.handleLimitedSourceClick}
              text="Google Drive"
              value="googledrive"
              showBorder={false}
              center={false}
              showArrow={false}
              icon={<GoogleDriveIcon />}
            />
          </>
        ) : null}
      </div>
    );
  }
}
