/** @prettier */
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Button from 'blackbird/components/button/Button';
import Toast from 'blackbird/components/feedback/toast/Toast';
import { downloadEvent } from 'blackbird/helpers/eventContextHelper';
import {
  Download,
  FileBadge,
  FileCheck,
  FileCheck2,
  FileImage,
} from 'lucide-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { type PDFContext } from '../types';

interface Props {
  hasTooMuchText: boolean;
  downloadUrl: string;
  filename: string;
  onExpire: () => void;
  context: PDFContext;
}

export const PDFDone: React.FC<Props> = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'export' });
  const [downloadStarted, setDownloadStarted] = React.useState(false);
  const handleDownload = () => {
    if (props.context === 'pdf') {
      downloadEvent('pdf');
    } else {
      downloadEvent('google_slides');
    }
    setTimeout(() => {
      setDownloadStarted(true);
    }, 300);
  };

  const getText = (key: string) => t(`${props.context}.${key}`);

  return (
    <div className="flex flex-col w-full pb-4 gap-8">
      <div className="space-y-6">
        {props.hasTooMuchText ? (
          <>
            <div className="flex justify-center mb-6 -mt-8">
              <ExclamationTriangleIcon className="w-16 h-16 text-brand-yellow/50" />
            </div>
            <Toast hideIcon kind="warning" message={t('tooMuchtext')} />
          </>
        ) : (
          <div className="flex justify-center mb-2 -mt-8">
            {downloadStarted ? (
              <Download
                className="w-16 h-16 text-black opacity-20"
                strokeWidth={1.5}
              />
            ) : (
              <FileImage
                className="w-16 h-16 text-black opacity-20"
                strokeWidth={1.5}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col items-center gap-4">
        {downloadStarted ? (
          <Toast
            kind="success"
            size="full"
            hideIcon
            message={<div className="text-center">{t('downloadStarted')}</div>}
          />
        ) : (
          <Button
            onMouseDown={handleDownload}
            link={props.downloadUrl}
            target="_blank"
            download={props.filename}
            type="solid"
            // This needs a fixed width, otherwise it will overflow
            className="minW0 w-80"
          >
            <span className="truncate">{t('downloadCta')}</span>
          </Button>
        )}
        <a
          className="text-sm underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2"
          onClick={props.onExpire}
        >
          {getText('doneGoBack')}
        </a>
      </div>
    </div>
  );
};
