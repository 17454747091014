/** @prettier */
import makeItFit from 'javascripts/helpers/makeItFit';
import type { Base64FrameImagesResult, DocInfo } from '../types';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { omit } from 'underscore';
const errorHandler = RequestErrorHandler('pdfImage');

interface Props {
  imageInfo: Base64FrameImagesResult;
  width?: number;
  height?: number;
  x: number;
  y: number;
  maxWidth?: number;
  maxHeight?: number;
  compression?: string;
  originX?: 'left' | 'right';
  originY?: 'top' | 'bottom' | 'middle';

  border?: string;
}

export const createPDFImage = ({ doc }: DocInfo, props: Props) => {
  const { imageInfo } = props;
  let hasError = false;
  const targetWidth = props.width || props.maxWidth || 999999;
  const targetHeight = props.height || props.maxHeight || 9999;

  if (!imageInfo) {
    errorHandler({
      messageKey: null,
      rollbarMessage: 'No image url was passed, could an icon be missing?',
    })();
    doc.setDrawColor(props.border ?? '#E0E0E0');
    doc.rect(props.x, props.y, targetWidth, targetHeight, 'S');

    return {
      width: targetWidth,
      height: targetHeight,
      x: props.x,
      y: props.y,
    };
  }

  const dimensions = makeItFit(
    { width: imageInfo.width, height: imageInfo.height },
    {
      width: targetWidth,
      height: targetHeight,
    },
  );

  if (!['JPEG', 'PNG', 'WEBP', 'GIF'].includes(imageInfo.type)) {
    throw new Error('Unsupported image type: ' + imageInfo.type);
  }

  let x = props.x;
  let y = props.y;

  if (props.originX === 'right') x -= dimensions.width;
  if (props.originY === 'bottom') y -= dimensions.height;
  if (props.originY === 'middle') y -= dimensions.height / 2;

  const compression = props.compression || 'FAST';

  try {
    doc.addImage(
      imageInfo.image,
      imageInfo.type,
      x,
      y,
      dimensions.width,
      dimensions.height,
      imageInfo.originalUrl,
      compression,
    );
  } catch (error) {
    hasError = true;
    errorHandler({
      messageKey: null,
      severity: 'warn',
      rollbarMessage: 'Could not load image',
      extraData: omit(imageInfo, 'image'),
    })(error);
  }

  if (props.border || hasError) {
    doc.setDrawColor(props.border ?? '#E0E0E0');
    doc.rect(props.x, props.y, dimensions.width, dimensions.height, 'S');
  }

  return {
    width: dimensions.width,
    height: dimensions.height,
    x,
    y,
  };
};
