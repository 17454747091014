import * as React from 'react';
import { isEqual } from 'underscore';

/** Fires the `effect` parameter if the prop has changed, but unlike the default
 * `useEffect`, it will use `_.isEqual` for comparison. The effect function  is
 * cached for performance reasons, use the dependency array to override */
export function usePropChangedEffect<T = any>(
  prop: T, effect: (change: T) => void, deps: any[] = []
) {
  const propRef = React.useRef<any>(prop);
  const callback = React.useCallback(effect,deps)

  React.useEffect(() => {
    if (!isEqual(propRef.current, prop)) {
      callback(prop)
      propRef.current = prop
    }
  }, [prop, callback])
}
