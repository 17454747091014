/** @prettier */
import type { CanvasScrubberRenderer } from './CanvasScrubberRenderer';
import { clampNumber } from '../../../helpers/clampNumber';
import { pixelRound } from '../../../helpers/pixel-round';
import type {
  FabricGroup,
  FabricObject,
} from 'javascripts/components/frame_editor/types';
const width = 28;
const tailWidth = 2;

export type ScrubHandleGroup = FabricGroup & {
  text: FabricObject;
  updateHandleTime: (time: number) => void;
};

export const createScrubHandle = (props: {
  fabric: any;
  renderer: CanvasScrubberRenderer;
  color: string;
  currentFrameNumber: string | null;
  time: number;
  totalHeight: number;
  totalWidth: number;
  onTimeChange: (time: number) => void;
}): ScrubHandleGroup => {
  const dimensions = props.renderer.dimensions.frameNumberContainer;
  const lineDimensions = props.renderer.dimensions.handleLine;
  const { availableHeight, bottomSection } = props.renderer.dimensions;

  const text = new props.fabric.Text(props.currentFrameNumber ?? '', {
    width: width,
    left: width / 2,
    top: dimensions.height / 2,
    textAlign: 'center',
    originX: 'center',
    originY: 'center',
    fontFamily: 'matter',
    fill: 'white',
    fontSize: 14,
  });

  let time = props.time;

  // The frame number in its container
  const frameNumberContainer: FabricGroup = new props.fabric.Group(
    [
      new props.fabric.Rect({
        width: width,
        height: dimensions.height,
        left: 0,
        fill: props.color,
        rx: 4,
        ry: 4,
        strokeWidth: 0,
      }),
      text,
    ],
    {
      visible: dimensions.height > 1,
    },
  );

  const triangle: FabricObject = new props.fabric.Path(
    'M12 0H0V6L4 11H8L12 6V0Z',
    {
      originY: 'top',
      top: lineDimensions.top,
    },
  );

  triangle.scaleToWidth(lineDimensions.triangleWidth);
  triangle.set({
    left: width / 2 - lineDimensions.triangleWidth / 2,
    top: lineDimensions.top,
    fill: props.color,
    strokeWidth: 0,
  });

  const handle: ScrubHandleGroup = new props.fabric.Group(
    [
      // The line that extends from underneath the triangle
      new props.fabric.Rect({
        width: tailWidth,
        top: lineDimensions.lineTop,
        height: lineDimensions.height,
        left: width / 2 - tailWidth / 2,
        fill: props.color,
        // rx: 3,
        // ry: 3,
        strokeWidth: 0,
        originY: 'top',
      }),
      triangle,
      frameNumberContainer,
    ],
    {
      originX: 'center',
      originY: 'top',
      hasControls: false,
      hasBorders: false,
      hoverCursor: 'grab',
      moveCursor: 'grabbing',
      lockMovementY: true,
      left: pixelRound(props.renderer.timeToPosition(time) || 0),
      text,
    },
  );

  const mainLeft = frameNumberContainer.left;
  const updateHandleTime = (newTime: number) => {
    const newLeft = props.renderer.timeToPosition(newTime) || 0;
    const clampedLeft = clampNumber(
      newLeft,
      width / 2,
      props.totalWidth - width / 2,
    );
    handle.set('left', pixelRound(newLeft));
    const diff = clampedLeft - newLeft;
    frameNumberContainer.set('left', pixelRound(mainLeft + diff));
  };

  handle.on('moving', ({ transform }) => {
    time = Math.max(
      0,
      Math.min(
        props.renderer.endTime,
        props.renderer.positionToTime(transform.target.left) || 0,
      ),
    );
    updateHandleTime(time);
    props.onTimeChange(time);
  });

  handle.updateHandleTime = (time) => {
    updateHandleTime(time);
    handle.setCoords();
  };

  updateHandleTime(time);

  return handle;
};
