/** @prettier */

import { logInDevelopment } from './logger';

/**
 * Rounds a number to values renderable on the current device pixel ratio
 * i.e. `0.5` on `1px` displays
 */
export const pixelRound = (num) => {
  if (isNaN(num)) {
    logInDevelopment('cannot round a NaN, returning 0');
    return 0;
  }
  return Math.round(num * window.devicePixelRatio) / window.devicePixelRatio;
};
