/** @format */

import React from 'react';
import {
  Settings,
  Archive,
  UserPlus,
  type LucideIcon,
  LayoutTemplate,
} from 'lucide-react';
import { ProjectsContext, type ProjectGroup } from '../ProjectsContext';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import ArrowDownIcon from '../../../images/icons/arrow.svg';
import Panel from '../../panel/Panel';
import { ProjectsGroupLabel } from '../ProjectsGroupLabel';
import { TeamPlanBadge } from 'blackbird/components/team/TeamPlanBadge';
import { TeamTrialBlock } from 'blackbird/components/team/TeamTrialBlock';
import { TeamUpgradePill } from '../../team/TeamUpgradePill';
import { ProjectDropZone } from '../modals/ProjectDropZone';
import { DRAGGABLE_TYPES } from 'javascripts/components/shared/dragAndDropUtils';

const accept = [DRAGGABLE_TYPES.project];

interface Option {
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
}

interface ProjectsTeamDropdownProps {
  group: ProjectGroup;
}

export const ProjectsTeamDropdown: React.FC<ProjectsTeamDropdownProps> = ({
  group,
}) => {
  const { setViewType } = React.useContext(ProjectsContext);

  const DropdownItem: React.FC<{ Icon: LucideIcon; text: string }> = ({
    Icon,
    text,
  }) => (
    <div className="flex items-center space-x-3">
      <Icon className="flex-shrink-0 w-4 h-4" />
      <div className="text-sm pb-0.5">{text}</div>
    </div>
  );

  const isAdmin = group.actions.includes('team.billing');
  const isWorkflow = group.plan_name === 'Workflow';
  const isBusiness = group.plan_name === 'Business';
  const isTrialing = group.plan_name === 'Trialing';

  const regularOptions: Option[] = [
    {
      label: <DropdownItem Icon={UserPlus} text={`Invite members`} />,
      value: `/settings/team/${isAdmin ? '' : `${group.hashid}/`}manage`,
    },
    {
      label: <DropdownItem Icon={Settings} text={`Team settings`} />,
      value: `/settings/team/${isAdmin ? '' : `${group.hashid}/`}general`,
    },
  ];

  const archiveOption: Option = {
    label: <DropdownItem Icon={Archive} text={`Archive`} />,
    value: 'archive',
  };

  const templatesOption: Option = {
    label: <DropdownItem Icon={LayoutTemplate} text={`Templates`} />,
    value: 'templates',
    disabled: !isWorkflow && !isTrialing && !isBusiness,
  };

  const handleChange = (option: Option) => {
    if (option.disabled) return;

    if (option.value === 'archive') {
      setViewType('archive');
    } else if (option.value === 'templates') {
      window.history.pushState({}, '', `/templates/${group.hashid}`);
      setViewType('templates');
    } else {
      window.location.href = option.value;
    }
  };

  if (!group.actions.includes('team.manage')) {
    return <ProjectsGroupLabel group={group} />;
  }

  const showBottomSection =
    group.actions.includes('storyboards.create') ||
    group.actions.includes('team.manage');

  return (
    <ProjectDropZone
      className="p-2 -m-2"
      accept={accept}
      projectId={null}
      border={false}
    >
      <Listbox
        as="div"
        className="relative group/teamdropdown"
        onChange={handleChange}
        value={null}
      >
        {({ open }) => (
          <>
            <Listbox.Button className="w-full outline-none ring-none">
              <ProjectsGroupLabel group={group} open={open} />
            </Listbox.Button>

            <Listbox.Options
              as={Panel}
              className="absolute flex-col w-56 mt-2 left-8 top-full focus:outline-none"
            >
              <div className="p-2">
                <div className="px-2 pt-1 pb-3 mb-2 border-b border-border">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <TeamPlanBadge planName={group.plan_name} />
                    </div>
                    <div className="flex-auto ml-1.5 text-type-subdued text-xs pt-0.5">
                      {group.quantity === 1 ? (
                        <>{`1 member`}</>
                      ) : (
                        <>{`${group.quantity} members`}</>
                      )}
                    </div>
                  </div>
                </div>
                {regularOptions.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    value={option}
                    as="div"
                    className={({ active }) =>
                      classNames(
                        'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded',
                        active && !option.disabled && 'bg-surface-light',
                        option.disabled && 'opacity-50 cursor-not-allowed',
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={classNames('block')}>
                          {option.label}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {showBottomSection && (
                  <>
                    <div className="my-2 border-t border-border"></div>
                    {group.actions.includes('team.manage') &&
                      BoordsConfig.HasTemplates && (
                        <Listbox.Option
                          key={templatesOption.value}
                          value={templatesOption}
                          disabled={templatesOption.disabled}
                          as="div"
                          className={({ active, disabled }) =>
                            classNames(
                              'select-none relative py-2 pl-3 pr-9 rounded',
                              active && !disabled && 'bg-surface-light',
                              !disabled ? 'cursor-pointer' : '',
                            )
                          }
                        >
                          {({ selected, disabled }) => (
                            <>
                              <div className="block">
                                <span
                                  className={classNames(
                                    disabled && 'opacity-50 cursor-not-allowed',
                                  )}
                                >
                                  {templatesOption.label}
                                </span>
                                {disabled && (
                                  <div className="whitespace-nowrap">
                                    <TeamUpgradePill />
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      )}

                    {group.actions.includes('storyboards.create') && (
                      <Listbox.Option
                        key={archiveOption.value}
                        value={archiveOption}
                        as="div"
                        className={({ active }) =>
                          classNames(
                            'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded',
                            active && 'bg-surface-light',
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            <span className={classNames('block')}>
                              {archiveOption.label}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    )}
                  </>
                )}
              </div>
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </ProjectDropZone>
  );
};
