/** @format */
import React, { useContext, useState } from 'react';
import ArrowIcon from 'blackbird/images/icons/arrow.svg';
import type { IFrame } from 'javascripts/types/frame';
import Icon from 'blackbird/components/icon/Icon';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { FlyoverContext } from '../flyover/flyoverContext';
import { MobileContext } from '../shareable/MobileContext';
import { SimplifiedHTML } from 'blackbird/components/form/richTextInput/SimplifiedHTML';
import { isUndefined } from 'underscore';
import DisclosureIcon from 'blackbird/images/icons/disclosure-left.svg';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import logger from 'javascripts/helpers/logger';

export const FrameSelect = React.memo<{
  label: string;
  frames: IFrame[];
  value?: number;
  onChange: (value: string | number | undefined) => void;
}>(({ label, value, onChange, frames }) => {
  const flyoverRef = useContext(FlyoverContext);
  const isMobileScreen = useContext(MobileContext);

  const [parentRef, setParentRef] = useState();
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = useCustomPopper(parentRef, popperElement, {
    placement: isMobileScreen ? 'bottom-end' : 'bottom-start',
    distance: 10,
    maxHeight: isMobileScreen ? 300 : 500,
  });

  return (
    <div>
      <Listbox value={value} onChange={onChange}>
        <Listbox.Button ref={(ref) => setParentRef(ref)} className={''}>
          {({ open }) => (
            <Tooltip title={`Jump to Frame`} placement="bottom" disabled={open}>
              <div className="flex items-center flex-shrink-0 text-sm rounded-sm group gap-0">
                <span className="text-base font-semibold">{label}</span>
                <span
                  className={classNames(
                    'group-hover:opacity-100',
                    open ? 'opacity-100' : 'opacity-30',
                  )}
                >
                  <Icon
                    icon={<DisclosureIcon />}
                    className={classNames(
                      'w-4 ml-1.5 ',
                      open ? '-mt-0.5 rotate-90' : '-rotate-90',
                    )}
                  />
                </span>
              </div>
            </Tooltip>
          )}
        </Listbox.Button>
        {ReactDOM.createPortal(
          <Listbox.Options
            className="w-40 p-2 overflow-y-auto bg-white rounded-lg shadow-lg z-flyover focus:outline-none space-y-2"
            ref={(ref) => setPopperElement(ref)}
            style={styles.popper}
            {...attributes.popper}
          >
            {frames.map((frame, i) => (
              <Listbox.Option
                className="cursor-pointer"
                key={frame.id}
                value={i}
              >
                {({ active }) => (
                  <div
                    className={classNames(
                      'text-sm flex flex-col py-1 px-2 rounded-l',
                      {
                        'bg-surface-light': active,
                      },
                    )}
                  >
                    <span>
                      Frame {!isUndefined(frame.number) ? frame.number : i + 1}
                    </span>
                    {frame.reference && (
                      <SimplifiedHTML
                        className="w-full text-sm truncate text-type-disabled"
                        text={frame.reference}
                      />
                    )}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>,
          flyoverRef?.current ?? document.querySelector('body')!,
        )}
      </Listbox>
    </div>
  );
});
