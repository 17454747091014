/** @prettier */
import * as React from 'react';
import * as Autosuggest from 'react-autosuggest';
import { includes } from 'underscore';
import * as fontLoader from '../../helpers/font-loader';

const theme = {
  container: 'w-full',
  // containerOpen: '',
  input: 'px-2 py-1.5 rounded-md rings-form w-full',
  inputOpen: 'br--top',
  // inputFocused: '',
  suggestionsContainer: 'relative ',
  // suggestionsContainerOpen: '',
  suggestionsList:
    'absolute top-0 left-0 right-0 z-10 border border-form rounded-sm bg-white border-t-0 overflow-y-auto max-h-32',
  suggestion: 'py-1.5 px-1.5 rounded-sm flex space-x-6 items-center',
  // suggestionFirst: '',
  suggestionHighlighted: 'bg-surface-light',
  // sectionContainer: '',
  // sectionContainerFirst: '',
  // sectionTitle: '',
};

const fontWeight = 400;
const style = { fontFamily: "'Source Code Pro', monospace", fontWeight };

const noop = () => {};
const renderSuggestion = (suggestion) => <>{suggestion}</>;
const defaults = [8, 10, 12, 14, 17, 21, 25, 30];

interface Props
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'placeholder' | 'value' | 'step' | 'min' | 'max'
  > {
  name: string;
  onChange: (args: { name: string; value: number }) => void;
  suggestions?: number[];
}

export const NumberPicker: React.FC<Props> = ({ ...props }) => {
  const [suggestions, setSuggestions] = React.useState<string[]>([]);
  const handlechange = (e, { newValue, method }) => {
    if (includes(['click', 'type', 'enter'], method)) {
      props.onChange({ name: props.name, value: parseInt(newValue, 10) });
    }
  };

  React.useEffect(() => {
    setSuggestions((props.suggestions || defaults).map(String));
  }, [props.suggestions]);

  React.useEffect(() => {
    fontLoader.load('Source Code Pro', fontWeight);
  }, []);

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    type: 'number',
    placeholder: props.placeholder,
    value: String(props.value || ''),
    onChange: handlechange,
    step: props.step,
    min: props.min ?? 0,
    max: props.max,
    style: style,
  };

  return (
    <Autosuggest
      theme={theme}
      suggestions={suggestions}
      onSuggestionsFetchRequested={noop}
      onSuggestionsClearRequested={noop}
      getSuggestionValue={(e) => e}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={() => true}
      inputProps={inputProps}
      focusInputOnSuggestionClick={false}
    />
  );
};

NumberPicker.displayName = 'NumberPicker';
