/** @format */
import * as React from 'react';
import type { DetailedFrame } from 'javascripts/types/frame';
import type { IShareableStoryboard } from 'javascripts/types/storyboard';
import { MobileContext } from './MobileContext';
import { FramePanelBarNavigator } from '../shared/FramePanelBarNavigator';
import { PanelbarDivider } from '../shared/PanelbarDivider';
import { FramePanelBar } from '../shared/FramePanelBar';
import { PlayerActions } from 'javascripts/flux/actions/player';
import { useStore } from 'javascripts/helpers/useStore';
import type { PlayerAudio, PlayerStore } from 'javascripts/flux/stores/player';
import { isNull, isNumber, noop } from 'underscore';
import { SmartImg } from '../shared/Img';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { useFrameImageSources } from 'javascripts/helpers/useFrameImageSources';
import PauseIcon from 'blackbird/images/icons/pause.svg';
import PlayIcon from 'blackbird/images/icons/play.svg';
import { ConnectedCanvasScrubber } from '../player/CanvasScrubber/CanvasScrubber';
import { IconButton } from 'blackbird/components/common/IconButton';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';

interface Props {
  storyboard: IShareableStoryboard;
  onSetIndex: any;
  frames: DetailedFrame[];
  activeIndex: number;
  canManage: boolean;
  hasBanner: boolean;
  isPlayer: boolean;
}

export const ShareableMobileLayout = React.memo<Props>((props) => {
  const playerIndex = useStore<number | null, PlayerStore>(
    'player',
    (f) => f.currentFrameIndex,
  );
  const activeIndex =
    props.isPlayer && !isNull(playerIndex) ? playerIndex : props.activeIndex;

  const isPlaying = useStore<boolean, PlayerStore>(
    'player',
    (f) => f.isPlaying,
  );
  const isReady = useStore<boolean, PlayerStore>(
    'player',
    (f) => !f.isWaitingOnAudio,
  );
  const audio = useStore<PlayerAudio | undefined, PlayerStore>(
    'player',
    (f) => f.audio,
  );

  const currentFrame = props.frames[activeIndex];
  const aspectRatio = props.storyboard.frame_aspect_ratio;
  const { width, height } = BoordsFrameSizeHelper(aspectRatio);

  React.useEffect(() => {
    if (props.isPlayer) {
      PlayerActions.open.defer({
        frame: props.frames[props.activeIndex],
        isEditable: false,
        storyboard: props.storyboard,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSetIndex = React.useCallback(
    (newIndex) => {
      props.onSetIndex(newIndex);
      if (props.isPlayer) PlayerActions.goToFrame(props.frames[newIndex]);
    },
    [props.onSetIndex, props.frames, props.isPlayer],
  );

  const sources = useFrameImageSources(currentFrame, aspectRatio);

  return (
    <MobileContext.Provider value={true}>
      <div className="h-dvh flex flex-col">
        <div
          className={`sticky z-header left-0 right-0 bg-white border-b border-b-border `}
          // style={{ top: props.hasBanner ? '3em' : 0 }}
        >
          <div className=" bg-surface border-b border-b-border h-[60px] px-3 flex items-center">
            <div className="text-lg text-bold mr-3 truncate">
              {props.storyboard.document_name}
            </div>

            {props.storyboard.include_version_number &&
              props.storyboard.version_count > 1 && (
                <div className="text-type-subdued mr-3 nowrap">
                  {props.storyboard.version_name.replace('Version ', 'v')} of{' '}
                  {props.storyboard.version_count}
                </div>
              )}
          </div>
          <SmartImg
            className="relative w-full"
            sources={sources}
            width={width}
            height={height}
            loading={'eager'}
            placeholder={getMissingFrameImageURL(
              props.storyboard.frame_aspect_ratio,
            )}
          />

          {/* {props.isPlayer && (
            <div className="p-3 md:p-4 flex gap-2 justify-center">
              <IconButton
                iconClassName="w-auto h-10"
                icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
                aria-label={isPlaying ? 'Pause' : 'Play'}
                disabled={!isReady}
                onClick={() => PlayerActions.togglePlay()}
                hasTooltip={false}
              />
              <ConnectedCanvasScrubber
                canvasClassName="rounded-md overflow-hidden"
                currentFrameId={currentFrame.id}
                currentFrameNumber={null}
                hideFirstFrameIndicator={true}
                onFrameIndicatorMoveCommit={noop}
                height={40}
                minimal
              />
            </div>
          )} */}
        </div>

        <div className="p-3 md:p-4">
          <FramePanelBarNavigator
            {...props}
            onSetIndex={handleOnSetIndex}
            showFrameStatus={
              props.storyboard.preferences.share_with_frame_status
            }
            activeIndex={activeIndex}
            middleChildren={
              props.isPlayer && (
                <IconButton
                  iconClassName="w-auto h-8"
                  icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
                  aria-label={isPlaying ? 'Pause' : 'Play'}
                  disabled={!isReady}
                  onClick={() => PlayerActions.togglePlay()}
                  hasTooltip={false}
                />
              )
            }
          />
        </div>
        <PanelbarDivider margin="mt-0 mb-8" />
        <FramePanelBar
          {...(props as any)}
          activeIndex={activeIndex}
          onSetIndex={handleOnSetIndex}
        />
      </div>
    </MobileContext.Provider>
  );
});

ShareableMobileLayout.displayName = 'ShareableMobileLayout';
