/** @prettier */
import { eachFrameFieldMap } from '../../../helpers/eachFrameFieldMap';
import { SVGIcon } from '../../shared/SVGIcon';
import { generateFrameFieldLabel } from '../../../helpers/generateFrameFieldLabel';
import type { IFrame } from '../../../types/frame';
import type { FrameField, IStoryboard } from '../../../types/storyboard';
import { every, isNull, isUndefined } from 'underscore';
import classNames from 'classnames';
import React from 'react';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import { stylesForIndexLength } from 'javascripts/helpers/styleForIndexLength';
import RichTextInput, {
  type RichTextInputChangeEventHandler,
} from 'blackbird/components/form/richTextInput/RichTextInput';
import { isFrameFieldEmpty } from 'javascripts/helpers/storyboard/frameFieldHelpers';
import { TagIcon } from '@heroicons/react/20/solid';
import logger from 'javascripts/helpers/logger';
import { type GridSize } from 'javascripts/components/frames/FrameGrid';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

interface Props {
  frame: IFrame;
  index: number;
  noBackground?: boolean;
  isImageLibraryLayout?: boolean;
  gridSize?: GridSize;
  storyboard: IStoryboard;
  frame_fields: FrameField[];
  // If true, show the number of the frame if no label is present
  hideIndex?: boolean;
  labelsHaveBackground: boolean;
  onUpdateFrameField?: RichTextInputChangeEventHandler;
}

/** This component is shared between the shareable/present layout and the player
 * sidebar */
const ShareableItemInfo: React.FC<Props> = ({ frame, ...props }) => {
  const {
    include_label_text_in_output,
    include_frame_number_in_pdf,
    include_icons_in_pdf,
  } = props.storyboard;

  const index = frame.number || props.index;
  const noContent = React.useMemo(
    () =>
      every(
        eachFrameFieldMap(props.frame_fields, (f, fieldId) =>
          getFrameField(frame, fieldId),
        ),
        (i) => i === null || isFrameFieldEmpty(i),
      ),
    [frame, props.frame_fields],
  );

  if (isUndefined(frame) || isNull(frame)) {
    return null;
  }

  const indexLength = String(index).length;
  const styles = stylesForIndexLength[indexLength].w;
  const canEdit = !!props.onUpdateFrameField;

  const hasReference = !isFrameFieldEmpty(getFrameField(frame, 'reference'));
  const hasFrameNumber = include_frame_number_in_pdf && !props.hideIndex;
  const hasReferenceIcon =
    hasReference && (include_label_text_in_output || canEdit);

  const showReferenceField =
    (hasReference && canEdit) ||
    (hasReference && include_label_text_in_output) ||
    hasFrameNumber;

  const showBottomBorder =
    (showReferenceField && props.hideIndex) ||
    (!noContent && props.frame_fields.length && props.hideIndex);

  const textSize = !props.gridSize
    ? 'text-sm'
    : {
        xs: 'text-xs',
        sm: 'text-sm',
        md: 'text-sm',
        lg: 'text-sm',
        xl: 'text-base',
      }[props.gridSize];

  return (
    <div
      className={classNames(
        'relative',
        !props.gridSize ? 'px-6' : 'bg-white rounded-md flex-grow',
        props.gridSize === 'xs' && 'rounded-md px-1.5',
        props.gridSize === 'sm' && 'rounded-md px-2',
        props.gridSize === 'md' && 'rounded-md px-2',
        props.gridSize === 'lg' && 'rounded-lg px-3',
        props.gridSize === 'xl' && 'rounded-xl px-3',
        props.isImageLibraryLayout && !noContent && 'pt-4 pb-4',
        showBottomBorder && 'border-b border-border',
        showBottomBorder && !props.isImageLibraryLayout && 'pb-4 -mt-5',
      )}
    >
      {/* props.hideIndex ? 'px-6' : 'px-3' */}
      <div
        className={classNames({
          '-ml-1.5': !noContent && props.hideIndex && include_icons_in_pdf,
        })}
      >
        {showReferenceField ? (
          <div
            className={classNames(
              'flex items-center',
              textSize,
              {
                'border-b border-b-border/50 last:border-none':
                  !noContent && !props.hideIndex,
                // 'py-4': !props.hideIndex,
                'py-2': !noContent && props.hideIndex,
              },
              !noContent &&
                !props.hideIndex &&
                props.gridSize === 'xs' &&
                'gap-1 py-2',
              !noContent &&
                !props.hideIndex &&
                props.gridSize === 'sm' &&
                'gap-1.5 py-2',
              !noContent &&
                !props.hideIndex &&
                props.gridSize === 'md' &&
                'gap-2 py-2.5',
              !noContent &&
                !props.hideIndex &&
                props.gridSize === 'lg' &&
                'gap-2 py-3',
              !noContent &&
                !props.hideIndex &&
                props.gridSize === 'xl' &&
                'gap-2 py-4',
              !props.gridSize && 'py-2 gap-1.5 ',
            )}
          >
            {hasFrameNumber ? (
              <Tooltip title={`Frame ${index}`} placement={`left`}>
                <div
                  className={classNames(
                    'bg-surface truncate text-center shrink-0 cursor-help pt-0.5 pb-1',
                    textSize,
                    props.gridSize === 'xs' &&
                      'rounded-sm text-xxs mr-1 mt-[0.1rem]',
                    props.gridSize === 'sm' && 'rounded-md text-xs mt-[0.2rem]',
                    props.gridSize === 'md' && 'rounded-md text-xs mt-[0.2rem]',
                    props.gridSize === 'lg' && 'rounded-md text-sm mt-[0.2rem]',
                    props.gridSize === 'xl' && 'rounded-md text-sm mt-[0.2rem]',
                  )}
                  style={styles}
                >
                  {index}
                </div>
              </Tooltip>
            ) : (
              hasReferenceIcon && (
                <TagIcon
                  className="w-[1.15rem] mt-[0.32rem] ml-[0.3rem] mr-[0.1rem] pr-[0.1rem] text-type-primary"
                  title={`Label`}
                />
              )
            )}

            {(canEdit || include_label_text_in_output) &&
            (hasReference || !props.hideIndex) ? (
              <div className={classNames(`w-full leading-6`, textSize)}>
                <RichTextInput
                  value={getFrameField(frame, 'reference')}
                  name="reference"
                  placeholder={canEdit ? `Label` : undefined}
                  onChange={props.onUpdateFrameField!}
                  disabled={!canEdit}
                  className={classNames(
                    'prose',
                    canEdit
                      ? 'focus-within:ring ring-offset-4 rounded-sm focus-within:text-type-primary text-type-primary cursor-pointer'
                      : 'text-type-subdued',
                  )}
                  immediate={false}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {eachFrameFieldMap(
          props.frame_fields,
          (fieldInfo, fieldId, i, label) => {
            const value = getFrameField(frame, fieldId);

            // We've already shown the frame label, so we can skip the first field.
            // We also do not need to show empty fields here. `getFrameField` will
            // default to '' if a field cannot be found
            if (fieldId === 'reference' || isFrameFieldEmpty(value))
              return null;

            // hideIndex is true when this is shown on shareable as is
            // non-editable
            return (
              <div
                className={classNames(
                  'flex items-start',
                  textSize,
                  props.gridSize === 'xs' && 'gap-1 py-2',
                  props.gridSize === 'sm' && 'gap-1.5 py-2',
                  props.gridSize === 'md' && 'gap-2 py-2.5',
                  props.gridSize === 'lg' && 'gap-2 py-3',
                  props.gridSize === 'xl' && 'gap-2 py-4',
                  !props.gridSize && 'py-2 gap-1.5 ',
                  props.hideIndex
                    ? ''
                    : 'border-b border-b-border last:border-none',
                )}
                key={fieldId}
              >
                {include_icons_in_pdf ? (
                  <Tooltip title={fieldInfo.label} placement={`left`}>
                    <div
                      className={`mid-grey shrink-0 mt2 cursor-help ${
                        props.labelsHaveBackground
                          ? 'flex items-center justify-center h20px bg-barely-grey br2 tc mr2'
                          : 'pt1'
                      }`}
                      style={
                        // When we don't have a frame number, we don't have to apply
                        // a width in order to align with something
                        props.labelsHaveBackground && hasFrameNumber
                          ? styles
                          : undefined
                      }
                    >
                      {fieldInfo.icon ? (
                        <SVGIcon
                          className="w-auto h-6"
                          icon={fieldInfo.icon as any}
                        />
                      ) : (
                        <span className="f7 silver avenir-heavy">
                          {generateFrameFieldLabel(label)}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                ) : null}
                <div className={classNames(`flex-auto`, textSize)}>
                  <RichTextInput
                    key={index + fieldId}
                    value={value}
                    name={fieldId}
                    placeholder={label}
                    onChange={props.onUpdateFrameField!}
                    disabled={!canEdit}
                    className={classNames(
                      'prose',
                      canEdit
                        ? 'focus-within:ring text-type-primary ring-offset-4 rounded-sm focus-within:text-type-primary cursor-pointer'
                        : 'text-type-subdued',
                    )}
                    immediate={false}
                  />
                </div>
              </div>
            );
          },
        )}
      </div>

      {/* {showBottomBorder && ( */}
      {/*   <div */}
      {/*     className={classNames( */}
      {/*       'relative pb-4', */}
      {/*       !props.isImageLibraryLayout && 'mb-6', */}
      {/*     )} */}
      {/*   > */}
      {/*     <div className="absolute bottom-0 left-0 right-0 h-[0.1rem] border-b border-b-border" /> */}
      {/*   </div> */}
      {/* )} */}
    </div>
  );
};

const memo = React.memo<Props>(ShareableItemInfo);
export { memo as ShareableItemInfo };
