/** @format */

import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef } from 'react';
import Button from '../button/Button';
import * as navigateToRoute from 'javascripts/helpers/router/navigate-to-route.js';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';
import { AiHelpDocLink } from './AiPrompt';
import { ModalDivider } from '../dialog/Dialog';
import cdnUrl from 'javascripts/helpers/cdn-url.js';
import Toast from '../feedback/toast/Toast';
import { SecondaryLink } from '../common/SecondaryLink';

interface AiWorkingProps {
  id: number;
  onCancel?: () => void;
}

export const AiWorking: React.FC<AiWorkingProps> = ({ id, onCancel }) => {
  const { t } = useTranslation();
  const [aiScriptGenerating, setAiScriptGenerating] = useState(true);
  const [error, setError] = useState('');
  const [isTimerActive, setIsTimerActive] = useState(false);
  const timeoutRef = useRef<any>();

  const TIMEOUT_LENGTH = 25000;

  const loadingStatuses: string[] = t('ai.loadingStatuses', {
    returnObjects: true,
  });

  const [loadingStatus, setLoadingStatus] = useState<string>(
    loadingStatuses[0] as string,
  );
  const [loadingStatusIndex, setLoadingStatusIndex] = useState<number>(0);

  useEffect(() => {
    // Start the progress animation
    const showTimeout = setTimeout(() => {
      setIsTimerActive(true);
    }, 10);

    return () => {
      clearTimeout(showTimeout);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const checkAiScriptGenerating = async () => {
      const response = await fetch(`/api/storyboards/${id}`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'X-API-KEY': BoordsConfig.AuthenticationToken,
        },
      });

      if (response.ok) {
        const res = await response.json();

        const { slug, ai_script_generating, ai_script_error } =
          res.data[0].attributes;

        if (!ai_script_generating) {
          setAiScriptGenerating(false);
          setIsTimerActive(false);
          if (ai_script_error.length < 1) {
            setLoadingStatus(t('ai.redirecting'));
            navigateToRoute('storyboard.show', { slug: slug }, true);
            window.location.reload();
          } else {
            setError(ai_script_error);
          }
        }
      } else {
        setError(t('ai.error.title'));
        setAiScriptGenerating(false);
        setIsTimerActive(false);
      }
    };

    if (aiScriptGenerating) {
      const interval = setInterval(() => {
        checkAiScriptGenerating();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [aiScriptGenerating, id]);

  useEffect(() => {
    if (loadingStatuses) {
      const interval = setInterval(() => {
        if (loadingStatusIndex < loadingStatuses.length - 1) {
          setLoadingStatusIndex(loadingStatusIndex + 1);
        }
        setLoadingStatus(loadingStatuses[loadingStatusIndex]);
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [loadingStatusIndex, loadingStatuses]);

  const Cancel = async () => {
    if (onCancel) {
      onCancel();
    }
    setIsTimerActive(false);
    await fetch(`/api/storyboards/${id}/ai_scripts/cancel`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
    });
  };

  return (
    <div className="flex flex-col pb-2 space-y-8">
      {error ? (
        <div className="">
          <div className="-mt-2 text-lg font-semibold">{`Generation Error`}</div>
          <div className="mt-5 mb-6">
            <Toast
              kind={'error'}
              size="full"
              title={error}
              message={<AiHelpDocLink cta={t('ai.error.helpDocCta')} />}
              hideIcon
            />
          </div>

          <Button
            type="secondary"
            size="md"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Cancel();
            }}
          >
            {t('ai.back')}
          </Button>
        </div>
      ) : (
        <>
          <div>
            <div className="mb-5 -mt-2 text-lg font-semibold">{`Generating Script...`}</div>

            <div className="relative flex items-center px-4 py-3 overflow-hidden rounded-lg bg-brand-blue-25 text-brand-blue ">
              {/* Progress bar */}
              <div
                className="absolute top-0 left-0 w-0 h-1 bg-brand-blue"
                style={{
                  width: isTimerActive ? undefined : '0%',
                  animation: isTimerActive
                    ? `growWidth ${TIMEOUT_LENGTH}ms linear forwards`
                    : 'none',
                }}
              />
              <div className="opacity-70">
                <LoadingIndicator containerClassName="scale-75" />
              </div>
              <div className="flex-auto ml-3 text-sm text-brand-blue ">
                {loadingStatus}
              </div>
              <div className="opacity-50 hover:opacity-100">
                <SecondaryLink
                  textColor={`text-brand-blue`}
                  decorationColor={`decoration-brand-blue/30`}
                  onClick={(e) => {
                    if (confirm('Cancel generation?')) {
                      e.preventDefault();
                      e.stopPropagation();
                      Cancel();
                    }
                  }}
                >
                  {t('ai.cancel')}
                </SecondaryLink>
              </div>
            </div>
          </div>
          <div>
            <ModalDivider />

            <div className="mt-4 text-base font-semibold">{`Up Next: Add Images to Your Storyboard`}</div>
            <div className="mt-2 mb-4 text-sm text-type-subdued">
              {`Your script will appear in storyboard frames automatically. Click the 'Quick generate' button to create new images for each scene.`}
            </div>

            <img
              className="rounded-xl"
              src={cdnUrl('/assets/tooltip-previews/quick-generate.jpg')}
            />
          </div>
        </>
      )}
    </div>
  );
};
