/** @format */
import type { ShareableStore } from 'javascripts/flux/stores/shareable';
import type { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import { last, some } from 'underscore';
import { useStore } from './useStore';

type StoreType = 'shareable' | 'storyboard';
type CombinedStore = ShareableStore | StoryboardStore;

const getUrlPrefix = (storeType: StoreType): string => {
  return storeType === 'shareable' ? '/s/' : '/storyboards/';
};

const getSlugTransform =
  (storeType: StoreType) =>
  (slug: string): string => {
    return storeType === 'shareable' ? slug.split('-')[0] : slug;
  };

export const useIsOutdatedVersion = (storeType: StoreType) => {
  return useStore<
    [boolean, string | undefined, boolean, boolean],
    CombinedStore
  >(storeType, ({ storyboard }) => {
    if (!storyboard) {
      return [false, undefined, true, true];
    }

    const hasNewerVersion =
      storyboard &&
      some(
        storyboard.versions,
        (i) => i.version_number > storyboard.version_number,
      );

    const latestVersion = last(storyboard.versions);
    const urlPrefix = getUrlPrefix(storeType);
    const transformSlug = getSlugTransform(storeType);

    return [
      hasNewerVersion,
      hasNewerVersion && latestVersion
        ? `${urlPrefix}${transformSlug(latestVersion.slug)}`
        : undefined,
      storyboard.preferences?.allow_comments_in_old_versions ?? false,
      storyboard.preferences?.share_with_version_notification ?? false,
    ];
  });
};
