/** @prettier */
import React from 'react';
import PropTypes, { Validator } from 'prop-types';
import type {
  IStoryboardInStore,
  AspectRatioName,
  frameAspectRatio,
} from '../../../types/storyboard';
import {
  supportedLayouts,
  supportedFormats,
  FontSettings,
  PDFContext,
} from '../../pdf/types';
import {
  availableLayouts,
  fallbackIfLayoutNotSupported,
} from '../helpers/availableLayouts';
import { FontPicker } from '../../shared/FontPicker';
import { aspectRatioNameToAspectRatio } from '../../../helpers/frame-size-helper';
import * as env from '../../../helpers/env';
import PDFDevelopmentSettings from './PDFDevelopmentSettings';
import { getColorSchemeGreyValues } from '../helpers/getContrastyGrayValues';
import { getDefaultLayoutMetrics } from '../PDFLayoutMetrics';
import { NumberPicker } from '../../shared/NumberPicker';
import { CharsetInfo, charsetName } from '../../../helpers/detectCharset';
import { PDFCharsetOverride } from './PDFCharsetOverride';
import { PDFArabicMessage } from './PDFArabicMessage';
import { ColorPickerForCover } from '../../shared/ColorPickerForCover';
import { StoryboardActions } from '../../../flux/actions/storyboard';
import { isNumber, isNaN, isNull, isUndefined } from 'underscore';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SVGIconType } from 'javascripts/components/shared/SVGIcon';
import IconDropdown, {
  type IconDropdownOptions,
} from 'javascripts/components/shared/IconDropdown';

const maxWidth = { width: 100 };

/** Converts the newer frame ratios to a similar looking one for the purpose of
 * getting an icon  */
const getSimilarRatio = (ratio: frameAspectRatio) => {
  switch (ratio) {
    case '1:1':
    case '4:3': {
      return '1x1';
    }
    case '9x16':
    case '4:5': {
      return '9x16';
    }
    case '16:9':
    case '1.85:1':
    case '2.4:1': {
      return '16x9';
    }
    default: {
      return ratio;
    }
  }
};
interface Props extends WithTranslation {
  storyboard: IStoryboardInStore;
  cover: any;
  layout: supportedLayouts;
  orientation: AspectRatioName;
  documentFormat: supportedFormats;
  isPaperPortrait: boolean;
  detectedCharset?: CharsetInfo;
  charsetOverride?: charsetName | null;
  currentFont?: FontSettings | null;
  fontSubsets: string[];
  onFontChange: (e: FontSettings) => void;
  onCharsetOverrideChange: (e: CharsetInfo | null) => void;
  context: PDFContext;
}
class PDFPageLayoutClass extends React.Component<Props> {
  static propTypes = {
    storyboard: PropTypes.object.isRequired as Validator<IStoryboardInStore>,
    cover: PropTypes.object.isRequired as Validator<any>,
    layout: PropTypes.string.isRequired as Validator<supportedLayouts>,
    orientation: PropTypes.string.isRequired as Validator<AspectRatioName>,
    documentFormat: PropTypes.string.isRequired as Validator<supportedFormats>,
  };

  componentDidMount() {
    if (!isNull(this.props.storyboard.pdf_layout)) {
      PdfActions.setLayout.defer({
        layout_name: this.props.storyboard.pdf_layout,
        has_premium_features: this.props.storyboard.has_premium_features,
      });
    } else {
      PdfActions.setLayout.defer({
        layout_name: this.props.layout,
        has_premium_features: this.props.storyboard.has_premium_features,
      });
    }
  }

  _setLayout = (name) => {
    PdfActions.setLayout.defer({
      layout_name: name,
    });
    // We're using this action because we want to only save the pdf layout
    StoryboardActions.updateAndSave({
      slug: this.props.storyboard.slug,
      silent: true,
      pdf_layout: name,
    });
  };

  handleSetFontSize = ({ name, value }) => {
    if (!isNumber(value) || isNaN(value))
      value = getDefaultLayoutMetrics().fontSize;

    // Because we want this not to trigger a "changes saved" alert, we cannot
    // use the regular StoryboardActions.save, so we need to update on the
    // server directly
    StoryboardActions.updateAndSave({
      slug: this.props.storyboard.slug,
      silent: true,
      font_size: value,
    });

    // Then we update it in the store as well
    StoryboardActions.update({
      name,
      value,
    });
  };

  render() {
    const aspectRatio = aspectRatioNameToAspectRatio(this.props.orientation);

    const dropdownOptions = availableLayouts[this.props.documentFormat][
      this.props.orientation
    ].map((type) => {
      switch (type) {
        case '3up':
          return {
            iconClass: ('pdf-three-up-' +
              getSimilarRatio(aspectRatio)) as SVGIconType,
            title: 'Three up',
            onClick: this._setLayout,
            isSelectedWhen: '3up',
            trialAccess: true,
          };
        case '6grid':
          return {
            iconClass: 'pdf-six-up',
            title: 'Six frame grid',
            // subtitle:     "Good for showing a lot of detail at once",
            onClick: this._setLayout,
            isSelectedWhen: '6grid',
            trialAccess: true,
          };
        case '8grid':
          return {
            iconClass: 'pdf-eight-up',
            title: 'Eight frame grid',
            onClick: this._setLayout,
            isSelectedWhen: '8grid',
            trialAccess: true,
          };
        case '4grid':
          return {
            iconClass: 'pdf-four-up',
            title: 'Four frame grid',
            // subtitle:        "Large frames but include more detail",
            onClick: this._setLayout,
            isSelectedWhen: '4grid',
            trialAccess: true,
          };
        case '2grid':
          return {
            iconClass: 'pdf-two-up',
            title: 'Two up',
            // subtitle:        "Large images and lots of text",
            onClick: this._setLayout,
            isSelectedWhen: '2grid',
            trialAccess: true,
          };
        case 'list':
          return {
            iconClass: 'pdf-five-up-portrait',
            title: 'Frame list',
            // subtitle:     "Good for showing a lot of detail at once",
            onClick: this._setLayout,
            isSelectedWhen: 'list',
            trialAccess: true,
          };
        case '1up':
          return {
            iconClass: 'pdf-one-up',
            title: 'Full page',
            // subtitle:     "Perfect for showing off your images",
            onClick: this._setLayout,
            isSelectedWhen: '1up',
            trialAccess: true,
          };
        case '1up2Col':
          return {
            iconClass: 'pdf-one-up-two-col',
            title: 'Full page — Two Columns',
            // subtitle:     "Perfect for showing off your images",
            onClick: this._setLayout,
            isSelectedWhen: '1up2Col',
            trialAccess: true,
          };
      }
    });

    var cover = this.props.cover.cover;
    const backgroundColor =
      cover.theme.document_background_color &&
      cover.theme.document_background_color.length === 7
        ? cover.theme.document_background_color
        : '#ffffff';

    // Sometimes this value is an empty string. We need to treat that as if
    // it's null
    const fontColor = cover.theme.font_color?.length
      ? cover.theme.font_color
      : getColorSchemeGreyValues(backgroundColor).text.color;

    const selectedOption = fallbackIfLayoutNotSupported(
      this.props.layout,
      this.props.documentFormat,
      this.props.orientation,
    );

    // <StoryboardExportBoolean field_name="theme.has_watermark" label={'Show "Made with Boords" badge'} is_checked={cover.theme.has_watermark} is_disabled={(BoordsConfig.CanChangeBranding !== "true")} />

    return (
      <div>
        <div className="mb-6 space-y-1.5">
          <div className="font-bold">Layout</div>
          <IconDropdown selected={selectedOption} options={dropdownOptions} />
        </div>
        <div className="flex items-start justify-between mb-6">
          <div className="space-y-1.5" style={{ width: '274px' }}>
            <div className="font-bold">Font</div>
            <FontPicker
              value={this.props.currentFont}
              subsets={this.props.fontSubsets}
              onChange={this.props.onFontChange}
            />
          </div>
          <div className="space-y-1.5">
            <div className="font-bold">Text size</div>
            <div style={{ width: '70px' }}>
              <NumberPicker
                name="font_size"
                value={
                  this.props.storyboard.font_size ??
                  getDefaultLayoutMetrics().fontSize
                }
                step={1}
                onChange={this.handleSetFontSize}
                max={18}
              />
            </div>
          </div>
          <div className="space-y-1.5">
            <div className="font-bold">Text color</div>
            <div className="flex" style={maxWidth}>
              <ColorPickerForCover field="font_color" color={fontColor} />
            </div>
          </div>
          <div className="space-y-1.5">
            <div className="font-bold">Background</div>
            <div className="flex" style={maxWidth}>
              <ColorPickerForCover
                field="document_background_color"
                color={cover.theme.document_background_color}
              />
            </div>
          </div>
        </div>
        <div className="flex pt-6 mb-6 pb-9 border-y border-y-border ">
          <div>
            <div className="mb-3 font-bold">Frame settings</div>
            <div className="flex flex-col gap-3" style={{ width: '19rem' }}>
              <StoryboardExportBoolean
                field_name="storyboard.has_frame_border"
                label="Show image borders"
                is_checked={cover.storyboard.has_frame_border || false}
              />

              <StoryboardExportBoolean
                field_name="storyboard.include_label_text_in_output"
                label='Show "Label" text'
                is_checked={
                  cover.storyboard.include_label_text_in_output || false
                }
              />
              <StoryboardExportBoolean
                field_name="storyboard.include_frame_number_in_pdf"
                label="Show frame number"
                is_checked={
                  cover.storyboard.include_frame_number_in_pdf || false
                }
              />
              <StoryboardExportBoolean
                field_name="storyboard.include_icons_in_pdf"
                label="Show icons"
                is_checked={cover.storyboard.include_icons_in_pdf || false}
              />
            </div>
          </div>
          <div>
            <div className="mb-3 font-bold">
              {this.props.t(`export.${this.props.context}.documentSettings`)}
            </div>
            <div className="flex flex-col gap-3" style={{ width: '280px' }}>
              {!this.props.isPaperPortrait &&
              this.props.context !== 'presentationImages' ? (
                <StoryboardExportBoolean
                  field_name="storyboard.use_letterbox_pdf_layout"
                  label="Widescreen pages"
                  is_checked={
                    cover.storyboard.use_letterbox_pdf_layout || false
                  }
                />
              ) : null}
              <StoryboardExportBoolean
                field_name="storyboard.include_version_number"
                label="Show version number"
                is_checked={cover.storyboard.include_version_number || false}
              />

              <StoryboardExportBoolean
                field_name="theme.show_page_numbers"
                label="Show page number"
                is_checked={
                  isUndefined(cover.theme.show_page_numbers)
                    ? true
                    : cover.theme.show_page_numbers
                }
              />

              <StoryboardExportBoolean
                field_name="storyboard.hide_storyboard_title"
                label="Show name in footer"
                is_checked={cover.storyboard.hide_storyboard_title || false}
                inverted
              />
            </div>
          </div>
        </div>

        {!BoordsConfig.Freeloader && (
          <div className="flex flex-col mb-6 gap-2">
            <div className="font-semibold">{`Footer logo`}</div>
            <ExportPdfFooterLogo
              cover={this.props.cover}
              storyboard={this.props.storyboard}
              is_available={true}
            />
          </div>
        )}

        <PDFCharsetOverride
          value={this.props.charsetOverride}
          onChange={this.props.onCharsetOverrideChange}
          detectedValue={this.props.detectedCharset?.subset}
        />

        {this.props.charsetOverride === 'arabic' ? <PDFArabicMessage /> : null}
      </div>
    );
  }
}
export const PDFPageLayout = withTranslation()(PDFPageLayoutClass);
