/** @prettier */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  CanvasScrubberRenderer,
  type CanvasScrubberProps,
} from './CanvasScrubberRenderer';
import { diff } from 'deep-object-diff';
import { PureCanvas } from '../../shared/PureCanvas';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { useStore } from 'javascripts/helpers/useStore';
import { noop } from 'underscore';
import type { PlayerStore } from 'javascripts/flux/stores/player';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import classNames from 'classnames';

interface Props extends Omit<CanvasScrubberProps, 'onLoad'> {
  height?: number;
  audioIsFetching: boolean;
  className?: string;
  canvasClassName?: string;
}

export class CanvasScrubber extends React.PureComponent<
  Props,
  { isLoaded: boolean }
> {
  canvasInstance: CanvasScrubberRenderer;
  canvasRef: React.RefObject<HTMLCanvasElement>;

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = { isLoaded: false };
  }

  static defaultProps = {
    height: 85,
  };

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.canvasRef.current!) as any;
    this.canvasInstance = new CanvasScrubberRenderer(node, {
      audioData: this.props.audioData,
      time: this.props.time,
      zoom: this.props.zoom,
      handleSizeModifier: this.props.handleSizeModifier,
      endTime: this.props.endTime,
      onUpdateTime: this.props.onUpdateTime,
      frameTimes: this.props.frameTimes,
      onFrameIndicatorMoveCommit: this.props.onFrameIndicatorMoveCommit,
      hideFirstFrameIndicator: this.props.hideFirstFrameIndicator,
      currentFrameId: this.props.currentFrameId,
      darkenUncoveredSections: this.props.darkenUncoveredSections,
      currentFrameNumber: this.props.currentFrameNumber,
      audioIsFetching: this.props.audioIsFetching,
      minimal: this.props.minimal ?? false,
      onLoad: () => this.setState({ isLoaded: true }),
    });
    this.lastProps = this.props;

    window.addEventListener('resize', this.onResize);
  }

  lastProps: CanvasScrubberProps;

  componentDidUpdate() {
    // Please note, this library returns unexpected values for non-primitives
    const updatedProps: Partial<CanvasScrubberProps> = {
      ...diff(this.lastProps ?? {}, this.props),
      audioData: this.props.audioData,
    };

    // The way the diff library processes changed arrays leads to unexpected
    // results, so we have to override that
    if (this.lastProps.frameTimes !== this.props.frameTimes) {
      updatedProps.frameTimes = this.props.frameTimes;
    } else {
      delete updatedProps.frameTimes;
    }

    this.lastProps = this.props;

    this.canvasInstance.update(updatedProps);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (!this.canvasInstance) return;
    this.canvasInstance.unmount();
  }

  /** Called by the parent element when the window or UI resizes */
  onResize = () => {
    if (!this.canvasInstance) return;
    this.canvasInstance.resize();
  };

  render() {
    return (
      <div className={classNames('w-full relative', this.props.className)}>
        <PureCanvas
          className={classNames('w-full', this.props.canvasClassName)}
          height={this.props.height!}
          canvasRef={this.canvasRef}
        />
        <LoadingIndicator fill show={!this.state.isLoaded} />
      </div>
    );
  }
}

// prettier-ignore
type ConnectedProps = Omit<Props, 'onUpdateTime' | 'onFrameIndicatorMoveCommit' | 'hideFirstFrameIndicator' | 'marginBottom'>;

export const ConnectedCanvasScrubber = React.memo(
  // eslint-disable-next-line react/display-name
  React.forwardRef<any, Partial<Props>>((props, ref) => {
    const audioProps = useStore<ConnectedProps, PlayerStore>('player', (s) => ({
      audioData: s.audio?.data || undefined,
      time: s.time,
      zoom: s.timelineZoom,
      endTime: s.endTime,
      currentFrameId: s.currentFrameId!,
      currentFrameNumber: s.currentFrame?.number || '1',
      frameTimes: s.frameTimes,
      audioIsFetching:
        s.audio?.state === 'fetching' || s.audio?.state === 'unfetched',
    }));

    const handleUpdateTime = useStoreAction('PlayerActions', 'updateTime');

    return (
      <CanvasScrubber
        ref={ref}
        {...audioProps}
        {...props}
        hideFirstFrameIndicator={props.hideFirstFrameIndicator ?? true}
        onUpdateTime={handleUpdateTime}
        onFrameIndicatorMoveCommit={props.onFrameIndicatorMoveCommit ?? noop}
      />
    );
  }),
);
ConnectedCanvasScrubber.displayName = 'ConnectedCanvasScrubber';
