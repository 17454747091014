/**@prettier */
const _ = require('underscore');
const createReactClass = require('create-react-class');
const { Trash2 } = require('lucide-react');
const PropTypes = require('prop-types');
const {
  default: Tooltip,
} = require('blackbird/components/feedback/tooltip/Tooltip');
const { default: Button } = require('blackbird/components/button/Button');
const { hasNewPDFExport } = require('../../pdf/helpers/newPDFExportOptin');
const { getDefaultLayoutMetrics } = require('../../pdf/PDFLayoutMetrics');

window.ExportPdfFooterLogo = createReactClass({
  propTypes: {
    storyboard: PropTypes.object.isRequired,
    cover: PropTypes.object.isRequired,
    is_available: PropTypes.bool.isRequired,
  },

  getInitialState: function () {
    return PdfFooterLogoStore.getState();
  },

  getDefaultProps: function () {
    return {
      is_available: false,
    };
  },

  componentDidMount: function () {
    PdfFooterLogoStore.listen(this._onChange);
    PdfFooterLogoActions.fetch.defer({
      storyboard_id: this.props.storyboard.id,
    });
  },

  componentWillUnmount: function () {
    clearTimeout(window.PDFFooterLogoTimeout);
    PdfFooterLogoStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    this.setState(state);
  },

  handleButtonClick: function (e) {
    e.preventDefault();
    FilestackActions.openPdfFooterLogo({
      storyboard_id: this.props.storyboard.id,
      project_id: this.props.storyboard.project.id,
    });
  },

  _remove: function (e) {
    e.preventDefault();
    if (this.props.is_available) {
      PdfFooterLogoActions.remove({
        storyboard_id: this.props.storyboard.id,
      });
    }
  },

  _restore: function (e) {
    e.preventDefault();
    if (this.props.is_available) {
      PdfFooterLogoActions.restore({
        storyboard_id: this.props.storyboard.id,
      });
    }
  },

  _upgrade: function (e) {
    if (!this.props.is_available) {
      e.preventDefault();
      UpgradePromptActions.show('PDF checkbox');
    }
  },

  _restart: function (e) {
    e.preventDefault();
    PdfFooterLogoActions.restart.defer();
    clearTimeout(window.PDFFooterLogoTimeout);
  },

  render: function () {
    const { footerLogoMaxHeight, footerLogoMaxWidth } =
      getDefaultLayoutMetrics();

    if (!this.state.is_loaded) {
      return null;
    }

    if (!this.props.is_available) {
      return (
        <div className="flex items-center bg-near-white ba bw1 b--light-gray pv3 ph20px lh-copy br2">
          <div className="flex-auto pr3">
            <div className="f4 ">
              {`Please upgrade to add a custom logo to your storyboard footers`}
            </div>
          </div>
          <div className="tr shrink-0">
            <div
              onClick={this._upgrade}
              className="nowrap avenir-heavy dib white bg-purple f5 ph3 pv2 lh-copy br2 pointer"
            >
              Upgrade now
            </div>
          </div>
        </div>
      );
    }

    var url = this.state.response.url;

    if (this.state.response.is_processing && !this.state.force_restart) {
      return (
        <div className="flex items-center justify-center bg-near-white align-center ba bw1 b--light-gray pv3 ph20px lh-copy br2">
          <div className="lh-copy tl">
            <div className="f4">Processing...</div>
            <div
              onClick={this._restart}
              className="underline f5 silver pointer"
            >
              Restart
            </div>
          </div>
          <div className="flex-auto tr">
            <img className="dib w2" src={BoordsConfig.LoaderUrl} />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="relative flex items-center py-3 pl-4 pr-3 border border-form rounded-md">
          <div className="flex-auto">
            <div className="flex items-center group/logopreview">
              <span className="text-sm text-type-subdued">
                <ExportPdfLogoPreview
                  url={url}
                  emptyText="No logo selected"
                  className="w-80"
                />
              </span>

              {!_.isEmpty(url) && (
                <div className="flex flex-col justify-center flex-auto ml-3">
                  <Tooltip
                    title={`Remove existing logo`}
                    placement={`top-start`}
                  >
                    <div
                      className="inline-block p-2 opacity-0 cursor-pointer rounded-md hover:bg-surface group-hover/logopreview:opacity-100"
                      onClick={this._remove}
                    >
                      <Trash2 className="w-4 h-4" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <Button onClick={this.handleButtonClick} type="secondary" size="sm">
            {`Upload new logo`}
          </Button>
        </div>
        {hasNewPDFExport() ? (
          <div className="mt-3 text-sm text-type-subdued">
            Footer logos will be resized to fit inside a container{' '}
            {footerLogoMaxHeight}pt high, {footerLogoMaxWidth}pt wide.
          </div>
        ) : (
          <div className="mt-3 text-sm text-type-subdued">
            Footer logos will be resized to fit inside a container 40px high,
            300px wide.
          </div>
        )}
      </div>
    );
  },
});
