/** @format */
import React from 'react';
import { ChevronDown } from 'lucide-react';
import classNames from 'classnames';

interface DropdownChevronProps {
  open?: boolean;
  className?: string;
}

export const DropdownChevron: React.FC<DropdownChevronProps> = ({
  open,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex-shrink-0 h-6 mx-2 mt-0.5 flex items-center justify-center',
        open ? 'opacity-100' : 'opacity-40',
        className,
      )}
    >
      <ChevronDown
        size={14}
        className={classNames('transition-all ', open && '-rotate-180')}
      />
    </div>
  );
};
