/** @format */
import { type AspectRatio } from 'javascripts/components/new_project/NewStoryboardAspectRatio';
import { type frameAspectRatio } from 'javascripts/types/storyboard';

export interface AccountConfiguration {
  maxFreeImages: number;
  defaultImageCount: number;
  defaultCategory: string;
  defaultPreset: string;
  defaultAspectRatio: AspectRatio;
  style: string;
}

// Default values for fallback
const DEFAULT_CONFIG: AccountConfiguration = {
  maxFreeImages: 2,
  defaultImageCount: 0,
  defaultCategory: 'marketing',
  defaultPreset: 'digital-marketing-campaign',
  defaultAspectRatio: '16x9',
  style: 'dynamic',
};

// Complete mapping of all account types and their configurations
const ACCOUNT_CONFIGURATIONS: Record<string, AccountConfiguration> = {
  advertising: {
    maxFreeImages: 4,
    defaultImageCount: 1,
    defaultCategory: 'marketing',
    defaultPreset: 'brand-narrative-campaign',
    defaultAspectRatio: '16x9',
    style: 'photographic',
  },
  video: {
    maxFreeImages: 4,
    defaultImageCount: 1,
    defaultCategory: 'product-explainers',
    defaultPreset: 'client-pitch-concept',
    defaultAspectRatio: '16x9',
    style: 'photographic',
  },
  social: {
    maxFreeImages: 4,
    defaultImageCount: 1,
    defaultCategory: 'marketing',
    defaultPreset: 'cross-platform-campaign',
    defaultAspectRatio: '4:5',
    style: 'photographic',
  },
  training: {
    maxFreeImages: 3,
    defaultImageCount: 1,
    defaultCategory: 'training',
    defaultPreset: 'on-the-job-training',
    defaultAspectRatio: '4:3',
    style: 'photographic',
  },
  tv: {
    maxFreeImages: 3,
    defaultImageCount: 1,
    defaultCategory: 'film',
    defaultPreset: 'short-film',
    defaultAspectRatio: '16x9',
    style: 'dynamic',
  },
  animation: {
    maxFreeImages: 3,
    defaultImageCount: 1,
    defaultCategory: 'product-explainers',
    defaultPreset: 'product-explainers-video',
    defaultAspectRatio: '16x9',
    style: 'dynamic',
  },
  comics: {
    maxFreeImages: 2,
    defaultImageCount: 1,
    defaultCategory: 'film',
    defaultPreset: 'graphic-novel-sequence',
    defaultAspectRatio: '16x9',
    style: 'dynamic',
  },
  school: {
    maxFreeImages: 2,
    defaultImageCount: 1,
    defaultCategory: 'film',
    defaultPreset: 'short-film',
    defaultAspectRatio: '16x9',
    style: 'dynamic',
  },
  other: {
    maxFreeImages: 2,
    defaultImageCount: 1,
    defaultCategory: 'film',
    defaultPreset: 'short-film',
    defaultAspectRatio: '16x9',
    style: 'dynamic',
  },
};

/**
 * Gets the current account configuration based on BoordsConfig.AccountType
 */
export const getCurrentAccountConfiguration = (): AccountConfiguration => {
  const accountType =
    typeof BoordsConfig !== 'undefined' ? BoordsConfig?.AccountType : 'default';

  return ACCOUNT_CONFIGURATIONS[accountType] || DEFAULT_CONFIG;
};

export const getMaxFreeImages = (): number =>
  getCurrentAccountConfiguration().maxFreeImages;

export const getDefaultImageCount = (): number =>
  getCurrentAccountConfiguration().defaultImageCount;

export const getAccountStyle = (): string =>
  getCurrentAccountConfiguration().style;

export const getAccountCategory = (): string =>
  getCurrentAccountConfiguration().defaultCategory;

export const getAccountPreset = (): string =>
  getCurrentAccountConfiguration().defaultPreset;

export const getAccountAspectRatio = (): frameAspectRatio =>
  getCurrentAccountConfiguration().defaultAspectRatio as frameAspectRatio;
