/** @prettier */
/* eslint-disable no-prototype-builtins */
import type {
  CloudinaryAudioInfo,
  DurationOptions,
} from '../../../flux/stores/player';
import * as waveformParser from '../waveformParser';
import {
  type FrameIndicator,
  FrameIndicatorFactory,
  type newFrameIndicatorFunc,
  type FrameIndicatorProps,
} from './FrameIndicator';
import { createScrubHandle, type ScrubHandleGroup } from './ScrubHandle';
import * as defaultWaveform from '../defaultWaveform';
import { pixelRound } from '../../../helpers/pixel-round';
import { clampNumber } from '../../../helpers/clampNumber';
import { throttledAnimationFrame } from '../../../helpers/throttled-animation-frame';
import { autoFormatTime } from '../../../helpers/format-time';
import {
  isNumber,
  isNaN,
  isNull,
  isBoolean,
  chain,
  forEach,
  isEmpty,
  throttle,
  times,
  last,
  flatten,
} from 'underscore';
import * as playerColors from '../playerColors';
import type {
  FabricCanvas,
  FabricGroup,
  FabricObject,
  forEachObjectCallback,
  WaveformObject,
} from '../../frame_editor/types';
import { getIntervalResolution } from './getIntervalResolution';
import logger from 'javascripts/helpers/logger';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('canvasScrubberRenderer');

const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

const scaleNumber = (num, in_min, in_max, out_min = 0, out_max = 1) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

/** Calculates the average value of numbers in an array between two indices */
const avgRange = (array: number[], start: number, end: number) => {
  let count = 0;
  if (start === end) return array[start];

  for (let index = start; index < end; index++) {
    count += array[index] || 0;
  }

  return count === 0 ? 0 : count / (end - start);
};

type frameTimes = { [frameId: string]: number };
type sortedFrameTimes = { id: number; time: number }[];
export interface CanvasScrubberProps {
  audioData?: CloudinaryAudioInfo;
  time: number;
  zoom: number;
  endTime: number;
  currentFrameId: number;
  currentFrameNumber: string | null;
  frameTimes: frameTimes;
  onUpdateTime: (newTime: number) => void;
  audioIsFetching: boolean;
  onFrameIndicatorMoveCommit: (frameTimings: number[]) => void;
  handleSizeModifier?: number;
  hideFirstFrameIndicator: boolean;
  darkenUncoveredSections?: boolean;
  minimal?: boolean;
  onLoad?: () => void;
}

export interface WaveformInfo {
  data: number[];
  highAvg: number;
}

const getDimensions = ({
  totalHeight,
  hasAudio = true,
  minimal = false,
  handleSizeModifier = 1,
  frameNumber,
}: {
  totalHeight: number;
  hasAudio?: boolean;
  minimal?: boolean;
  handleSizeModifier?: number;
  frameNumber: string | null;
}) => {
  const waveformMargin = hasAudio ? 6 : 0;
  const paddingTop = minimal ? 0 : 6;
  const marginBottom = minimal ? 0 : paddingTop;
  const frameIndicatorHeight = 10 * handleSizeModifier;

  const availableHeight = totalHeight - marginBottom;
  const hasFrameNumberContainer = !isNull(frameNumber);

  const bottomSection = {
    top: Math.max(0, availableHeight - frameIndicatorHeight),
    /** Basically frameIndicatorHeight */
    height: frameIndicatorHeight,
  };

  const frameNumberContainer = {
    height: minimal || !hasFrameNumberContainer ? 0 : 25,
    /** space between main handle and straight line part */
    marginBottom: minimal || !hasFrameNumberContainer ? 0 : 5,
  };

  const triangleWidth = minimal ? 6 : 12;
  const playheadTop =
    frameNumberContainer.height + frameNumberContainer.marginBottom;
  const handleLine = {
    top: playheadTop,
    triangleWidth: triangleWidth,
    lineTop: playheadTop + triangleWidth,
    height:
      availableHeight - (playheadTop + triangleWidth) - bottomSection.height,
  };

  const rulerMargin = 5;
  const rulerTextSize = 12;
  const availableRulerHeight =
    bottomSection.top - rulerTextSize - rulerMargin - paddingTop;
  const rulerHeight = hasAudio
    ? availableRulerHeight / 2
    : availableRulerHeight;

  const ruler = {
    top: paddingTop,
    textSize: minimal ? 0 : rulerTextSize,
    /** space between time and the interval indicators */
    textMarginBottom: 4,
    indicatorBottom: 0,
    indicatorHeight: minimal ? 0 : rulerHeight / 2,
    height: minimal ? 0 : rulerHeight,
  };

  ruler.indicatorBottom = minimal
    ? 0
    : ruler.top +
      ruler.textSize +
      ruler.textMarginBottom +
      ruler.indicatorHeight;

  const waveform = {
    top: ruler.indicatorBottom + waveformMargin,
    right: 0,
    left: 0,
    height: 0,
  };

  if (hasAudio) {
    waveform.height =
      availableHeight - waveform.top - bottomSection.height - waveformMargin;
  }

  return {
    availableHeight,
    bottomSection,
    frameNumberContainer,
    handleLine,
    marginBottom,
    waveform,
    ruler,
    paddingTop,
    frameIndicatorHeight,
  };
};

export class CanvasScrubberRenderer {
  private initialHeight: number;
  private fabric: any;
  private canvasEl: HTMLCanvasElement;
  private waveformInfo: null | WaveformInfo;
  private time: number;
  endTime: number;
  private progress = 0;
  currentFrameId = 0;
  currentFrameNumber: string | null;
  /** The fabricjs canvas instance */
  private canvas?: FabricCanvas;
  colors = playerColors;
  private zoom: number;
  private zoomWindow: [number, number];

  private timeInfo: {
    start: number;
    end: number;
    length: number;
    msPerPixel: number;
  };

  /** The FabricJS group with the waveform elements in there */
  private waveformGroup?: FabricGroup<WaveformObject>;
  /** The FabricJS group with the border elements in there */
  private borderGroup?: FabricGroup;
  /** The an array with the frame indicators (dots) */
  private frameIndicators: FrameIndicator[];
  private frameTimes: frameTimes;
  private sortedFrameTimes: sortedFrameTimes;
  durationOptions: DurationOptions;
  private newFrameIndicator: newFrameIndicatorFunc;

  /** are we currently holding the mouse down, a.k.a. can we scrub? */
  private isMouseDown = false;
  private handleGroup?: ScrubHandleGroup;
  private timeGroup: FabricGroup;

  /* List of the dimensions and positioning of various elements */
  dimensions: ReturnType<typeof getDimensions>;

  onFrameIndicatorMoveCommit: (frameTimings: number[]) => void;
  hideFirstFrameIndicator = true;

  private events: {
    onUpdateTime: CanvasScrubberProps['onUpdateTime'];
  };

  /** URL for the waveform, used to monitor changes */
  private waveformURL?: string;
  private audioData?: CanvasScrubberProps['audioData'];
  waveformLoadingAnimation: boolean;
  /** When we don't know for sure if there's audio yet */
  audioIsFetching: boolean;
  handleSizeModifier: number;

  frameIndicatorsAreMovable = false;
  private darkenUncoveredSections: boolean;
  isMinimal = false;

  constructor(canvas: HTMLCanvasElement, options: CanvasScrubberProps) {
    this.canvasEl = canvas;
    this.endTime = options.endTime;
    this.setFrameTimes(options.frameTimes);
    this.currentFrameId = options.currentFrameId;
    this.onFrameIndicatorMoveCommit = options.onFrameIndicatorMoveCommit;
    this.handleSizeModifier = options.minimal
      ? 0.35
      : (options.handleSizeModifier ?? 1);
    this.hideFirstFrameIndicator = options.hideFirstFrameIndicator ?? true;
    this.darkenUncoveredSections = options.darkenUncoveredSections ?? false;
    this.isMinimal = options.minimal ?? false;

    this.setTime(options.time);
    this.durationOptions = PlayerStore.getState().durationOptions;
    this.audioData = options.audioData;
    this.currentFrameId = options.currentFrameId;
    this.currentFrameNumber = options.currentFrameNumber;
    this.audioIsFetching = options.audioIsFetching;
    this.events = {
      onUpdateTime: options.onUpdateTime,
    };

    // Give people something to look at in case fabric is taking a while to load
    this.canvasEl.style.background = this.colors.background;

    import('fabric')
      .then((fabric) => {
        this.fabric = fabric.fabric;
        this.canvasEl.style.removeProperty('background');

        this.dimensions = getDimensions({
          totalHeight: canvas.clientHeight,
          minimal: options.minimal,
          handleSizeModifier: options.handleSizeModifier,
          frameNumber: this.currentFrameNumber,
        });

        canvas.height += this.dimensions.marginBottom;

        this.canvas = new this.fabric.Canvas(this.canvasEl, {
          width: canvas.clientWidth,
          height: canvas.clientHeight,
          preserveObjectStacking: true,
          selection: false,
          hoverCursor: 'default',
        });

        this.setZoom(options.zoom);
        this.initialHeight = canvas.clientHeight;
        this.newFrameIndicator = FrameIndicatorFactory(this.fabric, this);
        this.drawAll();
        options.onLoad?.();

        this.loadAndDrawWaveform(this.audioData);

        /** sets the new time based on the current mouse pointer */
        const updateTime = (e) => {
          const pointer = e.pointer;
          if (!e.target || e.target.isFrameIndicator) return;
          const newTime = clampNumber(
            this.positionToTime(pointer.x),
            0,
            this.endTime,
          );

          // We call this.update because that way we'll know for sure all the
          // right drawing functions are called
          this.update({ time: newTime });
          this.events.onUpdateTime(newTime);
        };

        this.canvas.on('mouse:down', () => (this.isMouseDown = true));
        this.canvas.on('mouse:up', (e) => {
          this.isMouseDown = false;
          updateTime(e);
        });

        this.canvas.on('mouse:move', (e) => {
          if (!this.isMouseDown) return;
          updateTime(e);
        });
      })
      .catch(errorHandler({ message: 'Something went wrong' }));
  }

  unmount() {
    this.stopWaveformLoadingAnimation();
    if (this.canvas) this.canvas.dispose();
  }

  private drawAll() {
    if (!this.canvas) return;
    this.drawBackground();
    this.drawUncoveredSectionsOverlay();
    this.drawWaveform();
    this.drawTime();
    this.drawHandle();
    this.drawFrameIndicators();
    this.canvas.requestRenderAll();
  }

  resize() {
    const newWidth = this.canvas.wrapperEl.parentElement.clientWidth;
    this.canvas.setWidth(newWidth);
    this.canvas.clear();
    this.drawAll();
  }

  clear() {
    this.canvas.clear();
    this.clearFrameIndicators();
  }

  update(changes: Partial<CanvasScrubberProps>) {
    // Previously we excited early here in case there was no canvas yet, but
    // changes can come in before fabricjs has loaded, so we need to process
    // them anyway

    if (isNumber(changes.endTime) && changes.endTime !== this.endTime) {
      this.endTime = changes.endTime;
      this.drawAll();
    }

    if (isNumber(changes.time) && changes.time !== this.time) {
      this.setTime(changes.time);
      this.moveZoomWindowIfNecessary();
      this.updateWaveformProgress();
      this.updateHandle();
      this.updateFrameIndicatorsProgress();
    }

    if (isNull(changes.audioData)) {
      // When the audio is cleared, tell it to load "nothing"
      this.loadWaveform();
      this.audioData = changes.audioData;
    } else if (changes.audioData && this.audioData !== changes.audioData) {
      this.audioData = changes.audioData;

      this.animateUIHeight(this.initialHeight).then(() => {
        this.loadAndDrawWaveform(this.audioData);
      });
    }

    if (
      changes.currentFrameNumber &&
      changes.currentFrameNumber !== this.currentFrameNumber
    ) {
      this.currentFrameNumber = changes.currentFrameNumber;
      this.updateHandle();
    }

    if (
      isBoolean(changes.audioIsFetching) &&
      changes.audioIsFetching !== this.audioIsFetching
    ) {
      this.audioIsFetching = changes.audioIsFetching;
      this.loadAndDrawWaveform(changes.audioData);
    }

    if (isNumber(changes.zoom) && changes.zoom !== this.zoom) {
      this.handleUpdateZoom(changes.zoom);
    }

    if (changes.frameTimes && this.frameTimes !== changes.frameTimes) {
      this.setFrameTimes(changes.frameTimes);
      // FIXME: ideally we don't do this, but it appears we're buggy
      this.clearFrameIndicators();
      this.drawFrameIndicators();
      this.updateWaveformProgress();
      this.drawUncoveredSectionsOverlay();
    } else if (
      isNumber(changes.currentFrameId) &&
      changes.currentFrameId !== this.currentFrameId
    ) {
      this.currentFrameId = changes.currentFrameId;
      this.updateFrameIndicatorsProgress();
    }
  }

  /** Resets `this.sortedFrameTimes` in order to place frameIndicators */
  private setFrameTimes(frameTimes: frameTimes) {
    this.frameTimes = frameTimes;

    this.sortedFrameTimes = chain(frameTimes)
      .map((time, idString: string) => ({
        id: parseInt(idString, 10),
        time,
      }))
      .sortBy('time')
      .value() as any;
  }

  /**
   * sets `this.time` and `this.progress` based on the new time passed. Does
   * not send update events.
   */
  private setTime(newTime: number) {
    this.time = newTime;
    this.progress = newTime / this.endTime;
    if (isNaN(this.progress)) this.progress = 0;
  }

  private drawTime() {
    if (this.timeGroup) this.canvas.remove(this.timeGroup);
    if (this.isMinimal) return;
    const {
      msPerPixel,
      length,
      start: startTime,
      end: endTime,
    } = this.timeInfo;

    const intervals = getIntervalResolution(msPerPixel);

    const amount = Math.ceil(this.endTime / intervals.ruler);

    const objs: FabricObject[] = [];
    const { ruler } = this.dimensions;

    const textProps = {
      fontFamily: 'matter',
      fontSize: ruler.textSize,
      fill: this.colors.timeText,
      top: this.dimensions.ruler.top,
      originY: 'top',
    };

    const endTimeText = new this.fabric.Text(
      // Pad the text a bit so we give the other labels room for intersection
      // checking
      `  ${autoFormatTime(
        this.endTime,
        intervals.showFrames,
        // Only pad with one zero when space is tight
        msPerPixel > 150 ? 1 : 2,
      )}  `,
      {
        ...textProps,
        fontWeight: 600,
        fill: this.colors.endTimeText,
        left: pixelRound(this.canvas.width),
        originX: 'right',
        top: textProps.top - 1,
      },
    );
    objs.push(endTimeText);

    times(amount, (i) => {
      const currentTime = i * intervals.ruler;
      if (currentTime < startTime || currentTime > endTime) return;

      const progress = (currentTime - startTime) / length;
      const left = this.canvas.width * progress;

      if (left > 0 && left < this.canvas.width) {
        const isBigRuler = currentTime % intervals.largeRuler === 0;
        const isLabel = currentTime % intervals.label === 0;
        const height = isBigRuler
          ? ruler.indicatorHeight
          : ruler.indicatorHeight * 0.6;

        const props = {
          left: pixelRound(left),
          originX: 'center',
          originY: 'top',
          strokeWidth: 0,
        };

        objs.push(
          new this.fabric.Rect({
            ...props,
            top: this.dimensions.ruler.indicatorBottom - height,
            fill: this.colors.ruler,
            width: 1,
            height,
          }),
        );

        if (isLabel) {
          const text = new this.fabric.Text(
            autoFormatTime(
              currentTime,
              intervals.showFrames,
              // Only pad with one zero when space is tight
              msPerPixel > 150 ? 1 : 2,
            ),
            {
              ...props,
              ...textProps,
            },
          );

          // If we're intersecting with the end time, remove this item.
          if (!text.intersectsWithObject(endTimeText)) {
            objs.push(text);
          }
        }
      }
    });

    this.timeGroup = new this.fabric.Group(objs, {
      selectable: false,
    });

    this.canvas.add(this.timeGroup);
  }

  private drawBackground() {
    if (this.borderGroup) this.canvas.remove(this.borderGroup);
    const dimensions = this.dimensions;

    this.borderGroup = new this.fabric.Group(
      [
        // Main BG
        new this.fabric.Rect({
          top: 0,
          left: 0,
          fill: this.colors.background,
          width: this.canvas.width,
          height: dimensions.availableHeight,
          strokeWidth: 0,
        }),

        // For indicator
        new this.fabric.Rect({
          top: dimensions.bottomSection.top,
          left: 0,
          fill: '#F2F2EE',
          width: this.canvas.width,
          height: dimensions.bottomSection.height,
          strokeWidth: 0,
          visible: !this.isMinimal,
        }),
      ],
      {
        originY: 'top',
        selectable: false,
      },
    );

    this.canvas.add(this.borderGroup);
    this.borderGroup?.sendToBack();
  }

  /** Function to execute on every waveform segment */
  private setWaveformFillStyle: forEachObjectCallback<WaveformObject> = (o) => {
    if (!o) return;
    const fillStyle =
      o.progress < this.progress
        ? this.colors.waveformAccent
        : this.colors.waveform;
    if (o.fill !== fillStyle) o.set('fill', fillStyle);
  };

  private updateWaveformProgress() {
    if (!this.canvas || !this.waveformGroup) return;
    this.waveformGroup.forEachObject(this.setWaveformFillStyle);
    this.canvas.requestRenderAll();
  }

  /** Updates existing frame indicators with their active state */
  private updateFrameIndicatorsProgress() {
    if (!this.canvas || !this.frameIndicators) return;

    this.frameIndicators.forEach((o, i) => {
      o.setActive(this.currentFrameId === o.frameId);
    });

    this.canvas.requestRenderAll();
  }

  private getSiblingTimes(index) {
    return [
      index === 0 ? 0 : this.sortedFrameTimes[index - 1].time,
      index === this.sortedFrameTimes.length - 1
        ? this.endTime
        : this.sortedFrameTimes[index + 1].time,
    ] as any;
  }

  private clearFrameIndicators() {
    this.frameIndicators.forEach((i) => this.canvas.remove(i));
    this.frameIndicators = [];
  }
  /** Clears and draws new frame indicators based on `this.sortedFrameTimes` */
  private drawFrameIndicators() {
    if (!this.canvas) return;
    const dimensions = this.dimensions;
    const top =
      dimensions.bottomSection.top + dimensions.frameIndicatorHeight / 2;

    if (!this.frameIndicators) this.frameIndicators = [];

    if (isEmpty(this.frameTimes)) {
      return this.clearFrameIndicators();
    }

    forEach(this.sortedFrameTimes, ({ time, id }, index) => {
      if (index === 0 && this.hideFirstFrameIndicator) return;
      const lastIndicator = last(this.frameIndicators);

      let circle: FrameIndicator | undefined = this.frameIndicators[index];
      const props: FrameIndicatorProps = {
        top: top,
        frameId: id,
        time,
        onTimeChange: this.handleFrameIndicatorMove,
        onMoveComplete: this.handleFrameIndicatorMoveComplete,
        siblingTimes: this.getSiblingTimes(index),
        isMovable: this.frameIndicatorsAreMovable,
        sizeModifier: this.handleSizeModifier,
      };

      if (circle) {
        circle.update(props);
        if (!this.canvas.contains(circle)) this.canvas.add(circle);
      } else {
        circle = this.newFrameIndicator(props);
        this.frameIndicators.push(circle);
        this.canvas.add(circle);
      }

      if (this.timeInfo.msPerPixel > 150) circle.setSizeModifier(0.5);
      if (lastIndicator && circle.intersectsWithObject(lastIndicator))
        circle.set('opacity', 0.5);
      circle.setCoords();
    });
  }

  /** Two pairs of bg and border */
  private coveredSectionsOverlays: FabricObject[][] = [];
  private drawUncoveredSectionsOverlay(
    headPosition?: number,
    tailPosition?: number,
  ) {
    if (!this.darkenUncoveredSections) return;
    const height = this.dimensions.bottomSection.top;

    if (this.coveredSectionsOverlays.length === 0) {
      const sharedProps: Partial<FabricObject> = {
        left: 0,
        top: 0,
        width: 1,
        height: height,
        // fill: this.colors.scrubberDot,
        fill: '#F2F2EE',
        // fill: 'red',
        objectCaching: false,
        strokeWidth: 0,
        selectable: false,
        originX: 'left',
        originY: 'top',
      };

      this.coveredSectionsOverlays.push(
        [
          new this.fabric.Rect(sharedProps),
          new this.fabric.Rect({
            ...sharedProps,
            fill: this.colors.scrubberDot,
          }),
        ],
        [
          new this.fabric.Rect(sharedProps),
          new this.fabric.Rect({
            ...sharedProps,
            fill: this.colors.scrubberDot,
          }),
        ],
      );

      this.canvas.add(...flatten(this.coveredSectionsOverlays));
    }

    headPosition =
      headPosition ??
      this.timeToPosition(this.sortedFrameTimes[0]?.time ?? 0) ??
      0;
    tailPosition =
      tailPosition ??
      this.timeToPosition(last(this.sortedFrameTimes)?.time ?? 0) ??
      this.timeToPosition(this.endTime)!;

    if (!isNumber(headPosition)) throw new Error('expect to have headPosition');
    if (!isNumber(tailPosition)) throw new Error('expect to have tailPosition');

    // Head section
    this.coveredSectionsOverlays[0][0].set({
      left: 0,
      width: pixelRound(headPosition),
      height,
    });

    this.coveredSectionsOverlays[0][1].set({
      left: pixelRound(headPosition),
      visible: headPosition !== 0,
      height,
    });

    // Tail section
    this.coveredSectionsOverlays[1][0].set({
      left: pixelRound(tailPosition),
      width: pixelRound(this.canvas.width - tailPosition),
      height,
    } as Partial<FabricObject>);

    this.coveredSectionsOverlays[1][1].set({
      left: pixelRound(tailPosition),
      visible: true,
      height,
    });
  }

  private dragIndicator?: FabricObject;
  // This is called when a frame indicator is moved
  private handleFrameIndicatorMove = ({ time, left }) => {
    const progress = time / this.endTime;
    let closestObject;
    let closestObjectDiff;

    this.waveformGroup?.forEachObject((o) => {
      const diff = Math.abs(o.progress - progress);
      if (!closestObjectDiff || closestObjectDiff > diff) {
        // Reset the style of the previous candidate
        this.setWaveformFillStyle(closestObject);
        closestObject = o;
        closestObjectDiff = diff;
        // Change the style of this point
        closestObject.set('fill', this.colors.waveformPointer);
      } else {
        this.setWaveformFillStyle(o);
      }
    });

    if (!this.waveformGroup) {
      if (!this.dragIndicator) {
        const top = this.dimensions.ruler.textSize + this.dimensions.paddingTop;

        const height = this.dimensions.ruler.height;

        this.dragIndicator = new this.fabric.Rect({
          left: pixelRound(left),
          top,
          height,
          width: 1,
          fill: this.colors.scrubberDot,
          objectCaching: false,
          strokeWidth: 0,
          selectable: false,
          originX: 'center',
          originY: 'top',
        });
        this.canvas.add(this.dragIndicator);
      } else {
        this.dragIndicator.set('left', left);
      }
    }

    this.drawUncoveredSectionsOverlay(
      this.frameIndicators[0].left,
      this.frameIndicators[1].left,
    );
    this.canvas.requestRenderAll();
  };

  private handleFrameIndicatorMoveComplete = () => {
    if (this.dragIndicator) {
      this.canvas.remove(this.dragIndicator);
      delete this.dragIndicator;
    }
    this.commitFrameTimings();
    this.canvas.requestRenderAll();
  };

  /** Commit the new frame timings after a frame indicator has been moved */
  private commitFrameTimings = () => {
    this.onFrameIndicatorMoveCommit(this.frameIndicators.map((n) => n.time));
  };

  /** Draws the current WaveformInfo to the canvas */
  private drawWaveform() {
    this.drawWaveformData(this.waveformInfo);
  }

  /** Draws supplied WaveformInfo to the canvas */
  private drawWaveformData(
    waveformInfo: WaveformInfo | null,
    compressFunc = easeInOutQuad,
    ignoreZoom = false,
  ) {
    if (!this.canvas) return;
    if (!waveformInfo) {
      if (this.waveformGroup) {
        this.canvas.remove(this.waveformGroup);
        delete this.waveformGroup;
      }
      return;
    }

    if (this.waveformGroup) this.canvas.remove(this.waveformGroup);
    const { waveform } = this.dimensions;
    const waveformData = waveformInfo.data;
    const highAvg = waveformInfo.highAvg;
    const waveformWidth = this.canvas.width - waveform.left - waveform.right;
    const waveformHeight = waveform.height;

    const zoomWindow = ignoreZoom ? [0, 1] : this.zoomWindow;
    const displayFactor = zoomWindow[1] - zoomWindow[0];
    const startPixel = waveformData.length * zoomWindow[0];

    const stepPerPixel = (waveformData.length / waveformWidth) * displayFactor;
    const spacing = 1;
    const barWidth = 2;
    const minValue = 0.05;

    // Use the highAvg value as a basic loudness indicator, and use it to
    // "normalize" the waveform by creating a multiplication factor like 1.3
    const compensationFactor = highAvg ? Math.max(1, 0.75 / highAvg) : 1;

    const groupItems: FabricObject[] = [];

    for (let i = 0; i < waveformWidth; i += barWidth + spacing) {
      const value =
        avgRange(
          waveformData,
          Math.floor(startPixel + i * stepPerPixel),
          Math.floor(startPixel + (i + 1) * stepPerPixel),
        ) * compensationFactor;

      // We apply an easing/compression func to increase its "contrast",
      // We also set a "minimum value" to make sure the bar is always visible
      const compressedValue = clampNumber(
        minValue + compressFunc(value) * (1 - minValue),
        minValue,
        1,
      );

      const barHeight = pixelRound(compressedValue * waveformHeight);
      const progress = (startPixel + i * stepPerPixel) / waveformData.length;
      const fillStyle =
        progress < this.progress && !ignoreZoom
          ? this.colors.waveformAccent
          : this.colors.waveform;

      groupItems.push(
        new this.fabric.Rect({
          left: pixelRound(i),
          top: pixelRound(waveformHeight / 2 - barHeight / 2),
          width: barWidth,
          height: barHeight,
          fill: fillStyle,
          objectCaching: false,
          strokeWidth: 0,
          // Add a property to the object indicating what fraction of progress
          // is represented by this rectangle
          progress,
        }),
      );
    }

    this.waveformGroup = new this.fabric.Group(groupItems, {
      selectable: false,
      left: waveform.left,
      top: waveform.top,
    });

    // if (typeof position !== 'undefined') {
    //   this.canvas.insertAt(this.waveformGroup, position - 1);
    // } else {
    this.canvas.add(this.waveformGroup);
    // }
  }

  private startWaveformLoadingAnimation = () => {
    if (this.waveformLoadingAnimation) return;
    this.waveformLoadingAnimation = true;
    const compressFunc = (v) => v;
    const waveformPos = this.dimensions.waveform;
    const waveformHeight = waveformPos.height;
    const testData = defaultWaveform(10000);
    const getText = () =>
      this.audioIsFetching
        ? 'Getting audio information'
        : 'Loading audio preview';

    const loadingText = new this.fabric.Text(getText(), {
      fontSize: 9,
      fontFamily: 'matter',
      textAlign: 'center',
      fill: this.colors.waveformText,
      originX: 'center',
      originY: 'center',
      strokeWidth: 0,
      shadow: {
        color: this.colors.background,
        offsetX: 0,
        offsetY: 0,
        blur: 2,
      },
      left: pixelRound(
        (this.canvas.width - waveformPos.right - waveformPos.left) / 2 +
          waveformPos.left,
      ),
      top: pixelRound(waveformPos.top + waveformHeight / 2),
    });

    this.canvas.add(loadingText);

    throttledAnimationFrame(() => {
      if (!this.waveformLoadingAnimation) {
        this.canvas.remove(loadingText);
        return false;
      }

      this.drawWaveformData(
        {
          data: testData,
          highAvg: 0.75,
        },
        compressFunc,
        true,
      );

      testData.unshift(testData.pop()!);
      testData.unshift(testData.pop()!);
      testData.unshift(testData.pop()!);
      loadingText.set('text', getText());
      loadingText.bringToFront();
    }, 60);
  };

  private stopWaveformLoadingAnimation() {
    this.waveformLoadingAnimation = false;
  }

  /** Sets new zoom + zoomWindow values */
  private setZoom(zoom: number) {
    // Even though we don't use this.zoom a lot, we have to keep track of it
    // to see if it changed
    this.zoom = zoom;

    // I got this equation by telling wolframalpha to fit a couple of
    // good-looking values to a logarithmic value.
    // https://www.wolframalpha.com/input?i=log+fit+%282000%2C0.55%29%2C%2847544%2C+0.9%29%2C%28120000%2C+0.989%29%2C%28201000%2C+0.996%29%2C%28399000%2C+0.999%29
    const maxZoomFactor = 0.0908463 * Math.log(0.279956 * this.endTime);
    // Clamp in case some weird stuff happens
    const zoomFactor = clampNumber(1 - zoom * maxZoomFactor, 0, 1);

    const center = this.progress;
    const windowWidth = zoomFactor / 2;
    const start = center - windowWidth;
    const end = center + windowWidth;

    const adjustment = Math.max(0 - start, 0) + Math.min(1 - end, 0);
    this.zoomWindow = [start + adjustment, end + adjustment];

    const startTime = this.endTime * this.zoomWindow[0];
    const endTime = this.endTime * this.zoomWindow[1];
    const length = endTime - startTime;
    const msPerPixel = length / this.canvas.width;

    this.timeInfo = {
      start: startTime,
      end: endTime,
      length,
      msPerPixel,
    };
  }

  private throttledUpdateZoom = throttle(() => {
    this.drawWaveform();
    this.updateHandle();
    this.drawTime();
    // Bring the handle to the front, so that it is placed over the waveform
    this.handleGroup?.bringToFront();
    // Then, draw new frameIndicator
    this.drawFrameIndicators();
    this.drawUncoveredSectionsOverlay();
    this.canvas.requestRenderAll();
  }, 1000 / 24);

  /** sets new zoom values, triggers rerender */
  private handleUpdateZoom(zoom: number) {
    this.setZoom(zoom);
    requestAnimationFrame(this.throttledUpdateZoom);
  }

  private moveZoomWindowIfNecessary() {
    if (!this.zoomWindow) return;
    const viewportSize = this.zoomWindow[1] - this.zoomWindow[0];
    const adjustmentLeft = Math.min(0, this.progress - this.zoomWindow[0]);
    const adjustmentRight = Math.max(0, this.progress - this.zoomWindow[1]);

    if (this.progress > this.zoomWindow[1]) {
      // Prevent new value from going out of bounds
      const adjustment = Math.min(
        0,
        1 - (this.zoomWindow[1] + adjustmentRight + viewportSize),
      );
      this.zoomWindow = [
        this.zoomWindow[0] + adjustmentRight + viewportSize + adjustment,
        this.zoomWindow[1] + adjustmentRight + viewportSize + adjustment,
      ];
      requestAnimationFrame(this.throttledUpdateZoom);
    } else if (this.progress < this.zoomWindow[0]) {
      this.zoomWindow = [
        this.zoomWindow[0] + adjustmentLeft,
        this.zoomWindow[1] + adjustmentLeft,
      ];
      requestAnimationFrame(this.throttledUpdateZoom);
    }
  }

  /**
   * Calculates a new 'progress' value based on a position relative to the
   * canvas.
   */
  positionToProgress(xPosition: number) {
    const { waveform } = this.dimensions;
    const waveformWidth = this.canvas.width - waveform.left - waveform.right;
    const pointerProgress = (xPosition - waveform.left) / waveformWidth;

    return (
      this.zoomWindow[0] * (1 - pointerProgress) +
      this.zoomWindow[1] * pointerProgress
    );
  }

  positionToTime = (xPosition: number) =>
    this.positionToProgress(xPosition) * this.endTime;

  /**
   * Calculates what x-position on the canvas corresponds to the 'progress'
   * value passed.
   */
  progressToPosition(progress: number) {
    const { waveform } = this.dimensions;
    const waveformWidth = this.canvas.width - waveform.left - waveform.right;

    const scaledProcess = scaleNumber(
      isNaN(progress) ? 0 : clampNumber(progress, 0, 1),
      this.zoomWindow[0],
      this.zoomWindow[1],
    );

    const position = scaledProcess * waveformWidth + waveform.left;
    return scaledProcess < 0 || scaledProcess > 1 ? null : pixelRound(position);
  }

  timeToPosition = (time: number) => {
    return this.progressToPosition(time / this.endTime);
  };

  private drawHandle() {
    if (this.handleGroup) this.canvas.remove(this.handleGroup);
    this.handleGroup = createScrubHandle({
      fabric: this.fabric,
      renderer: this,
      currentFrameNumber: this.isMinimal ? null : this.currentFrameNumber,
      totalHeight: this.dimensions.availableHeight,
      totalWidth: this.canvas.width,
      time: this.time,
      color: '#000000',
      onTimeChange: this.handleHandleMove,
    });

    this.canvas.add(this.handleGroup);
  }

  private handleHandleMove = (time) => {
    this.setTime(time);
    this.updateWaveformProgress();
    this.events.onUpdateTime(this.time);
    this.handleGroup?.bringToFront();
  };

  private updateHandle() {
    if (!this.canvas || !this.handleGroup) return;
    this.handleGroup.updateHandleTime(this.time);
    if (this.currentFrameNumber)
      this.handleGroup.text.set('text', this.currentFrameNumber);
  }

  private loadWaveform = (audio?: CloudinaryAudioInfo) =>
    new Promise<void>((resolve) => {
      if (!this.canvas || this.audioIsFetching) return resolve();
      if (!audio || audio.waveform === '') {
        this.waveformInfo = null;
        this.collapseUI();
        // null would be more correct, but we receive '' from the server, and
        // we also compare this to incoming values, so let's be consistent.
        this.waveformURL = '';
        return resolve();
      }

      this.waveformURL = audio.waveform;
      waveformParser(audio.waveform, (result) => {
        this.waveformInfo = result;
        this.stopWaveformLoadingAnimation();
        resolve();
      });
    });

  private loadAndDrawWaveform = (audio?: CloudinaryAudioInfo) => {
    if (!this.canvas) return;
    this.startWaveformLoadingAnimation();
    this.loadWaveform(audio).then(() => {
      this.drawWaveform();
      this.canvas.requestRenderAll();
    });
  };

  rerender = () => {
    // Guarding for canvas that has been disposed of already
    if (this.canvas.lowerCanvasEl) this.canvas.requestRenderAll();
  };

  private animateUIHeight = (targetHeight: number) => {
    if (!this.canvas || this.canvas.height === targetHeight)
      return Promise.resolve();

    return new Promise<void>((resolve) => {
      const dimensions = this.dimensions;
      const duration = 250;
      const fps = 60;
      const amountOfFrames = duration / (1000 / fps);

      const targetDimensions = getDimensions({
        totalHeight: targetHeight,
        hasAudio: false,
        minimal: this.isMinimal,
        handleSizeModifier: this.handleSizeModifier,
        frameNumber: this.currentFrameNumber,
      });

      const initialHeight = this.canvas.height;
      const initialRulerHeight = dimensions.ruler.height;
      const endRulerHeight = targetDimensions.ruler.height;

      const diff = this.canvas.height - targetHeight;
      const rulerDiff = dimensions.ruler.height - endRulerHeight;

      this.stopWaveformLoadingAnimation();
      throttledAnimationFrame((frameNo) => {
        if (frameNo >= amountOfFrames) {
          resolve();
          return false;
        }

        const progress = easeInOutQuad(frameNo / amountOfFrames);
        const target = Math.round(initialHeight - diff * progress);

        const targetRulerHeight = Math.round(
          initialRulerHeight - rulerDiff * progress,
        );

        if (this.canvas && this.canvas.lowerCanvasEl) {
          this.canvas.setHeight(target);
          this.dimensions = getDimensions({
            totalHeight: target,
            hasAudio: !!this.audioData,
            minimal: this.isMinimal,
            handleSizeModifier: this.handleSizeModifier,
            frameNumber: this.currentFrameNumber,
          });
          dimensions.ruler.height = targetRulerHeight;
          this.drawAll();
        }
      }, fps);
    });
  };

  private collapseUI = () => this.animateUIHeight(58);
}
