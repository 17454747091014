/** @prettier */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { FrameCommentCount } = require('../../frames/FrameCommentCount.react');
const FrameStatusIndicator = require('../../frames/editor/FrameStatusIndicator');
const { SmartImg } = require('../../shared/Img');
const navigateToRoute = require('../../../helpers/router/navigate-to-route');
const BEMHelper = require('react-bem-helper');
const { ShareableItemInfo } = require('./ShareableItemInfo');
const {
  getFrameImageSources,
} = require('javascripts/helpers/useFrameImageSources');
const { Watermark } = require('blackbird/components/watermark/Watermark');
const {
  getErrorFrameImageURL,
} = require('javascripts/helpers/getMissingFrameImageURL');

const {
  default: Tooltip,
} = require('blackbird/components/feedback/tooltip/Tooltip');

const bemHelper = new BEMHelper({
  name: 'grouped-frame-shareable',
  outputIsString: true,
});

module.exports = createReactClass({
  displayName: 'ShareableGridItem',

  propTypes: {
    storyboard: PropTypes.object.isRequired,
    frame: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    gridSize: PropTypes.string,
    grouped: PropTypes.oneOf([true, false, 'start', 'end']),
    zoomType: PropTypes.oneOf(['player', 'zoom']),
    commentCount: PropTypes.number,
    estimatedWidth: PropTypes.number,
    fullSizeImage: PropTypes.bool,
  },

  getInitialState: function () {
    return {
      commentsEnabled: this.props.storyboard.has_comments_enabled,
    };
  },

  handleHover() {
    ShareableActions.setActiveIndex(this.props.index);
  },

  handleClick(e) {
    e.preventDefault();
    navigateToRoute(this.props.zoomType, {
      frameIndex: this.props.index,
      slug: this.props.storyboard.permaslug,
    });
  },

  render: function () {
    const index = this.props.index;
    const frame = this.props.frame;
    const imageClass = this.props.storyboard.has_frame_border
      ? ' has-frame-border'
      : '';
    const aspectRatio = this.props.storyboard.frame_aspect_ratio;
    const { width, height } = BoordsFrameSizeHelper(aspectRatio);

    const className =
      this.props.grouped &&
      bemHelper(null, [
        'shareable',
        this.props.grouped === 'start' && 'start',
        this.props.grouped === 'end' && 'end',
      ]);

    const sources = getFrameImageSources(frame, aspectRatio);

    return (
      <li
        className={`shareable__grid__item flex flex-col gap-4 relative ${className}`}
        onMouseEnter={this.handleHover}
      >
        <div
          className={'cursor-zoom-in ' + imageClass}
          onClick={this.handleClick}
        >
          {this.props.storyboard.preferences.share_with_frame_status && (
            <div className={'absolute -top-2 -right-2 z-10'}>
              <FrameStatusIndicator frame={frame} />
            </div>
          )}
          <Watermark>
            <SmartImg
              sources={sources}
              width={width}
              height={height}
              placeholder={getErrorFrameImageURL(
                this.props.storyboard.frame_aspect_ratio,
              )}
              estimatedWidth={this.props.estimatedWidth}
              log
            />
          </Watermark>
        </div>
        <ShareableItemInfo
          storyboard={this.props.storyboard}
          frame={frame}
          index={index}
          frame_fields={this.props.storyboard.frame_fields}
          labelsHaveBackground={true}
          gridSize={this.props.gridSize}
        />

        {this.props.commentCount && this.state.commentsEnabled && (
          <div className="absolute top-0 left-0 p-3" onClick={this.handleClick}>
            <Tooltip placement="bottom-start" title={`Open comment view`}>
              <div>
                <FrameCommentCount
                  className="cursor-pointer"
                  count={this.props.commentCount}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </li>
    );
  },
});
