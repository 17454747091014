/** @format */

import { LocalState } from 'javascripts/helpers/local-state';

import React, {
  createContext,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
  useCallback,
} from 'react';
import i18n from 'i18next';
import { type GeneratorStyle } from 'javascripts/types/frame';
import { defaultStyle } from 'blackbird/components/generator/GeneratorContext';
import {
  getAccountCategory,
  getAccountPreset,
  getDefaultImageCount,
} from 'javascripts/helpers/account-type-config-helper';
import logger from 'javascripts/helpers/logger';

export interface AiPreset {
  title: string;
  slug: string;
  placeholder: string;
  category: string;
  example: string;
  generic?: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface AiLanguage {
  label: string;
  value: string;
  default?: boolean;
}

export interface BoordsAiContextProps {
  imageCount: number;
  setImageCount: Dispatch<SetStateAction<number>>;
  showAi: boolean;
  setShowAi: Dispatch<SetStateAction<boolean>>;
  useAssistant: boolean;
  setUseAssistant?: Dispatch<SetStateAction<boolean>>;
  presets: AiPreset[];
  style: GeneratorStyle;
  setStyle: Dispatch<SetStateAction<GeneratorStyle>>;
  preset: AiPreset;
  setPreset: Dispatch<SetStateAction<AiPreset>>;
  presetOptions: SelectOption[];
  categories: SelectOption[];
  category: SelectOption;
  setCategory: Dispatch<SetStateAction<SelectOption>>;
  languages: AiLanguage[];
  language: AiLanguage;
  setLanguage: Dispatch<SetStateAction<AiLanguage>>;
}

const useAssistantKey = 'newStoryboardUseAiAssistant';
const aiLanguageKey = 'aiLanguageKey';
const aiPresetKey = 'aiPresetKey';
const aiCategoryKey = 'aiCategoryKey';

const initCategories = i18n.t('ai:categories', {
  returnObjects: true,
}) as SelectOption[];
const initPresets = i18n.t('ai:presets', { returnObjects: true }) as AiPreset[];
const initLanguages = i18n.t('ai:languages', {
  returnObjects: true,
}) as AiLanguage[];

const presetsToSelectOptions = (presets: AiPreset[]): SelectOption[] => {
  return presets.map((preset) => ({
    label: preset.title,
    value: preset.slug,
  }));
};

const defaultValues = {
  imageCount: getDefaultImageCount(),
  setImageCount: () => {},
  showAi: false,
  useAssistant: BoordsConfig.QuizIsAi ?? false,
  categories: initCategories,
  category: initCategories.find(
    (c) => c.value === getAccountCategory(),
  ) as SelectOption,
  presets: initPresets,
  preset: initPresets.find((c) => c.slug === getAccountPreset()) as AiPreset,
  presetOptions: presetsToSelectOptions(initPresets),
  style: defaultStyle,
  languages: initLanguages,
  language: initLanguages[0],
  setUseAssistant: () => {},
  setShowAi: () => {},
  setCategory: () => {},
  setPreset: () => {},
  setStyle: () => {},
  setLanguage: () => {},
};

export const BoordsAiContext =
  createContext<BoordsAiContextProps>(defaultValues);

export const BoordsAiProvider: React.FC = ({ children }) => {
  const [showAi, setShowAi] = useState(defaultValues.showAi);
  const [useAssistant, setUseAssistant] = useState(defaultValues.useAssistant);
  const [imageCount, setImageCount] = useState<number>(
    defaultValues.imageCount,
  );

  const [presets] = useState<AiPreset[]>(defaultValues.presets);
  const [style, setStyle] = useState<GeneratorStyle>(defaultValues.style);
  const [preset, setPreset] = useState<AiPreset>(defaultValues.preset);
  const [presetOptions, setPresetOptions] = useState<SelectOption[]>(
    defaultValues.presetOptions,
  );

  const [languages] = useState<AiLanguage[]>(defaultValues.languages);
  const [language, setLanguage] = useState<AiLanguage>(defaultValues.language);

  const [categories] = useState<SelectOption[]>(defaultValues.categories);
  const [category, setCategory] = useState<SelectOption>(
    defaultValues.category,
  );

  useEffect(() => {
    const { BoordsConfig } = window as any;
    if (BoordsConfig?.IsAiOptIn) {
      setShowAi(true);
    }
  }, []);

  // Set and remember useAssistant value
  useEffect(() => {
    if (LocalState.getValue(useAssistantKey)) {
      setUseAssistant(LocalState.getValue(useAssistantKey) as boolean);
    }
  }, []);
  useEffect(() => {
    LocalState.setValue(useAssistantKey, useAssistant);
  }, [useAssistant]);

  const setLanguageByShortCode = useCallback(
    (code: string) => {
      const matchingLanguage = languages.find((lang) => lang.value === code);
      if (matchingLanguage) {
        setLanguage(matchingLanguage);
      }
    },
    [languages],
  );

  // Apply saved language or browser language
  useEffect(() => {
    if (languages) {
      if (LocalState.getValue(aiLanguageKey)) {
        setLanguageByShortCode(LocalState.getValue(aiLanguageKey) as string);
      } else {
        setLanguageByShortCode(navigator.language);
      }
    }
  }, [setLanguageByShortCode, languages]);

  useEffect(() => {
    LocalState.setValue(aiLanguageKey, language.value);
  }, [language.value]);

  useEffect(() => {
    if (category) {
      if (category.value === 'all') {
        setPresetOptions(presetsToSelectOptions(defaultValues.presets));
        setPreset(defaultValues.presets[0]);
      } else {
        setPresetOptions((prevOptions) => {
          const filteredPresets = defaultValues.presets.filter(
            (p: AiPreset) => p.category === category.value,
          ) as AiPreset[];
          if (filteredPresets.length > 0) {
            // Set the structure preset based on account type
            const possibleInitialPreset = filteredPresets.find(
              (p) => p.slug === getAccountPreset(),
            );
            if (possibleInitialPreset) {
              setPreset(possibleInitialPreset);
            } else {
              setPreset(filteredPresets[0]);
            }
            return presetsToSelectOptions(filteredPresets);
          }

          setPreset(defaultValues.presets[0]);
          return prevOptions;
        });
      }
    }
  }, [category]);

  useEffect(() => {
    if (preset && showAi) {
      LocalState.setValue(aiPresetKey, preset.slug);
    }
  }, [preset, showAi]);

  // Save category
  useEffect(() => {
    if (categories) {
      if (LocalState.getValue(aiCategoryKey)) {
        const savedCategory = categories.find(
          (category: SelectOption) =>
            category.value === (LocalState.getValue(aiCategoryKey) as string),
        ) as SelectOption;
        if (savedCategory) {
          setCategory(savedCategory);
        }
      }
    }
  }, [categories]);

  useEffect(() => {
    if (category && showAi) {
      LocalState.setValue(aiCategoryKey, category.value);
    }
  }, [category, showAi]);

  const value = {
    setShowAi,
    showAi,
    useAssistant,
    setUseAssistant,
    presets,
    preset,
    categories,
    category,
    setCategory,
    setPreset,
    presetOptions,
    languages,
    language,
    setLanguage,
    style,
    setStyle,
    imageCount,
    setImageCount,
  };

  return (
    <BoordsAiContext.Provider value={value}>
      {children}
    </BoordsAiContext.Provider>
  );
};
