/** @prettier */
import React, { useState, useMemo, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import useCustomPopper from '../../helpers/hooks/useCustomPopper';
import Button from '../button/Button';
import Checkbox from '../form/checkbox/Checkbox';
import { IStoryboardVersion } from 'javascripts/types/storyboard';
import { IconButton } from '../common/IconButton';
import DeleteIcon from 'blackbird/images/icons/delete-2.svg';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { useTranslation } from 'react-i18next';
import Tooltip from '../feedback/tooltip/Tooltip';
import {
  GetPermission,
  hasPermission,
} from 'javascripts/helpers/has-permission';
import { DropdownChevron } from '../common/DropdownChevron';

/** barebones version of the version popup, actual implementation to follow */
export const VersionDropdown: React.VFC<{
  versions: IStoryboardVersion[];
  selectedVersion: number;
  /**
   * To show selected text in the format "{version} of {number}"
   */
  showTotalCount?: boolean;
  storyboardOwnerId?: number;
  onSelectVersion?: (item: IStoryboardVersion) => void;
  onNewVersion?: (copyComments: boolean, copyFrameStatuses: boolean) => void;
  isShareable?: boolean;
  className?: string;
}> = (props) => {
  const [busy, setBusy] = useState<boolean>(false);
  const [copyComments, setCopyComments] = useState<boolean>(true);
  const [copyFrameStatuses, setCopyFrameStatuses] = useState<boolean>(false);
  const [isArchiving, setIsArchiving] = useState<boolean>(false);
  const [parentRef, setParentRef] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {
    versions,
    selectedVersion,
    showTotalCount,
    className,
    isShareable,
    storyboardOwnerId,
  } = props;
  const [currentVersion, setCurrentVersion] = useState<number>();
  const currentVersionDetails = useMemo(
    () => versions.find((item) => item.version_number === currentVersion),
    [currentVersion, versions],
  );
  const { t } = useTranslation(undefined, { keyPrefix: 'storyboard.versions' });
  useEffect(() => {
    setCurrentVersion(selectedVersion);
  }, [selectedVersion]);

  const { styles, attributes } = useCustomPopper(parentRef, popperElement, {
    placement: 'bottom-start',
    distance: 4,
    maxHeight: 500,
  });
  const handleSelectedVersion = (item) => {
    setCurrentVersion(item);
    props.onSelectVersion?.(item);
  };
  const handleDeleteVersion =
    (version: IStoryboardVersion) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isArchiving) return;
      setIsArchiving(true);
      StoryboardActions.archiveStoryboardVersion(version);
    };
  const handleNewVersion = (e) => {
    e.stopPropagation();
    setBusy(true);
    props.onNewVersion?.(copyComments, copyFrameStatuses);
  };

  return currentVersionDetails ? (
    <div className={classNames('flex flex-col flex-shrink-0 ', className)}>
      <Listbox value={currentVersion} onChange={handleSelectedVersion}>
        {({ open }) => (
          <>
            <Listbox.Button
              ref={(ref) => setParentRef(ref)}
              className={classNames(
                'text-type-subdued flex-shrink-0 text-sm rounded-sm pl-1 flex items-center ring-none outline-none group/versiondropdown',
              )}
            >
              {t(
                showTotalCount ? 'currentVersionWithTotal' : 'currentVersion',
                {
                  currentVersionNumber: currentVersionDetails.version_number,
                  versionCount: versions.length,
                },
              )}
              <DropdownChevron
                open={open}
                className="group-hover/versiondropdown:opacity-100"
              />
            </Listbox.Button>

            {ReactDOM.createPortal(
              <Listbox.Options
                className="z-50 w-64 overflow-y-auto bg-white rounded-lg shadow-lg focus:outline-none space-y-2"
                ref={(ref) => setPopperElement(ref)}
                style={styles.popper}
                {...attributes.popper}
              >
                {versions.length > 1 && (
                  <div className="p-2">
                    {versions.map((item) => (
                      <Listbox.Option
                        className="cursor-pointer"
                        key={item.id}
                        value={item.version_number}
                        as="a"
                        href={
                          isShareable
                            ? `/s/${item.hashid}`
                            : `/storyboards/${item.slug}`
                        }
                      >
                        {({ active }) => (
                          <div
                            className={classNames(
                              'flex items-start px-3 py-1.5 rounded-md gap-2 group',
                              {
                                'bg-surface-light': active,
                              },
                            )}
                          >
                            <div className="flex flex-col flex-grow truncate">
                              <span
                                className={classNames(
                                  'text-sm',
                                  active && 'text-brand-pink',
                                )}
                              >
                                {item.full_version_name}
                              </span>
                              <span className="text-xs text-type-subdued">
                                {t('updatedAt', {
                                  date: new Date(item.updated_at),
                                })}
                              </span>
                            </div>
                            {!isShareable &&
                              currentVersion !== item.version_number && (
                                <IconButton
                                  className="opacity-0 text-type-primary no-padding group-hover:opacity-100 hover:text-brand-pink"
                                  icon={<DeleteIcon />}
                                  onClick={handleDeleteVersion(item)}
                                  aria-label={t('delete')}
                                  disabled={isArchiving}
                                />
                              )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </div>
                )}

                {!isShareable && storyboardOwnerId && (
                  <div
                    className={classNames(
                      'w-full p-4 flex flex-col bg-white sticky bottom-0 ',
                      {
                        'border-t-border border-t bg-surface-light':
                          versions.length > 1,
                      },
                    )}
                  >
                    <GetPermission
                      storyboardOwnerId={storyboardOwnerId}
                      featureName="versioning"
                    >
                      {(hasPermission) => (
                        <>
                          {hasPermission ? (
                            <>
                              <Button
                                size="sm"
                                disabled={busy}
                                className="w-full"
                                onClick={handleNewVersion}
                              >
                                <span className="py-0.5">
                                  {t(busy ? 'creating' : 'new')}
                                </span>
                              </Button>
                              <div className="mt-3">
                                <div className="grid grid-cols-1 gap-0.5">
                                  <Checkbox
                                    smallLabel
                                    label={
                                      <Tooltip
                                        title={t('copyCommentsToggle.tooltip')}
                                        placement="right"
                                      >
                                        <span className="cursor-pointer">
                                          {t('copyCommentsToggle.label')}
                                        </span>
                                      </Tooltip>
                                    }
                                    onChange={(e) =>
                                      setCopyComments(e.currentTarget.checked)
                                    }
                                    value={copyComments}
                                  />

                                  <Checkbox
                                    smallLabel
                                    label={
                                      <Tooltip
                                        title={t('copyStatusesToggle.tooltip')}
                                        placement="right"
                                      >
                                        <span className="cursor-pointer">
                                          {t('copyStatusesToggle.label')}
                                        </span>
                                      </Tooltip>
                                    }
                                    onChange={(e) =>
                                      setCopyFrameStatuses(
                                        e.currentTarget.checked,
                                      )
                                    }
                                    value={copyFrameStatuses}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <FeatureUnavailable featureName="versioning" />
                          )}
                        </>
                      )}
                    </GetPermission>
                  </div>
                )}
              </Listbox.Options>,
              document.querySelector('body')!,
            )}
          </>
        )}
      </Listbox>
    </div>
  ) : null;
};
