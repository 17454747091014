/**@prettier */
import * as React from 'react';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import type { DetailedFrame, frameStatus } from 'javascripts/types/frame';
import { FrameActions } from 'javascripts/flux/actions/frame';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Trash2, X } from 'lucide-react';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

interface FrameImageStatusOverlayProps {
  title: string;
  frame?: DetailedFrame;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  currentFrameStatus?: frameStatus;
}

export const FrameImageStatusOverlay: React.FC<
  FrameImageStatusOverlayProps
> = ({ frame, title, onClick, children, currentFrameStatus }) => {
  const TIMEOUT_LENGTH = 60000;
  const isGenerating = currentFrameStatus === 'generating_image';

  const [isTimerActive, setIsTimerActive] = React.useState(false);
  const timeoutRef = React.useRef<any>();

  React.useEffect(() => {
    if (isGenerating) {
      // Start the progress animation
      const showTimeout = setTimeout(() => {
        setIsTimerActive(true);
      }, 10);

      return () => {
        clearTimeout(showTimeout);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [isGenerating]);

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (frame) {
      apiRequest({
        path: `frames/${frame.id}/generator_preview/cancel`,
        method: 'delete',
      });

      FrameActions.setImageStatus.defer({
        frame: frame,
        image_status: 'complete',
      });
    }
  };

  return (
    <div
      className="absolute inset-0 z-20 flex items-center justify-center h-full"
      onClick={onClick}
    >
      {/* Background */}
      <div className="absolute inset-0 bg-black rounded-sm opacity-40"></div>
      {/* Element */}
      <div
        className={classNames(
          'flex items-center gap-4 z-10 bg-black rounded-md px-3 pt-1 pb-1.5 relative overflow-hidden group/framestatus',
        )}
      >
        {isGenerating && (
          <>
            <div className="absolute top-0 left-0 right-0 bg-white/30 h-0.5" />
            <div
              className="absolute top-0 left-0 w-0 h-0.5 bg-white/70"
              style={{
                width: isTimerActive ? undefined : '0%',
                animation: isTimerActive
                  ? `growWidth ${TIMEOUT_LENGTH}ms linear forwards`
                  : 'none',
              }}
            />
          </>
        )}
        <span
          className={classNames(
            isGenerating && 'pt-0.5',
            'text-sm text-white opacity-80',
          )}
        >
          {title}
        </span>
        {isGenerating && frame ? (
          <span className="relative w-4 h-4">
            <span className="absolute pt-0.5 opacity-100 -top-2 -right-1 group-hover/framestatus:opacity-0">
              {children}
            </span>
            <Tooltip title={`Cancel`} placement={`right`} distance={10}>
              <div
                className="absolute top-0 px-1 py-1 opacity-0 cursor-pointer -right-2 group-hover/framestatus:opacity-100 rounded-md hover:bg-white/30 bg-white/20"
                onClick={handleCancel}
              >
                <X className="w-3 h-3 text-white" />
              </div>
            </Tooltip>
          </span>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
};

export const FrameImageStatus: React.FC<{
  frame: DetailedFrame;
}> = (props) => {
  const { t } = useTranslation();
  const handleOverlayErrorClick = React.useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      e.preventDefault();
      FrameActions.setImageStatus({
        frame: props.frame,
        image_status: 'complete',
      });
    },
    [props.frame],
  );

  var status = props.frame.image_status;
  if (!status || status === 'complete') return null;
  if (status === 'image_error') {
    return (
      <FrameImageStatusOverlay
        onClick={handleOverlayErrorClick}
        title={props.frame.error_message!}
      />
    );
  } else {
    return (
      <FrameImageStatusOverlay
        frame={props.frame}
        title={t('frames.status.' + status)}
        currentFrameStatus={status as frameStatus}
      >
        <LoadingIndicator className="w-3 h-3 text-white" fast />
      </FrameImageStatusOverlay>
    );
  }
};
