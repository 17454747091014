/**@prettier */
/* eslint one-var:0 */
const {
  default: CommentList,
} = require('blackbird/components/comments/list/CommentList');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { commentNameLocalState } = require('javascripts/helpers/local-state');

window.PanelbarCommentsSection = createReactClass({
  propTypes: {
    canManage: PropTypes.bool.isRequired,
    storyboard: PropTypes.object.isRequired,
    comments: PropTypes.array.isRequired,
    headerText: PropTypes.string.isRequired,
    team: PropTypes.any,
    user: PropTypes.object,
    active: PropTypes.bool,
    frameId: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    handleCommentSave: PropTypes.func.isRequired,
    handleCommentUpdate: PropTypes.func.isRequired,
    handleCommentDelete: PropTypes.func.isRequired,
    hideCompletedComments: PropTypes.bool,
  },

  componentDidUpdate: function (prevProps) {
    if (!prevProps.active && this.props.active) {
      this._highlightSection();
    } else if (
      // Scroll only if new comment is added , not for delete
      prevProps.comments?.length < this.props.comments?.length &&
      prevProps.frameId === this.props.frameId
    ) {
      requestAnimationFrame(this.scrollToBottom);
    }
  },
  scrollToBottom: function () {
    var sectionEl = ReactDOM.findDOMNode(this.refs.section);
    var lastChild = sectionEl.lastChild;
    lastChild.scrollIntoView({ behavior: 'smooth', block: 'start' });
  },
  _highlightSection: function () {
    var sectionEl = ReactDOM.findDOMNode(this.refs.section);
    sectionEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  },

  _handleSectionClick: function (e) {
    e.preventDefault();
    this.props.onClick?.();
  },

  handleCommentSave(data) {
    const { storyboard } = this.props;
    this.props.handleCommentSave?.({
      ...data,
      storyboardSlug: storyboard.short_slug,
      name: data.name ?? commentNameLocalState.getValue(),
    });
  },
  handleCommentUpdate(data) {
    const { storyboard } = this.props;
    this.props.handleCommentUpdate?.({
      ...data,
      storyboardId: storyboard.short_slug,
    });
  },
  handleCommentDelete(data) {
    const { storyboard } = this.props;
    const name = commentNameLocalState.getValue();
    this.props.handleCommentDelete?.({
      ...data,
      storyboardId: storyboard.short_slug,
      name: data.name ?? name,
    });
  },
  handleMarkAsComplete(data) {
    const { storyboard } = this.props;
    const { id, acknowledged } = data;
    CommentActions.acknowledge({
      comment_id: id,
      storyboard_id: storyboard.short_slug,
      acknowledged: acknowledged,
    });
  },
  render: function () {
    const activeClass =
      this.props.active && this.props.onClick
        ? 'border-2 border-brand-pink rounded-lg overflow-hidden my-2 !-mx-4'
        : '';

    const clickableClass = this.props.onClick ? 'cursor-pointer' : '';

    if (this.props.comments.length === 0) return null;
    return (
      <div data-hj-suppress className={`-mx-6 ${activeClass}`} ref="section">
        <div
          onClick={this._handleSectionClick}
          className={
            'pt-1.5 pb-2 px-6 bg-surface-light flex justify-between items-center ' +
            clickableClass
          }
        >
          <div className="text-xs font-semibold tracking-wider uppercase text-type-subdued">
            {this.props.headerText}
          </div>
        </div>
        <div className="border-b border-border">
          <CommentList
            canManage={this.props.canManage}
            frameId={this.props.frameId}
            comments={this.props.comments}
            onAddReply={this.handleCommentSave}
            onEditComment={this.handleCommentUpdate}
            onDelete={this.handleCommentDelete}
            onMarkAsComplete={this.handleMarkAsComplete}
            hideCompletedComments={this.props.hideCompletedComments}
          />
        </div>
      </div>
    );
  },
});
