/**@prettier */
import * as React from 'react';
import ChevronDownIcon from 'blackbird/images/icons/arrow.svg';
import { supportedLayouts } from '../pdf/types';
import { SVGIcon, SVGIconType } from './SVGIcon';
import { Listbox } from '@headlessui/react';
import withCustomPopper, { CustomPopperProps } from 'blackbird/helpers/popper';
import Icon from 'blackbird/components/icon/Icon';
import { isEmpty, map } from 'underscore';
import { DropdownChevron } from 'blackbird/components/common/DropdownChevron';

export interface IconDropdownOptions {
  iconClass: SVGIconType;
  title: string;
  subtitle?: string;
  onClick: any;
  isSelectedWhen: supportedLayouts;
  trialAccess: boolean;
}

interface Props extends CustomPopperProps {
  selected: string;
  options: IconDropdownOptions[];
}

class IconDropdown extends React.Component<
  Props,
  {
    selectedValue: string;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.selected,
    };
  }

  _upgrade(e) {
    e.preventDefault();
    UpgradePromptActions.show('Locked PDF layout');
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selectedValue: this.props.selected });
    }
  };

  _findSelected = (): IconDropdownOptions => {
    var selected = this.props.options[0];
    this.props.options.map((option) => {
      if (option.isSelectedWhen === this.props.selected) {
        selected = option;
      }
    });
    return selected;
  };

  handleChange = (value) => {
    var selectedOption = this._findSelected();
    selectedOption.onClick(value);
  };

  render() {
    var selectedOption = this._findSelected();
    return (
      <div className="relative flex-auto group/pdflayout">
        <Listbox value={this.state.selectedValue} onChange={this.handleChange}>
          {({ open }) => (
            <>
              <Listbox.Button
                ref={this.props.setPopperParentRef}
                as="div"
                className="relative w-full border cursor-pointer rounded-md border-border-mid"
              >
                <div className="absolute top-0 bottom-0 flex items-center w-6 right-5">
                  <DropdownChevron
                    open={open}
                    className={`group-hover/pdflayout:opacity-100`}
                  />
                </div>
                <div className={'cursor-pointer p-2 flex items-center'}>
                  <div className={'mr-3'}>
                    <SVGIcon
                      fill={false}
                      className="h-[3.4rem] w-[3.7rem] fullsize:w-14 fullsize:h-11"
                      icon={selectedOption.iconClass}
                    />
                  </div>
                  {!isEmpty(selectedOption.subtitle) ? (
                    <div className="f4 lh-copy">
                      <div>{selectedOption.title}</div>
                      <div className="silver">{selectedOption.subtitle}</div>
                    </div>
                  ) : (
                    <div className="f4 lh-copy">{selectedOption.title}</div>
                  )}
                </div>
              </Listbox.Button>
              <Listbox.Options
                style={this.props.popperStyles.popper}
                {...this.props.popperAttributes.popper}
                ref={this.props.setPopperElement}
                className="z-10 w-full bg-white border rounded-sm border-border-mid focus:outline-none"
              >
                {map(this.props.options, (option, idx) => (
                  <Listbox.Option
                    key={'dropdown-item-' + idx}
                    value={option.isSelectedWhen}
                  >
                    {!option.trialAccess && BoordsConfig.IsTrial ? (
                      <div
                        className={
                          'cursor-pointer hover:text-black p-4 flex items-center' +
                          (idx == this.props.options.length - 1
                            ? ' '
                            : ' border-b border-border-mid')
                        }
                        onClick={this._upgrade}
                      >
                        <div className={'mr-3 opacity-30'}>
                          <SVGIcon
                            fill={false}
                            className="w-14 h-11"
                            icon={option.iconClass}
                          />
                        </div>
                        <div className="f4 lh-copy">
                          <div className="silver">{option.title}</div>
                          <div className="dark-green">
                            {'Upgrade to use this template'}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={'dropdown-item-' + idx}
                        className={
                          'cursor-pointer hover:opacity-100 p-4 flex items-center' +
                          (option.isSelectedWhen == this.props.selected
                            ? '  '
                            : ' opacity-50 glow ') +
                          (idx == this.props.options.length - 1
                            ? ' '
                            : ' border-b border-border-mid  ')
                        }
                      >
                        <div className={'mr-3'}>
                          <SVGIcon
                            fill={false}
                            className="w-14 h-11"
                            icon={option.iconClass}
                          />
                        </div>
                        {!isEmpty(option.subtitle) ? (
                          <div className="f4 lh-copy">
                            <div>{option.title}</div>
                            <div className="silver">{option.subtitle}</div>
                          </div>
                        ) : (
                          <div className="f4 lh-copy">{option.title}</div>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </>
          )}
        </Listbox>
      </div>
    );
  }
}

const exported = withCustomPopper(IconDropdown)({
  placement: 'bottom-start',
});

export default exported;
