import {AiPrompt} from 'blackbird/components/ai/AiPrompt';
import {AiWorking} from 'blackbird/components/ai/AiWorking';
import { useTranslation } from 'react-i18next';
import type {IStoryboard} from 'javascripts/types/storyboard';
import React, {useEffect, useState} from 'react';
import logger from 'javascripts/helpers/logger';
const { default: Dialog } = require('blackbird/components/dialog/Dialog');

interface FlyoverAiScriptProps {
  storyboard: IStoryboard
}

export const FlyoverAiScriptGenerator: React.FC<FlyoverAiScriptProps> = ({ storyboard }) => {
  const { t } = useTranslation();
  const [working, setWorking] = useState(false)
  useEffect(() => {
    if (storyboard.ai_script_generating) {
      setWorking(true)
    }
  }, [storyboard])

  return (
    <Dialog
      isColorBurst={working}
      isDark={!working}
      hideActions
      keepOpen={working}
      title={!working && t("ai.dialog.new.title")}
      col
      hideClose={working}
      onCloseBtnClick={() => {
        FlyoverActions.close();
      }}
      isOpen
    >
      {!working ? (
        <AiPrompt storyboard_id={storyboard.id} onSubmit={() => setWorking(true)}/>
      ) : (
        <AiWorking id={storyboard.id} onCancel={() => setWorking(false)} />
      )}
    </Dialog>
  )
}

(window as any).FlyoverAiScriptGenerator = FlyoverAiScriptGenerator;
