/** @prettier */

import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import classNames from 'classnames';
import { CalendarIcon, Gem } from 'lucide-react';
import { differenceInDays, endOfDay } from 'date-fns';

declare const BoordsConfig: {
  TrialEndsAt?: number;
};

export const BannerPuffinTrial: React.FC = () => {
  const getDaysRemaining = () => {
    if (!BoordsConfig.TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.TrialEndsAt * 1000);
    const daysRemaining = differenceInDays(endOfDay(trialEndDate), now);
    return daysRemaining <= 0 ? null : daysRemaining;
  };

  const daysRemaining = getDaysRemaining();

  if (daysRemaining === null) {
    return null;
  }

  return (
    <Banner
      kind={'alert'}
      hideIcon
      className="no-padding-y h-banner !bg-black"
      customWidth
      message={
        <div className="relative flex items-center justify-center flex-auto w-full">
          {/* <a */}
          {/*   className="flex items-center pl-2 pr-2.5 pt-0 pb-0.5 leading-tight rounded-lg bg-white/20 border-white text-white border-2 text-black/70 cursor-pointer" */}
          {/*   href="/billing" */}
          {/* > */}
          {/*   <CalendarIcon className="w-4 opacity-50" /> */}
          {/*   <span className="ml-1.5 font-semibold text-sm">{`Free Trial`}</span> */}
          {/* </a> */}
          <div className="ml-3 mr-2 font-semibold text-white">{`${daysRemaining} days left on your Workflow trial →`}</div>

          <Button
            size="xs"
            type={`white`}
            rounded
            onClick={() => {
              Track.event.defer('banner_click', {
                category: 'Checkout',
              });
              FlyoverActions.open.defer({
                type: 'inlinePricing',
              });
            }}
          >
            <span className="inline-flex items-center">
              <span className="pb-[0.1rem]">{`Compare Plans`}</span>
            </span>
          </Button>
        </div>
      }
    />
  );
};
